.contentListItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.contentListItem i {
  display: none;
}

.contentListItem .topicsListItemBtn {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
  padding: 16px 30px 16px 16px;
  border: none;
  text-align: left;
}

.contentListItem.highlighted {
  border: 2px solid var(--theme-primary);
}

.contentListItem.active .topicsListItemBtn,
.contentListItem .topicsListItemBtn:hover,
.contentListItem .topicsListItemBtn:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

.contentListItem .topicsListSubItemBtn {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-dark-blue);
  margin-left: 15px;
  border: none;
  text-align: left;
}

.contentListItem.active .topicsListSubItemBtn,
.contentListItem .topicsListSubItemBtn:hover,
.contentListItem .topicsListSubItemBtn:focus {
  text-decoration: none;
  color: var(--theme-primary);
}
