.teacherPage {
  padding-top: 40px;
  margin-bottom: -57px;
}

.pageBottomContent {
  padding: 60px 0 100px;
  background-color: var(--lavender-grey);
  will-change: max-height;
}

.pageBottomContent :global .carousel-indicators {
  display: none;
}

.pageBottomContent :global .carousel-control-next-icon,
.pageBottomContent :global .carousel-control-prev-icon {
  display: none;
}

.pageBottomContent :global .carousel-control-next,
.pageBottomContent :global .carousel-control-prev {
  transition: all ease 0.3s;
  opacity: 1;
  width: 40px;
  height: 40px;
  top: auto;
  bottom: 220px;
  margin-bottom: -20px;
}

.pageBottomContent :global .carousel-control-prev {
  transform: rotate(180deg);
}

.pageBottomContent :global .carousel-control-next:before,
.pageBottomContent :global .carousel-control-prev:before {
  position: absolute;
  content: '\e918';
  font-family: 'icomoon';
  font-size: 14px;
  color: var(--whiteColor);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.teacherContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.teacherNavList {
  max-width: 400px;
  width: 100%;
  margin-right: 10%;
}

@media (max-width: 991px) {
  .teacherPage {
    padding-top: 24px;
    margin-bottom: 0;
  }

  .pageBottomContent {
    padding: 24px 0 16px;
    background-color: var(--whiteColor);
  }

  .navListContent {
    padding-top: 24px;
  }

  .pageBottomContent :global .carousel-control-next,
  .pageBottomContent :global .carousel-control-prev {
    bottom: 260px;
  }
}

@media (max-width: 767px) {
  .pageBottomContent :global .carousel-control-next,
  .pageBottomContent :global .carousel-control-prev {
    bottom: 190px;
  }
}

@media (max-width: 480px) {
  .pageBottomContent :global .carousel-control-next,
  .pageBottomContent :global .carousel-control-prev {
    bottom: 130px;
  }
}
