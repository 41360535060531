.asaPage {
  padding: 40px 0 150px;
}

.asaPageTitle {
  margin-bottom: 32px;
}

.asaPageContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.pageText {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.asaPageItem {
  max-width: 33%;
  width: 100%;
}

.asaPageItem:not(last-child) {
  margin-right: 18px;
}

@media (max-width: 991px) {
  .asaPage {
    padding: 0;
  }

  .asaPageContent {
    flex-flow: row wrap;
  }

  .asaPageItem {
    max-width: none;
    width: 100%;
  }
}
