/*TODO: update after add loader in project*/

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 30px;
  font-family: 'Ubuntu', sans-serif;
  color: var(--whiteColor);
  background-color: rgba(0,0,0,.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.questionPage {
  background-color: var(--lavender-grey);
}

.questionLinkBottom {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 88px;
}

.loaderContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

.questionLinkBottom :global(.btnLabel):hover,
.questionLinkBottom :global(.btnLabel):focus {
  background-color: var(--button-default-focused-10);
}

.questionBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.doubleCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 6px;
  margin: 10px 32px 10px 0;
  border: 1px solid var(--additional-gray-blue);
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--neutral-gray-blue);
}

.doubleCounter > div:nth-child(2) {
  border-left: 1px solid var(--additional-gray-blue);
}

.headerQuestionBox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.headerQuestionBtn {
  border: 1px solid var(--button-secondary-enable);
  background-color: var(--whiteColor);
  padding: 5px 24px;
  border-radius: 6px;
  color: var(--neutral-dark-blue);
  max-width: 346px;
  width: 100%;
}

.headerQuestionBtn:hover,
.headerQuestionBtn:focus {
  text-decoration: none;
  color: var(--neutral-dark-blue);
}

.headerQuestionBtn > i {
  font-size: 16px;
  line-height: 1;
}

@media (max-width: 991px) {
  .questionLinkBottom {
    padding-bottom: 40px;
  }

  .questionLinkBottom :global(.btnLabel) {
    border: 2px solid var(--theme-primary);
  }
}

@media (max-width: 768px) {
  .questionLinkBottom {
   justify-content: center;
  }
}
