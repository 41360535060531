.headerText {
  font-family: 'Ubuntu', sans-serif;
  line-height: 21px;
  font-size: 21px;
  font-weight: bold;
  color: var(--modal-footer-btn-color);
}

.continueText {
  font-size: 24px;
}

.footerDays {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  line-height: 21px;
  font-size: 14px;
  color: var(--functional-success);
}

@media (max-width: 767px) {
  h3,
  .h3 {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  h3,
  .h3 {
    font-size: 14px;
    line-height: 22px;
  }
}
