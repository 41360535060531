.disabledButton {
  pointer-events: none;
  opacity: 0.4;
}

.stopBtn {
  color: var(--whiteColor);
  background-color: var(--theme-primary);
}

/*TODO remove after resolve readSpeaker issue */
.hideButton {
  visibility: hidden;
}
