.progressContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.progressContent:hover {
  cursor: pointer;
}

.status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
}

.bad {
  background-color: var(--functional-warning);
}

.good {
  background-color: var(--functional-success);
}

.middle {
  background-color: var(--button-primary-pressed);
}

.empty {
  background-color: var(--additional-gray-blue);
}

:global .progress {
  max-width: 80px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--additional-gray-blue);
}
