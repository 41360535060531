.glossaryListItem {
  margin-bottom: 16px;
}

.glossaryLink {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  padding: 0;
  text-align: left;
  border: none;
}
