.btnList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.btnList > :global(.btn):first-child {
  margin-right: 20px;
}

@media (max-width: 1199px) {
  :global .promoItem .btnList {
    justify-content: center;
  }

  .btnList {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .btnList {
    flex-wrap: wrap;
  }

  .btnList > :global(button),
  .btnList > :global(.btn) {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .btnList > :global(.btn):first-child {
    margin-right: 0;
  }
}
