.notFoundPage {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-top: 60px;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.imgContainer {
  margin-bottom: 38px;
}

.pageTextError {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 120px;
  line-height: 200px;
  text-align: center;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue-70);
}

.pageText {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: var(--neutral-dark-blue-70);
  margin-top: 14px;
}

.linkBtn {
  display: flex;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--button-default-focused);
  margin-top: 80px;
}

.linkBtn i {
  display: block;
  transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

@media (max-width: 991px) {
  .pageTextError {
    font-size: 96px;
  }
}
