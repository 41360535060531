.cockpitBottomPanel {
    padding: 80px 0 100px;
  }

  .jumbotron {
    padding: 0;
    background-color: var(--lavender-grey);
    flex-grow: 1;
  }

  .jumbotron:not(:last-child) {
    margin-right: 20px;
  }

.jumbotronBox {
  padding: 20px 28px;
}

.jumbotronBox:not(:last-child),
.jumbotronHeader {
  border-bottom: 1px solid var(--additional-gray-blue);
}

.jumbotronBox :global .h3 {
  color: var(--neutral-dark-blue);
  text-align: center;
}

.jumbotronBoxItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.jumbotronBoxItem:not(:last-child) {
  margin-bottom: 16px;
}

  .jumbotronTime,
  .jumbotronDate,
  .jumbotronCount {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .jumbotronDate {
    color: var(--neutral-dark-blue);
  }

  .jumbotronTime {
    color: var(--neutral-dark-blue-50);
  }

  .jumbotronCount {
    color: var(--functional-success);
  }

  .jumbotronText {
    font-family: 'Hind', sans-serif;
    font-size: 15px;
    line-height: 21px;
  }

  .jumbotronIcon :global .icon-arrow {
    font-size: 14px;
    color: var(--theme-primary);
  }

  .progressBarContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .progressBarContent :global .progress {
    max-width: none;
    height: 16px;
  }

  :global .progress {
    flex-grow: 1;
    border-radius: 8px;
  }

  .progressBar :global .progress-bar {
    background-color: var(--theme-primary);
    border-radius: 8px;
    max-width: none;
  }

  .status {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-left: 10px;
  }

  .bad {
    background-color: var(--functional-warning);
  }

  .good {
    background-color: var(--functional-success);
  }

  .middle {
    background-color: var(--button-primary-pressed);
  }

  .empty {
    background-color: var(--additional-gray-blue);
  }

  .jumbotronBoxDate {
    width: 100%;
  }

  .instructorContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .daysValue {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 96px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: var(--functional-success);
    margin-bottom: 14px;
  }

  .daysText {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: var(--functional-success);
  }

  .dateItemText {
    font-family: 'Hind', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--neutral-gray-blue);
  }

  .dateItemLabel {
    font-family: 'Hind', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--neutral-dark-blue-50);
  }

  .jumbotronBoxBtn a,
  .jumbotronBoxBtn .btn {
    width: 100%;
    border-radius: 0 0 6px 6px;
  }

  .jumbotronLabel {
    font-family: 'Hind', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--neutral-dark-blue-50);
    margin-bottom: 0;
  }

  .jumbotronValue {
    font-family: 'Hind', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--neutral-gray-blue);
  margin-bottom: 0;
}

.jumbotronBoxItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.jumbotron {
  width: 100%;
  max-width: 100%;
  margin-right: 177px;
  border-radius: 10px;
}

.dateItem {
    max-width: calc(50% - 10px);
    width: 100%;
  }

  .jumbotronPlaceholder {
    text-align: center;
  }

  .jumbotronImg {
    display: block;
    max-width: 100%;
    margin: 35px auto 44px;
  }

  .jumbotronHeader {
    min-height: 0;
    flex-shrink: 0;
  }

  .jumbotronFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .jumbotronFooter .jumbotronBox {
    border-bottom: none;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .jumbotronFooter .jumbotronBtn {
    min-height: 0;
    flex-shrink: 0;
  }

  .jumbotronIconBox {
    width: 100%;
    text-align: center;
  }

  .jumbotronIconBox i {
    font-size: 78px;
    color: var(--neutral-gray);
  }

  @media (max-width: 991px) {
    .cockpitBottomPanel {
      padding: 0;
    }


  .jumbotronHeader .jumbotronBox {
    padding: 20px 28px 0;
    border-bottom: none;
  }

    .jumbotronHeader {
      border-bottom: none;
    }

    .dateItemText {
      font-size: 17px;
    }


    .jumbotronBox {
      padding: 20px;
    }
  }
