.container {
  background: var(--additional-gray-blue-20);
  border-radius: 10px;
  padding: 30px 40px;
  font-family: Hind;
  font-weight: 500;
}

.container :global(p) {
  margin: 0;
}

.generalInfoBox {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.titleText {
  font-family: Ubuntu;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
}

.subTitleText {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.contactInfoRow {
  padding-top: 50px;
}

.contactInfoBoxItem {
  margin-bottom: 20px;
}

.infoLabel {
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.infoText {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controlsContainer {
  margin-top: 20px;
  padding: 0;
}

.controlsBox {
  display: flex;
  justify-content: flex-end;
}
