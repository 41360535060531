.imprintPage {
  padding: 60px 0 120px;
}

.imprintList {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 28px;
}

.imprintPage p {
  margin-bottom: 12px;
  max-width: 400px;
}

@media (max-width: 991px) {
  .imprintPage {
    padding: 24px 0 0;
  }

  .imprintPage h1 {
    text-align: left;
  }

  .imprintList {
    margin-bottom: 28px;
  }
}
