.storeBtnItem {
  max-width: 100%;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  margin-top: 105px;
  text-align: right;
}

.storeBtnItem img {
  height: 30px;
}

.storeBtnItem:first-child {
  margin-top: 0;
  text-align: left;
}

.storeBtnItem:first-child img {
  height: 260px;
  width: 100%;
}

@media (max-width: 1199px) {
  .storeBtnItem img {
    height: 20px;
  }

  .storeBtnItem:first-child img {
    height: 120px;
  }
}
