.promoContent {
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.promoItem {
  max-width: calc(50% - 10px);
  width: 100%;
}

.promoItem:first-child {
  position: relative;
}

.btnList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.btnList > a:last-child {
  margin-left: 20px;
}

.rulesImg {
  height: 100%;
  width: auto;
}

:global .categoryIconBarHome {
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: center;
  padding-left: 50px;
  width: 100%;
}

.rulesList {
  position: absolute;
  right: 0;
  bottom: 48px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.rulesListItem {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.rulesListItem:not(:last-child) {
  margin-right: 26px;
}

.rulesListItemText {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.rulesListMobile {
  display: none;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 50%;
}

.iconsList {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 62px;
}

.iconsListItem {
  width: 100px;
  height: 100px;
}

.loginBtnMobile {
  display: none;
}

.ruleListItem:not(:first-child) {
  margin-left: 12px;
}

.ruleListItem > i {
  color: currentColor;
}

.socialLinkListMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 36px;
}

.socialLinkList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

.socialLinkList > li:not(:last-child) {
  margin-right: 40px;
}

.socialLinkList > li {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialLinkItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease-in-out 0.4s;
}

.socialLinkItem:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.socialLinkItemText {
  text-transform: uppercase;
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: var(--text-color);
  margin-top: 14px;
}

.navbar-expand-lg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: var(--whiteColor);
}

@media (min-width: 1200px) {
  .promoItem:last-child {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .promoItem:first-child {
    display: none;
  }

  .promoItem {
    text-align: center;
  }

  .rulesListMobile {
    display: flex;
    margin-bottom: 28px;
    justify-content: center;
    max-width: none;
  }

  .rulesListMobile > i {
    font-size: 36px;
  }

  .btnList {
    justify-content: center;
  }

  .promoContent {
    flex-wrap: wrap;
  }

  .promoItem {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
  }

  .iconsList {
    margin-bottom: 44px;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .sectionVipContent {
    flex-wrap: wrap;
  }

  .sectionVipItem {
    max-width: none;
    width: 100%;
    margin-bottom: 25px;
  }

  .sectionCard {
    background-color: var(--neutral-light-gray);
  }

  .storeBtnList {
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
  }

  .loginBtnMobile {
    display: block;
  }

  .btnList {
    flex-wrap: wrap;
  }

  .btnList > a,
  .btnList > button {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .btnList > button {
    order: 1;
  }

  .btnList > a:nth-child(3) {
    margin-left: 0;
    order: 2;
  }

  .btnList > a:nth-child(2) {
    order: 3;
  }
}
