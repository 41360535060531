.theoryTopicPage {
  background-color: var(--lavender-grey);
  padding-top: 32px;
}

:global .theoryTopicPage .topicsListItem {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.theoryTopicPage .linkBtn {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.linkBtn i {
  display: block;
  transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

.theoryTopicContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 100px;
  max-height: 930px;
  height: 100%;
  overflow: hidden;
}

.mobileView {
  display: none;
}

@media (max-width: 991px) {
  .theoryTopicPage {
    background-color: var(--whiteColor);
  }

  .theoryTopicContent {
    padding-bottom: 20px;
    max-height: none;
    overflow: inherit;
  }

  .mobileView {
    display: block;
    margin-bottom: 40px;
  }
}
