.glossaryContent {
  position: relative;
  padding: 40px 44px 44px;
  background-color: var(--whiteColor);
  border-radius: 6px;
  flex-grow: 1;
  width: 100%;
}

.glossaryScrollBox::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.glossaryScrollBox::-webkit-scrollbar {
  width: 12px;
}

.glossaryScrollBox::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

@media (max-width: 991px) {
  .glossaryContent {
    padding: 0;
  }
}
