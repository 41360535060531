.examList {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  max-height: 364px;
  overflow-y: auto;
}

.examList::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.examList::-webkit-scrollbar {
  width: 12px;
}

.examList::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.examListItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid var(--additional-gray-blue);
  background: var(--whiteColor);
}

.examListItemCorrect {
  border-color: var(--functional-success);
  border-width: 2px;
  background-color: var(--functional-success-10);
}

.examListItemCorrect::after {
  content: ' ';
  display: inline-block;
  transform: rotate(45deg);
  height: 19px;
  width: 11px;
  border-bottom: 4px solid var(--functional-success);
  border-right: 4px solid var(--functional-success);
  top: -5px;
  position: absolute;
  right: 0;
  z-index: 9;
}

.examListItemCorrect span::before {
  content: ' ';
  display: inline-block;
  transform: rotate(45deg);
  height: 19px;
  width: 12px;
  border-bottom: 5px solid var(--whiteColor);
  border-right: 5px solid var(--whiteColor);
  top: -5px;
  position: absolute;
  right: 0;
}

.examListItemIncorrect {
  border-color: var(--functional-warning);
  border-width: 2px;
}

.examListItemIncorrect::after {
  content: 'x';
  display: inline-block;
  height: 21px;
  width: 11px;
  color: var(--functional-warning);
  font-size: 26px;
  font-weight: 700;
  top: -15px;
  position: absolute;
  right: 0;
  z-index: 9;
}

.examListItemIncorrect span::before {
  content: 'x';
  display: inline-block;
  height: 21px;
  width: 11px;
  color: var(--whiteColor);
  font-size: 29px;
  font-weight: 700;
  top: -17px;
  position: absolute;
  right: 1px;
}

.favorite:before {
  position: absolute;
  content: '';
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--button-primary-pressed);
  z-index: 1;
}

.checked {
  background-color: var(--button-secondary-pressed);
}

.questionsNumbers {
  /*position: relative;*/
  z-index: 2;
}

@media (max-width: 991px) {
  .examListItem {
    margin: 5px;
  }

  .examList::-webkit-scrollbar {
    width: 8px;
  }
}
