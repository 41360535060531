.btnTop {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  background-color: var(--whiteColor);
  transition: ease 0.3s;
  transform-origin: center;
  border: 1px solid transparent;
  transform: scale(0);
  z-index: 10;
}

.btnTop i {
  width: 14px;
  height: 14px;
  font-size: 14px;
  transform: rotate(-90deg);
  color: var(--theme-primary);
}

.btnTop.visible {
  transform: scale(1);
}

.btnTop:hover {
  cursor: pointer;
  box-shadow: none;
  border: 1px solid var(--theme-primary);
}

.btnTopText {
  font-family: 'Hind', sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: var(--text-color);
}

@media (max-width: 767px) {
  .btnTop {
    right: 15px;
    bottom: 15px;
  }
}
