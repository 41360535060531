.responsiveVideo {
  max-width: 610px;
  width: 100%;
  height: 100%;
  min-height: 442px;
  border-radius: 6px;
}

:global .carousel-item p {
  margin: 12px 0 24px;
  max-width: 400px;
}

.responsiveVideo::-webkit-media-controls {
  opacity: 0;
}

.videoContainer:hover .responsiveVideo::-webkit-media-controls {
  cursor: pointer;
}

@media (max-width: 1199px) {
  .responsiveVideo {
    min-height: 338px;
  }
}

@media (max-width: 991px) {
  .responsiveVideo {
    min-height: auto;
    max-width: none;
  }
}
