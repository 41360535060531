.instructorPage {
  padding: 60px 0 120px;
}

.instructorPage h1 {
  margin-bottom: 32px;
}

.instructorSubtitle {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

p.instructorSubtitle {
  color: var(--neutral-dark-blue);
}

.instructorList p {
  position: relative;
  padding-left: 22px;
}

.instructorList p:before {
  position: absolute;
  content: '';
  left: 0;
  top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--neutral-gray);
}

.instructorPage :global .jumbotron {
  margin-right: 0;
  padding: 24px 13px 30px 25px;
  border-radius: 10px;
  max-width: 440px;
}

.jumbotronTitle {
  padding-left: 20px;
  max-width: 420px;
}

@media (max-width: 991px) {
  .instructorPage {
    padding: 24px 0 0;
  }

  .jumbotronTitle {
    padding-left: 0;
  }
}
