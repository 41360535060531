.vipCardMobile {
  display: none;
}

.vipCardMobile :global(.accordion) {
  position: relative;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  overflow: hidden;
  letter-spacing: 1px;
  background: url('../../img/vip-mobile.svg') no-repeat -63px -58px, var(--whiteColor);
  background-size: 146px 146px;
}

.vipCardMobile :global(.card-header) {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-bottom: none;
  border-radius: 0;
  padding: 0;
}

.vipCardMobile :global(.card) {
  background-color: transparent;
  margin-bottom: 0;
  border: none;
}

.vipCardMobile :global(.card-body) {
  display: flex;
  justify-content: center;
  padding: 3px 20px 8px;
  align-items: center;
  width: 100%;
}

.vipCardMobile :global(.linkBtn) {
  letter-spacing: 0.1px;
}

.cardTitle {
  font-family: 'Hind', sans-serif;
  font-size: 24px;
  line-height: 60px;
  color: var(--theme-primary);
  font-weight: bold;
  text-transform: uppercase;
}

.accordionBtn {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -12px;
  transform: rotate(90deg);
  color: var(--theme-primary);
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.accordionBtn > i {
  font-size: 24px;
}

@media (max-width: 767px) {
  .vipCardMobile {
    display: block;
  }
}
