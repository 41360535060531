.pageText {
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .pageText {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .pageText {
    text-align: left;
  }
}

.p3 {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}
