.mobileSlider {
  display: none;
}

.mobileSliderItem:not(:last-child) {
  margin-bottom: 20px;
}

.sliderTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
  margin-bottom: 4px;
}

.mobileSlider .sliderText {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
  margin: 8px 0 0;
}

.langList {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.langList > li:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 991px) {
  .mobileSlider {
    display: block;
  }
}
