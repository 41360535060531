.navList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.navList :global .nav-item {
  width: 100%;
  background-color: var(--whiteColor);
  margin-bottom: 12px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.navList :global .nav-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
  padding: 16px 30px 16px 16px;
  transition: all ease 0.3s;
}

.navList :global .nav-link i {
  font-size: 14px;
  color: var(--theme-primary);
}

.navList :global .nav-link.active,
.navList :global .nav-link:hover {
  color: var(--button-default-focused);
}
