.zoomLink {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue-70);
  pointer-events: none;
}

@media (max-width: 991px) {
  .zoomLink {
    color: var(--button-default-focused);
    pointer-events: auto;
    text-decoration: underline;
    transition: all ease 0.3s;
  }

  .zoomLink:hover {
    cursor: pointer;
  }
}
