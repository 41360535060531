:global(.topicListLogged) .topicList {
  background-color: var(--neutral-light-gray);
}

:global(.topicListNotLogged) .topicList {
  background-color: var(--whiteColor);
}

.topicList {
  padding: 80px 0 20px;
}

:global .jumbotron {
  max-width: 350px;
  width: 100%;
  margin-right: 177px;
  border-radius: 10px;
}

.topicListContent {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.theoryTopicList {
  column-count: 2;
  column-width: 250px;
  column-gap: 10px;
}

@media (max-width: 991px) {
  .topicListContent {
    flex-wrap: wrap;
  }

  :global(.topicListLogged) .topicList {
    background-color: var(--whiteColor);
  }

  .theoryTopicList {
    column-count: 1;
    column-width: auto;
    column-gap: inherit;
    order: 1;
    padding-bottom: 20px;
  }

  :global .jumbotron {
    order: 2;
    margin-bottom: 0;
  }

  .topicList {
    padding: 40px 0 20px;
  }
}

@media (max-width: 767px) {
  :global .jumbotron {
    margin-right: 0;
  }
}
