.glossaryPage {
  padding: 32px 0 104px;
  background-color: var(--lavender-grey);
}

.glossaryPage :global .h2 {
  color: var(--neutral-dark-blue);
  margin: 20px 0;
}

.glossaryPageContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  max-height: 750px;
  height: 100%;
  overflow: hidden;
}

.glossaryPage .linkBtn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--button-default-focused);
  transition: all ease 0.3s;
  text-transform: uppercase;
}

.glossaryPage .linkBtn i {
  display: block;
  font-size: 14px;
  color: currentColor;
  transform: rotate(180deg);
  margin-left: 5px;
}

.glossaryPage .linkBtn:hover,
.glossaryPage .linkBtn:focus {
  text-decoration: none;
}

.glossaryPageContent::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.glossaryPageContent::-webkit-scrollbar {
  width: 12px;
}

.glossaryPageContent::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.mobileView {
  display: none;
}

@media (max-width: 991px) {
  .glossaryPage {
    padding: 24px 0 20px;
    background-color: var(--whiteColor);
  }

  .glossaryPageContent {
    flex-flow: row wrap;
    max-height: none;
  }

  .glossaryPageContent > div:last-child {
    display: none;
  }

  .mobileView {
    display: block;
  }
}
