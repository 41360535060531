.root {
  height: 300px;
  width: 100%;
  background-color: var(--lavender-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
  margin-bottom: 15px;
}

.root > * {
  max-width: 60%;
  max-height: 70%;
}
