@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,500&display=swap&subset=cyrillic,cyrillic-ext,latin-ext);
:root {
  --whiteColor: #fff;
  --blackColor: #000;
  --theme-primary-80: #f29100;
  --lavender-grey: #f7f9fc;
  --additional-blue: #cde1ff;
  --additional-gray-blue: #e2eaf6;
  --additional-gray-blue-20: rgba(226, 234, 246, 0.2);
  --additional-light-blue: #e4efff;
  --blue-twinkle: #d1d8e0;
  --button-default-disabled: #ffdaa3;
  --button-default-enabled: #f29100;
  --button-default-focused: #ffad33;
  --button-default-focused-10: #f0f6ff;
  --button-default-pressed: #f29100;
  --button-default-pressed-15: #ffecd1;
  --button-icon-enabled: #e4efff;
  --button-icon-focused: #f0f6ff;
  --button-icon-pressed: #f29100;
  --button-outline-focused: #e8f5fc;
  --button-primary-disabled: #fff380;
  --button-primary-enabled: #fee801;
  --button-primary-focused: #ffad33;
  --button-primary-focused-10: #ffecce;
  --button-primary-pressed: #ead501;
  --button-primary-pressed-15: #fcf9d9;
  --button-secondary-disabled: #f1f5fa;
  --button-secondary-enable: #e2eaf6;
  --button-secondary-focused: #dae2ee;
  --button-secondary-pressed: #d3deed;
  --functional-error-10: rgba(255, 124, 148, 0.1);
  --functional-error-15: rgba(255, 124, 148, 0.15);
  --functional-success: #15cf74;
  --functional-success-bold: #28a745;
  --functional-success-10: rgba(21, 207, 116, 0.1);
  --functional-warning: #ff7d95;
  --functional-warning-bold: #e20000;
  --gray-6: #f2f2f2;
  --neutral-dark-blue: #000;
  --neutral-dark-blue-70: #000;
  --neutral-dark-blue-50: #000;
  --neutral-gray-blue: #000;
  --neutral-gray-blue-50: #000;
  --neutral-gray: #d1d8e0;
  --neutral-light-gray: #f7f9fc;
  --text-color: #000;
  --badge-color: #f29100;
  --light-white: #fafafa;
  --placeholder-color: rgba(75, 101, 132, 0.8);
  --modal-btn-color: rgba(255, 255, 255, 0.6);
  --modal-footer-btn-color: #405268;
  --theme-accent: #f29100;
  --theme-primary: #f29100;
}

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.77a030c8.eot);
  src: url(/static/media/icomoon.77a030c8.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.69afc0f2.ttf) format('truetype'), url(/static/media/icomoon.f94cc7bc.woff) format('woff'),
    url(/static/media/icomoon.9a71bce7.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-speaker:before {
  content: '\e91c';
}
.icon-zoom:before {
  content: '\e908';
}
.icon-star-line:before {
  content: '\e900';
}
.icon-profile:before {
  content: '\e931';
}
.icon-arrow-fill:before {
  content: '\e918';
}
.icon-arrow:before {
  content: '\e917';
}
.icon-close:before {
  content: '\e909';
}
.icon-grid:before {
  content: '\e90a';
}
.icon-list:before {
  content: '\e91b';
}
.icon-star-fill:before {
  content: '\e907';
}
.icon-driving-school:before {
  content: '\e901';
}
.icon-check:before {
  content: '\e902';
}
.icon-multilang:before {
  content: '\e904';
}
.icon-theory:before {
  content: '\e905';
}
.icon-support:before {
  content: '\e90b';
}
.icon-email:before {
  content: '\e90c';
}
.icon-share:before {
  content: '\e90d';
}
.icon-whatsapp:before {
  content: '\e90e';
}
.icon-facebook:before {
  content: '\e90f';
}
.icon-instagram:before {
  content: '\e910';
}
.icon-twitter:before {
  content: '\e911';
}
.icon-linkedine:before {
  content: '\e912';
}
.icon-e-bike:before {
  content: '\e913';
}
.icon-tractor:before {
  content: '\e914';
}
.icon-motorcycle:before {
  content: '\e915';
}
.icon-car:before {
  content: '\e916';
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
}

h1,
.h1 {
  font-size: 38px;
  line-height: 60px;
}

h2,
.h2 {
  font-size: 36px;
  line-height: 44px;
}

h3,
.h3 {
  font-size: 28px;
  line-height: 42px;
}

h4,
.h4 {
  font-size: 26px;
  line-height: 36px;
}

h5,
.h5 {
  font-size: 24px;
  line-height: 36px;
}

h6,
.h6 {
  font-size: 20px;
  line-height: 30px;
}

.h7 {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.h8 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

p {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
}

.p3 {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

@media (max-width: 991px) {
  h3,
  .h3 {
    font-size: 24px;
    line-height: 36px;
  }
}

button,
button:hover {
  border-color: transparent;
}

@font-face {
  font-family: 'hind';
  src: url(/static/media/hind-medium-webfont.331233c9.eot);
  src: url(/static/media/hind-medium-webfont.b0a02140.woff2) format('woff2'),
    url(/static/media/hind-medium-webfont.b33ed408.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'hind';
  src: url(/static/media/hind-regular-webfont.45246401.eot);
  src: url(/static/media/hind-regular-webfont.5e2fe7a4.woff2) format('woff2'),
    url(/static/media/hind-regular-webfont.2ec80077.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Should be removed to disable scroll top when modal open */
/* html,
body {
  overflow-x: hidden;
} */

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  background-color: #e9f6fe;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.form-control:focus {
  box-shadow: none;
}

.form-control {
  color: var(--placeholder-color);
  padding: 8px 12px;
  height: 44px;
}

.form-control:-ms-input-placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.form-control::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul,
ol {
  list-style: none;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--theme-primary);
  transition: all ease 0.3s;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
  color: var(--button-default-focused);
}

*,
:focus,
a,
a:focus,
button,
button:focus {
  outline: none;
}

video {
  display: inline-block;
}

#root {
  /*max-width: 1440px;*/
  margin: 0 auto;
  background-color: var(--whiteColor);
}

/*********************************BUTTON*********************************/

.btnLabel {
  color: var(--theme-primary);
  text-decoration: none;
  transition: all ease-in 0.3s;
  background-color: transparent;
}

.btnLabel:hover,
.btnLabel:focus,
.btnLabel.hover,
.btnLabel.focus {
  background-color: var(--button-default-focused-10);
  border-color: var(--button-default-focused-10);
  color: var(--theme-primary);
}

.btn {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  letter-spacing: 0.02px;
  padding: 6px 24px;
  border-radius: 4px;
  transition: all ease 0.3s;
  min-width: 104px;
  border-width: 2px;
}

.btn:first-letter {
  text-transform: uppercase;
}

.btn.focus,
.btn:focus,
.btn.active,
.btn:active {
  outline: 0;
  box-shadow: none;
}

.btn-primary {
  background-color: var(--theme-accent);
  border-color: var(--theme-accent);
  color: var(--whiteColor);
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:hover {
  background-color: var(--button-primary-focused);
  border-color: var(--button-primary-focused);
  color: var(--blackColor);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--button-primary-disabled);
  border-color: var(--button-primary-disabled);
  color: rgba(var(--blackColor), 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--button-primary-pressed);
  border-color: var(--button-primary-pressed);
  color: var(--blackColor);
}

.btn-secondary {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: var(--whiteColor);
}

.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  background-color: var(--button-default-focused);
  border-color: var(--button-default-focused);
  color: var(--whiteColor);
  box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: var(--button-default-disabled);
  border-color: var(--button-default-disabled);
  color: var(--whiteColor);
}

.btn-outline-primary {
  border-color: var(--theme-accent);
  color: var(--blackColor);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: var(--button-primary-focused-10);
  border-color: var(--button-primary-focused);
  color: var(--blackColor);
  box-shadow: none;
}

.btn-outline-secondary {
  border-color: var(--theme-primary);
  color: var(--neutral-dark-blue);
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--button-outline-focused);
  border-color: var(--theme-primary);
  color: var(--neutral-dark-blue);
  box-shadow: none;
}

.linkBtn {
  display: flex;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: var(--theme-primary);
}

.linkBtn > .icon-arrow {
  color: currentColor;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 2px;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background-color: var(--modal-btn-color);
  padding: 0;
  min-width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051;
}

.closeBtn .icon-close {
  color: var(--theme-primary);
}

.closeBtn:focus,
.closeBtn:hover,
.closeBtn:visited {
  background-color: transparent;
}

.closeBtnWhite,
.closeBtnWhite:focus,
.closeBtnWhite:hover,
.closeBtnWhite:visited {
  color: var(--whiteColor);
}

.closeBtnBlue,
.closeBtnBlue:focus,
.closeBtnBlue:hover,
.closeBtnBlue:visited {
  color: var(--theme-primary);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: var(--theme-primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary.dropdown-toggle {
  color: var(--theme-primary);
}

/*********************************BUTTON END*********************************/

/**********************************MODAL***************************************/

.modal-dialog.modal-sm {
  max-width: 346px;
}

.modal-dialog.modal-lg {
  max-width: 740px;
}

.modal-content {
  border-radius: 6px;
}

.modal-header button.close {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 32px;
  height: 32px;
  margin: 0;
  opacity: 1;
  font-weight: normal;
  cursor: pointer;
  text-shadow: none;
}

button.close > span {
  opacity: 0;
}

.modal-header button.close:before {
  content: '\e909';
  position: absolute;
  font-family: 'icomoon';
  font-size: 32px;
  color: var(--theme-primary);
  left: 0;
  top: 0;
}

.modal-open {
  height: 100vh;
}

.modal.show .modal-dialog {
  width: 100%;
  margin: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal.fade .modal-dialog {
  transition: all 0.3s ease;
}

.modalLogin .modal-header {
  padding: 20px;
}

.modalLogin .modal-body,
.modalLogout .modal-body,
.modalLogout .modal-header {
  padding: 0 20px;
}

.modal-body .btn-link {
  display: inline-block;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  color: var(--neutral-dark-blue);
  margin-top: 18px;
}

.modal-content {
  border: none;
}

.modal-footer {
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  border-top: none;
}

.modal-sm .modal-footer .btn {
  width: 100%;
  border-radius: 0 0 6px 6px;
  text-align: center;
}

.modal-sm .modal-footer .btn:nth-last-child(2) {
  display: inline-block;
  text-align: center;
}

.modal-footer > :not(:first-child) {
  margin-left: 0;
}

.modal-footer > :not(:last-child) {
  margin-right: 0;
}

.modal-footer > .btn-link {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: var(--modal-footer-btn-color);
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modalQuestion .modal-content {
  padding: 34px 50px 24px;
}

.modalQuestion .modal-footer {
  justify-content: flex-end;
}

.modalQuestion .modal-body,
.modalQuestion .modal-header {
  padding: 0;
}

.modalQuestion .closeBtn {
  left: auto;
  right: 14px;
  top: 14px;
}

.askContentTitle {
  color: var(--neutral-dark-blue);
}

.askContentText {
  color: var(--neutral-gray-blue);
}

.navbar-toggler {
  z-index: 1039;
}

@media (max-width: 991px) {
  .navbar.navbar-expand-lg > .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .navbar {
    padding: 0;
  }
}

.collapsing {
  position: relative;
  left: 0;
  top: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.35s ease;
}

.navbar-collapse.collapse {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColor);
}

.navbar-collapse.collapse.show {
  position: relative;
  left: 0;
  top: 0;
  background-color: var(--whiteColor);
  transition-duration: 0.55s;
}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-light .navbar-nav .nav-link {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  transition: 0.3s;
  color: var(--neutral-dark-blue);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--button-default-pressed);
  font-weight: 500;
}

.pageSubtitle {
  color: var(--neutral-dark-blue);
  margin-bottom: 32px;
}

.pageText {
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.pageTextMd {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
}

.dropdown-menu {
  border: none;
  padding: 0;
}

.dropdown-item {
  padding: 0 30px 0 0;
}

.imgContainer img {
  width: auto;
  height: 100%;
}

.demoVersion {
  position: absolute;
  top: -10px;
  right: 35%;
  z-index: 100;
  background: url(/static/media/demo-label.7dd2ba2f.svg) no-repeat 50% 50%;
  background-size: contain;
  height: 100px;
  width: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demoText {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--blackColor);
  max-width: 100%;
  text-align: center;
}

.card-title {
  font-size: 36px;
  line-height: 44px;
  color: var(--neutral-dark-blue);
}

.rsbtn {
  display: none !important;
}

/*************************************MEDIA*****************************************/

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 1199px) {
  .pageSubtitle {
    margin-bottom: 16px;
  }

  .rulesListItemBg {
    width: 60px;
    height: 60px;
  }

  .pageSubtitle {
    text-align: center;
  }

  .promoItem:last-child {
    max-width: 45%;
  }

  .demoVersion {
    right: 20%;
    width: 60px;
    height: 50px;
  }

  .demoText {
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
  }
}

@media (max-width: 991px) {
  #root {
    position: relative;
  }

  .navbar {
    position: static;
  }

  .navbar-light .navbar-nav .nav-link {
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .demoVersion {
    display: none;
  }

  .navbar-collapse {
    padding: 0 65px;
  }

  .modalQuestion .modal-content {
    padding: 40px 24px 14px 24px;
  }
}

@media (max-width: 767px) {
  .pageSubtitle {
    text-align: left;
  }
}

@media screen and (orientation: landscape) and (max-device-width: 991px) {
  html {
    overflow-y: auto;
  }

  .navbar-collapse.collapse.show {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
    height: 100%;
  }

  .modal-content {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .modal-open .modal {
    overflow-y: hidden;
  }

  .modal.show .modal-dialog {
    height: 100%;
  }

  .modalLogin.modal.show .modal-dialog {
    height: unset;
  }

  .modal-content .modal-footer {
    overflow: visible;
  }
}

.notificationSWContainer {
  max-width: 1440px;
  position: fixed;
  z-index: 11;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.notificationSWContainer button {
  margin-left: 15px;
}

@media (max-width: 450px) {
  .notificationSWContainer {
    font-size: 13px;
  }
}

#SWNotification {
  display: none;
}

.Header_headerTop__33dcI {
  position: relative;
  background-color: var(--light-white);
  z-index: 10;
}

.Header_headerTop__33dcI .navbar-nav {
  align-items: center;
}

.Header_headerTop__33dcI .navbar-nav {
  align-items: center;
}

.Header_headerTopContent__3alrr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.Header_headerTopLinkList__kVW9c {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.Header_headerTopLinkList__kVW9c li:not(:last-child) {
  margin-right: 8px;
}

.Header_headerTopLinkItem__2Vs1_ {
  color: var(--theme-primary);
  transition: all ease-in-out 0.4s;
}

.Header_headerTopLinkItem__2Vs1_:hover {
  text-decoration: none;
  color: var(--theme-primary);
}

.Header_langBtnGroup__2j9-r {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.Header_langBtnGroup__2j9-r .Header_button__3kSo_:not(:first-child) {
  margin-left: 28px;
}

.Header_langBtnGroup__2j9-r .Header_button__3kSo_ {
  font-family: 'Hind', sans-serif;
  font-size: 13px;
  line-height: 21px;
  color: var(--neutral-dark-blue);
  transition: all ease-in-out 0.4s;
  border: none;
  background: transparent;
}

.Header_langBtnGroup__2j9-r .Header_button__3kSo_:hover {
  color: var(--theme-primary);
  cursor: pointer;
}

.Header_mobileButtons__wkA7r {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.Header_demoVersionMobile__NiKZa {
  position: relative;
  display: flex;
  margin-right: 23px;
  justify-content: flex-end;
  background: url(/static/media/demo-label.7dd2ba2f.svg) no-repeat 50% 50%;
  background-size: contain;
  height: 60px;
  width: 60px;
  align-items: center;
  padding: 0;
}

.Header_demoVersionMobile__NiKZa:before {
  position: absolute;
  content: '';
  left: 0;
  top: -16px;
  width: 100%;
  height: 30px;
  background-color: var(--theme-accent);
  z-index: 1;
}

.Header_demoText__1xTep {
  font-family: 'Hind', sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--blackColor);
  max-width: 100%;
  text-align: center;
}

.navbar {
  padding: 0;
  z-index: 10;
}

.Header_accountBtn__1rLih i {
  color: var(--theme-primary);
  font-size: 24px;
}

.Header_accountBtn__1rLih {
  display: none;
}

@media (max-width: 991px) {
  .Header_headerTop__33dcI {
    display: none;
  }

  .Header_accountBtn__1rLih {
    display: flex;
  }
}

@media (min-width: 992px) {
  .Header_demoVersionMobile__NiKZa {
    display: none;
  }
}

.HeaderMenu_loginBtnMobile__1I4pu,
.HeaderMenu_logoutBtnMobile__1won1 {
  display: none;
  color: var(--neutral-dark-blue);
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.HeaderMenu_loginBtn__O2Qqo,
.HeaderMenu_logoutBtn__3cwYF {
  display: block;
}

.HeaderMenu_langIconGroup__1mE3U {
  display: none;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 44px;
}

.HeaderMenu_navbar-nav__11zqn .HeaderMenu_nav-link__2G1W- {
  color: var(--neutral-dark-blue);
  transition: all ease 0.3s;
}

.HeaderMenu_navbar-nav__11zqn .HeaderMenu_nav-link__2G1W-.HeaderMenu_active__3GGdE,
.HeaderMenu_navbar-nav__11zqn .HeaderMenu_nav-link__2G1W-:hover {
  color: var(--button-default-pressed);
}

.HeaderMenu_loginBtnGroup__2aQB1 {
  display: flex;
  align-items: center;
}

.HeaderMenu_loginBtnItem__21PWV {
  margin-right: 35px;
}

@media (max-width: 1199px) {
  .HeaderMenu_loginBtnItem__21PWV {
    margin-right: 10px;
  }
}

@media (max-width: 1150px) {
  .mr-auto .nav-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}

@media (max-width: 991px) {
  .HeaderMenu_loginBtnMobile__1I4pu,
  .HeaderMenu_logoutBtnMobile__1won1 {
    display: block;
  }

  .mr-auto .nav-link {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    max-width: unset;
  }

  .mr-auto .nav-link {
    word-break: unset;
    text-align: unset;
  }

  .HeaderMenu_loginBtnItem__21PWV.HeaderMenu_desktop__35J69 {
    display: none;
  }

  .HeaderMenu_loginBtnGroup__2aQB1 {
    align-items: flex-start;
  }

  .HeaderMenu_loginBtn__O2Qqo,
  .HeaderMenu_logoutBtn__3cwYF {
    display: none;
  }

  .HeaderMenu_langIconGroup__1mE3U {
    display: flex;
  }
}

.Loader_loader__2J6fK {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  transition: all 0.3s ease;
}

.Loader_showLoader__QlAML {
  display: flex;
}

.Loader_blockLoader__3cPQl {
  position: absolute;
}

.Loader_loader__2J6fK > i {
  font-size: 100px;
  width: 100px;
  height: 100px;
  color: var(--button-default-enabled);
  text-align: center;
  animation: Loader_rotate__2usO4 2s infinite;
}

@keyframes Loader_rotate__2usO4 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.Counter_counter__3VqRh {
  width: 55px;
  padding: 8px 6px;
  text-align: center;
}

.Checkbox_label__kbrG4 {
  display: flex;
  align-items: flex-start;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}

.Checkbox_inputField__30gaE {
  display: none;
}

.Checkbox_labelBox__2pJvi {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(119, 140, 163, 0.4);
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  padding: 0;
  margin-right: 16px;
  transition: all ease-in 0.3s;
  background-color: var(--whiteColor);
}

.Checkbox_checkboxField__1yYEw {
  display: flex;
  margin-bottom: 6px;
  padding: 6px;
  border-radius: 6px;
  min-height: 60px;
}

.Checkbox_labelBox__2pJvi.Checkbox_checked__2w8Ak {
  border-color: var(--neutral-dark-blue-70);
}

.Checkbox_labelBox__2pJvi.Checkbox_correct___n2sY {
  border-color: var(--functional-success-bold);
  border-width: 2px;
}

.Checkbox_labelBox__2pJvi.Checkbox_incorrect__3GKdM {
  border-color: var(--functional-warning-bold);
  border-width: 2px;
}

.Checkbox_labelBox__2pJvi.Checkbox_checked__2w8Ak,
.Checkbox_labelBox__2pJvi.Checkbox_correct___n2sY,
.Checkbox_labelBox__2pJvi.Checkbox_incorrect__3GKdM {
  position: relative;
}

span.Checkbox_checked__2w8Ak:before,
span.Checkbox_correct___n2sY:before,
span.Checkbox_incorrect__3GKdM:before {
  position: absolute;
  font-size: 30px;
  font-weight: 500;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

span.Checkbox_checked__2w8Ak:before {
  padding-top: 5px;
  padding-left: 1px;
  content: '\00D7';
  color: var(--blackColor);
}

span.Checkbox_checked__2w8Ak.Checkbox_correct___n2sY:before {
  font-family: 'icomoon';
  font-size: 25px;
  content: '\e902';
  font-weight: 800;
  padding-top: 1px;
  top: 0;
  color: var(--functional-success-bold);
}

span.Checkbox_incorrect__3GKdM:before {
  color: var(--functional-warning-bold);
}

p.Checkbox_correct___n2sY {
  background-color: var(--functional-success-10);
}

p.Checkbox_incorrect__3GKdM {
  background-color: var(--whiteColor);
}

p.Checkbox_checked__2w8Ak {
  background-color: var(--functional-error-10);
}

p.Checkbox_checked__2w8Ak .Checkbox_labelBox__2pJvi.Checkbox_checked__2w8Ak:before {
  content: '';
}

p.Checkbox_checked__2w8Ak .Checkbox_labelBox__2pJvi.Checkbox_checked__2w8Ak {
  border-color: var(--functional-warning);
}

.Checkbox_showCorrect__2ELPp {
  background-color: var(--functional-success-10);
}

.QuestionView_questionContainer__2-h2M {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  padding: 24px 24px 32px;
  margin-bottom: 30px;
  border-radius: 6px;
  align-items: stretch;
  border: 1px solid transparent;
}

.QuestionView_videoImage__1a0S7 span {
  display: none;
}

.QuestionView_videoImage__1a0S7 img {
  display: block !important;
}

.QuestionView_questionContent__3n0UR {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100%;
  max-width: 33.3%;
  width: 100%;
  margin-left: 60px;
  align-self: stretch;
}

.QuestionView_questionList__3dXE7 {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.QuestionView_questionTitle__3HgRM {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-dark-blue-70);
  min-height: 72px;
}

.QuestionView_questionPaginator__2tIoI {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.QuestionView_btnPrev__6WuvG,
.QuestionView_btnNext__YKZ97,
.QuestionView_btnExit__11GMh {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}

.QuestionView_btnCenter__5j_P2 {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02px;
  color: var(--whiteColor);
  flex-grow: 1;
  border-radius: 4px;
  padding: 0;
  height: 48px;
  opacity: 1 !important;
}

.QuestionView_btnCorrectAnswer__1URwq {
  border-color: #28a745 !important;
  background-color: #28a745 !important;
}

.QuestionView_btnWrongAnswer__2TkIw {
  border-color: #e20000 !important;
  background-color: #e20000 !important;
}

.QuestionView_btnNext__YKZ97,
.QuestionView_btnExit__11GMh {
  margin-left: 10px;
}

.QuestionView_btnPrev__6WuvG {
  margin-right: 10px;
}

.QuestionView_btnPrev__6WuvG > i {
  transform: rotate(180deg);
}

.QuestionView_btnPrev__6WuvG > i,
.QuestionView_btnNext__YKZ97 > i {
  font-size: 20px;
}

.QuestionView_questionListItem__220i2 {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  margin-bottom: 12px;
}

.QuestionView_trueAnswer__MjwaR {
  border: 1px solid var(--functional-success);
}

.QuestionView_falseAnswer__3g4zZ {
  border: 1px solid var(--functional-warning);
}

@media (max-width: 991px) {
  .QuestionView_questionContainer__2-h2M {
    flex-flow: row wrap;
  }

  .QuestionView_questionContent__3n0UR {
    max-width: none;
    width: 100%;
    margin-left: 0;
    padding: 0 10px;
  }

  .QuestionView_questionPaginator__2tIoI {
    max-width: 50%;
    width: 100%;
    margin-top: 18px;
  }

  .QuestionView_questionList__3dXE7 {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .QuestionView_questionPaginator__2tIoI {
    max-width: 100%;
  }

  .QuestionView_questionContainer__2-h2M {
    padding: 0 0 20px;
  }

  .QuestionView_originLink__a5Tg1 {
    display: none;
  }

  .QuestionView_originLinkMobile__Nc7Gf {
    display: inline-block;
  }
}

.ZoomMultimedia_imgContainer-main__2sMLj {
  position: relative;
  background: #f4efef url(/static/media/img-placeholder.e4ae5da5.svg) no-repeat 50% -44px;
  background-size: contain;
  border-radius: 6px;
  padding-top: 40.25%;
  width: 100%;
}

.ZoomMultimedia_imgContainer-sidebar__1n9bc {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.ZoomMultimedia_imgContainer-sidebar__1n9bc .ZoomMultimedia_questionImgContent__2S9tZ {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.ZoomMultimedia_imgContainer-main__2sMLj .ZoomMultimedia_questionImgContent__2S9tZ {
  position: absolute;
  left: 0;
  top: 50%;
  height: 100%;
  border-radius: 6px;
  transform: translateY(-50%);
  object-fit: cover;
  width: 100%;
}

.ZoomMultimedia_videoContainer__xvlgS {
  position: relative;
  flex-flow: column;
  justify-content: flex-start;
  padding-top: 47.75%;
  overflow: hidden;
  border-radius: 6px;
  width: 100%;
  height: auto;
}

.ZoomMultimedia_videoContainer__xvlgS video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.ZoomMultimedia_videoContainer__xvlgS .ZoomMultimedia_zoomBtn__1Jyel {
  position: absolute;
  right: 10px;
  top: 6px;
  transition: all ease 0.3s;
}

.ZoomMultimedia_sidebarZoomBtn__2U0E3 {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

@media (max-width: 991px) {
  .ZoomMultimedia_videoContainer__xvlgS {
    padding-top: 56.25%;
  }

  .ZoomMultimedia_imgContainer-main__2sMLj .ZoomMultimedia_questionImgContent__2S9tZ {
    width: 100%;
    height: 100%;
  }

  .ZoomMultimedia_imgContainer-main__2sMLj {
    max-width: none;
    margin-bottom: 14px;
    padding-top: 56.25%;
  }

  .ZoomMultimedia_imgContainer-sidebar__1n9bc img,
  .ZoomMultimedia_imgContainer-sidebar__1n9bc .ZoomMultimedia_sidebarZoomBtn__2U0E3 {
    display: none;
  }

  .ZoomMultimedia_videoContainer__xvlgS .ZoomMultimedia_zoomBtn__1Jyel {
    display: none;
  }
}

.MultimediaModal_multiModal__1zW6b .modal-content {
  background-color: transparent;
  position: static;
}

.MultimediaModal_multiModal__1zW6b .modal-header button.close {
  position: fixed;
  top: 0;
  right: 0;
}

.MultimediaModal_multiModal__1zW6b .modal-header button.close:before {
  color: var(--whiteColor);
}

.MultimediaModal_multiModal__1zW6b .modal-body {
  padding: 0;
}

.MultimediaModal_multiModal__1zW6b .modal-body img {
  border-radius: 10px;
}

.MultimediaModal_zoomImg__1sJ6r {
  width: 100%;
}

.MultimediaModal_videoContent__GgjRE {
  width: 100%;
}

.ZoomBtn_zoomBtn__2uD_8 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: var(--whiteColor);
  border-radius: 6px;
  cursor: pointer;
  color: var(--theme-primary);
  transition: all ease 0.3s;
  border: 2px solid transparent;
}

.ZoomBtn_zoomBtn__2uD_8 i {
  font-size: 26px;
}

.ZoomBtn_zoomBtn__2uD_8:hover {
  border: 2px solid var(--theme-primary);
}

@media (max-width: 991px) {
  .ZoomBtn_zoomBtn__2uD_8 {
    width: 32px;
    height: 32px;
    right: 10px;
    bottom: 10px;
  }

  .ZoomBtn_zoomBtn__2uD_8 i {
    font-size: 20px;
  }
}

.ZoomLink_zoomLink__3H3-L {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue-70);
  pointer-events: none;
}

@media (max-width: 991px) {
  .ZoomLink_zoomLink__3H3-L {
    color: var(--button-default-focused);
    pointer-events: auto;
    text-decoration: underline;
    transition: all ease 0.3s;
  }

  .ZoomLink_zoomLink__3H3-L:hover {
    cursor: pointer;
  }
}

.QuestionBar_questionBtnList__1O9l4 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}

.QuestionBar_btnPrev__2tyfF {
  transform: rotate(180deg);
}

.QuestionBar_questionBtnListItem__1Nq0j {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--theme-primary);
  border: none;
  padding: 0;
}

.QuestionBar_questionBtnListItem__1Nq0j > i {
  font-size: 36px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.QuestionBar_questionBtnListItem__1Nq0j:not(:last-child) {
  margin-right: 10px;
}

.QuestionBar_lngBtn__29HdW {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 5px;
  padding: 3px;
}

@media (max-width: 991px) {
  .QuestionBar_questionBtnListItem__1Nq0j {
    width: 32px;
    height: 32px;
  }

  .QuestionBar_questionBtnListItem__1Nq0j > i {
    font-size: 26px;
  }
}

.LngDropDown_dropDownToggle__1gsez {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  min-width: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.LngDropDown_dropDownToggle__1gsez:hover,
.LngDropDown_dropDownToggle__1gsez:focus {
  background-color: transparent;
  border-color: transparent;
}

.LngDropDown_dropdown-toggle__3A8-b::after {
  border-top: 6px solid;
  border-color: var(--theme-primary);
}

.LngDropDown_barBtn__2oIuo .LngDropDown_dropMenu__396Ii {
  max-width: 70px;
  width: 100%;
  min-width: auto;
  transform: none !important;
  top: 32px;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  margin: 0;
}

.flag {
  width: 36px;
  height: 36px;
  margin: 6px;
  border-radius: 50%;
}

.show > .btn-primary.dropdown-toggle {
  position: relative;
  z-index: 9999;
}

@media (max-width: 991px) {
  .flag {
    width: 24px;
    height: 24px;
    margin: 4px;
  }
}

.QuestionChooseModalContainer_examList__1BLow {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  max-height: 364px;
  overflow-y: auto;
}

.QuestionChooseModalContainer_examList__1BLow::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.QuestionChooseModalContainer_examList__1BLow::-webkit-scrollbar {
  width: 12px;
}

.QuestionChooseModalContainer_examList__1BLow::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.QuestionChooseModalContainer_examListItem__1_2L2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid var(--additional-gray-blue);
  background: var(--whiteColor);
}

.QuestionChooseModalContainer_examListItemCorrect__ftOGk {
  border-color: var(--functional-success);
  border-width: 2px;
  background-color: var(--functional-success-10);
}

.QuestionChooseModalContainer_examListItemCorrect__ftOGk::after {
  content: ' ';
  display: inline-block;
  transform: rotate(45deg);
  height: 19px;
  width: 11px;
  border-bottom: 4px solid var(--functional-success);
  border-right: 4px solid var(--functional-success);
  top: -5px;
  position: absolute;
  right: 0;
  z-index: 9;
}

.QuestionChooseModalContainer_examListItemCorrect__ftOGk span::before {
  content: ' ';
  display: inline-block;
  transform: rotate(45deg);
  height: 19px;
  width: 12px;
  border-bottom: 5px solid var(--whiteColor);
  border-right: 5px solid var(--whiteColor);
  top: -5px;
  position: absolute;
  right: 0;
}

.QuestionChooseModalContainer_examListItemIncorrect__3TKYl {
  border-color: var(--functional-warning);
  border-width: 2px;
}

.QuestionChooseModalContainer_examListItemIncorrect__3TKYl::after {
  content: 'x';
  display: inline-block;
  height: 21px;
  width: 11px;
  color: var(--functional-warning);
  font-size: 26px;
  font-weight: 700;
  top: -15px;
  position: absolute;
  right: 0;
  z-index: 9;
}

.QuestionChooseModalContainer_examListItemIncorrect__3TKYl span::before {
  content: 'x';
  display: inline-block;
  height: 21px;
  width: 11px;
  color: var(--whiteColor);
  font-size: 29px;
  font-weight: 700;
  top: -17px;
  position: absolute;
  right: 1px;
}

.QuestionChooseModalContainer_favorite__3kzmr:before {
  position: absolute;
  content: '';
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--button-primary-pressed);
  z-index: 1;
}

.QuestionChooseModalContainer_checked__2dp4a {
  background-color: var(--button-secondary-pressed);
}

.QuestionChooseModalContainer_questionsNumbers__2v-Zm {
  /*position: relative;*/
  z-index: 2;
}

@media (max-width: 991px) {
  .QuestionChooseModalContainer_examListItem__1_2L2 {
    margin: 5px;
  }

  .QuestionChooseModalContainer_examList__1BLow::-webkit-scrollbar {
    width: 8px;
  }
}

.ReadSpeaker_disabledButton__MNMr0 {
  pointer-events: none;
  opacity: 0.4;
}

.ReadSpeaker_stopBtn__2Obby {
  color: var(--whiteColor);
  background-color: var(--theme-primary);
}

/*TODO remove after resolve readSpeaker issue */
.ReadSpeaker_hideButton__1ZLYq {
  visibility: hidden;
}

.P3_p3__1_bI8 {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.Image_imgContainer__1GjYu img {
  height: auto;
}

.PlaceholderBox_root__1Dtr3 {
  height: 300px;
  width: 100%;
  background-color: var(--lavender-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
  margin-bottom: 15px;
}

.PlaceholderBox_root__1Dtr3 > * {
  max-width: 60%;
  max-height: 70%;
}

.TheoryBook_questionBtnListItem__7FZK0 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  cursor: pointer;
  color: var(--theme-primary);
  border-radius: 2px;
  margin-bottom: 12px;
}

.TheoryBook_glossaryHeader__2-8Pu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.TheoryBook_glossaryHeader__2-8Pu .glossary-title {
  margin-right: 10px;
}

.TheoryBook_questionBtnListItem__7FZK0 > i {
  font-size: 48px;
}

.TheoryBook_glossaryFooter__3DPP8 {
  position: absolute;
  bottom: 0;
  left: 44px;
  right: 44px;
  padding: 38px 44px 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
}

.TheoryBook_footerLink__2egRD {
  display: none;
}

.TheoryBook_btnPrev__3a6iB,
.TheoryBook_btnNext__2ycYk {
  position: absolute;
  top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: var(--additional-gray-blue);
  border-radius: 6px;
  transition: all ease 0.3s;
  min-width: auto;
}

.TheoryBook_btnPrev__3a6iB:disabled,
.TheoryBook_btnNext__2ycYk:disabled {
  opacity: 0;
  pointer-events: none;
}

.TheoryBook_btnPrev__3a6iB > i,
.TheoryBook_btnNext__2ycYk > i {
  font-size: 20px;
  color: var(--neutral-gray-blue);
}

.TheoryBook_btnPrev__3a6iB {
  transform: rotate(180deg);
  left: 0;
}

.TheoryBook_btnNext__2ycYk {
  right: 0;
}

.TheoryBook_btnPrev__3a6iB:hover,
.TheoryBook_btnNext__2ycYk:hover,
.TheoryBook_btnPrev__3a6iB:focus,
.TheoryBook_btnNext__2ycYk:focus,
.TheoryBook_btnPrev__3a6iB:not(:disabled):not(.TheoryBook_disabled__1n3yw):active,
.TheoryBook_btnNext__2ycYk:not(:disabled):not(.TheoryBook_disabled__1n3yw):active,
.TheoryBook_btnPrev__3a6iB:not(:disabled):not(.TheoryBook_disabled__1n3yw).TheoryBook_active__1WX4b,
.TheoryBook_btnNext__2ycYk:not(:disabled):not(.TheoryBook_disabled__1n3yw).TheoryBook_active__1WX4b,
.TheoryBook_btnPrev__3a6iB:not(:disabled):not(.TheoryBook_disabled__1n3yw):active:focus,
.TheoryBook_btnNext__2ycYk:not(:disabled):not(.TheoryBook_disabled__1n3yw):active:focus {
  background-color: var(--button-secondary-enable);
  box-shadow: none;
}

.glossary-subtitle {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
}

.glossary-subtitle, .glossary-text {
  margin-bottom: 12px;
  display: block;
  width: 100%;
}

.glossary-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 20px;
}

.TheoryBook_glossaryImgList__b8htd {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.TheoryBook_imgContent__2VyPb,
.TheoryBook_imgPlaceholderContent__2LZwF {
  display: inline-flex;
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-right: 24px;
}

.TheoryBook_imgPlaceholderContent__2LZwF {
  width: 160px;
  height: 120px;
}

.TheoryBook_glossaryScrollBox__20HmE {
  max-height: calc(100% - 100px);
  height: 100%;
  overflow-y: auto;
}

.TheoryBook_glossaryScrollBox__20HmE::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TheoryBook_glossaryScrollBox__20HmE::-webkit-scrollbar {
  width: 12px;
}

.TheoryBook_glossaryScrollBox__20HmE::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.TheoryBook_modal__kjcJ0,
.TheoryBook_backdrop__3mipi,
.TheoryBook_topicLink__157Kq {
  display: none !important;
}

@media (max-width: 991px) {
  .TheoryBook_questionBtnListItem__7FZK0 {
    width: 32px;
    height: 32px;
  }

  .TheoryBook_questionBtnListItem__7FZK0 > i {
    font-size: 32px;
  }

  .TheoryBook_glossaryScrollBox__20HmE {
    max-height: none;
    overflow-y: inherit;
  }

  .TheoryBook_imgPlaceholderContent__2LZwF {
    width: 120px;
    height: 80px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .TheoryBook_glossaryScrollBox__20HmE.TheoryBook_withModal__1QTsq {
    display: none;
  }

  .TheoryBook_modal__kjcJ0,
  .TheoryBook_backdrop__3mipi,
  .TheoryBook_topicLink__157Kq {
    display: block !important;
  }

  .TheoryBook_topciNumberWrapper__-6FmM {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .TheoryBook_topicNumber__dSgmv {
    margin-bottom: 0;
  }

  .TheoryBook_glossaryFooter__3DPP8 {
    position: relative;
    left: 0;
    right: 0;
    padding: 0;
    height: 48px;
  }

  .TheoryBook_btnPrev__3a6iB,
  .TheoryBook_btnNext__2ycYk {
    top: 0;
  }
}

.TheoryBookContainer_glossaryContent__1yiZW {
  position: relative;
  padding: 40px 44px 44px;
  background-color: var(--whiteColor);
  border-radius: 6px;
  flex-grow: 1;
  width: 100%;
}

.TheoryBookContainer_glossaryScrollBox__2uovs::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TheoryBookContainer_glossaryScrollBox__2uovs::-webkit-scrollbar {
  width: 12px;
}

.TheoryBookContainer_glossaryScrollBox__2uovs::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

@media (max-width: 991px) {
  .TheoryBookContainer_glossaryContent__1yiZW {
    padding: 0;
  }
}

.TheoryModal_theoryModal__3ZnX0 .modal-dialog {
  max-width: 1200px;
  max-height: 750px;
  height: 100%;
  overflow: hidden;
}

.TheoryModal_theoryModal__3ZnX0 .modal-body > div {
  height: 100%;
}

.TheoryModal_theoryModal__3ZnX0 .modal-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.Timer_timer__1_mjp {
  width: 54px;
  padding: 8px 6px;
  text-align: center;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-body {
  padding: 0 20px;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-header {
  padding: 20px;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-body {
  padding: 0 20px;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-body {
  padding: 0 20px;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-header {
  padding: 20px;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-body {
  padding: 0 20px;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*TODO: update after add loader in project*/

.ExamCardContainer_loader__2UNcL {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 30px;
  font-family: 'Ubuntu', sans-serif;
  color: var(--whiteColor);
  background-color: rgba(0,0,0,.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.ExamCardContainer_questionPage__3k6Ce {
  background-color: var(--lavender-grey);
}

.ExamCardContainer_questionLinkBottom__3q0DG {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 88px;
}

.ExamCardContainer_loaderContainer__mo2vC {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

.ExamCardContainer_questionLinkBottom__3q0DG .btnLabel:hover,
.ExamCardContainer_questionLinkBottom__3q0DG .btnLabel:focus {
  background-color: var(--button-default-focused-10);
}

.ExamCardContainer_questionBar__3_lgk {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.ExamCardContainer_doubleCounter__37IAd {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 6px;
  margin: 10px 32px 10px 0;
  border: 1px solid var(--additional-gray-blue);
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--neutral-gray-blue);
}

.ExamCardContainer_doubleCounter__37IAd > div:nth-child(2) {
  border-left: 1px solid var(--additional-gray-blue);
}

.ExamCardContainer_headerQuestionBox__1eOLB {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.ExamCardContainer_headerQuestionBtn__3DieI {
  border: 1px solid var(--button-secondary-enable);
  background-color: var(--whiteColor);
  padding: 5px 24px;
  border-radius: 6px;
  color: var(--neutral-dark-blue);
  max-width: 346px;
  width: 100%;
}

.ExamCardContainer_headerQuestionBtn__3DieI:hover,
.ExamCardContainer_headerQuestionBtn__3DieI:focus {
  text-decoration: none;
  color: var(--neutral-dark-blue);
}

.ExamCardContainer_headerQuestionBtn__3DieI > i {
  font-size: 16px;
  line-height: 1;
}

@media (max-width: 991px) {
  .ExamCardContainer_questionLinkBottom__3q0DG {
    padding-bottom: 40px;
  }

  .ExamCardContainer_questionLinkBottom__3q0DG .btnLabel {
    border: 2px solid var(--theme-primary);
  }
}

@media (max-width: 768px) {
  .ExamCardContainer_questionLinkBottom__3q0DG {
   justify-content: center;
  }
}

.PageTitle_pageTitle__1Iuxc {
  font-size: 40px;
  line-height: 60px;
  color: var(--neutral-dark-blue);
  margin-bottom: 12px;
}

@media (max-width: 1199px) {
  .PageTitle_pageTitle__1Iuxc {
    font-size: 32px;
    line-height: 43px;
  }
}

@media (max-width: 991px) {
  .PageTitle_pageTitle__1Iuxc {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 16px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .PageTitle_pageTitle__1Iuxc {
    text-align: left;
  }
}

.ExamResultInfo_resultPageItem__1EfkY .jumbotron {
  max-width: 440px;
  margin-bottom: 36px;
}

.ExamResultInfo_resultList__10GC6 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
  padding-right: 20px;
}

.ExamResultInfo_resultLabel__aZ7yM {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--neutral-gray-blue-50);
}

.ExamResultInfo_resultValue__2QgW7 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.ExamResultInfo_resultShareTitle__26X-e {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

@media (max-width: 1199px) {
  .ExamResultInfo_resultPageItem__1EfkY {
    max-width: none;
    width: 100%;
  }
}

.Congratulation_resultPageItem__dgOXC {
  max-width: 400px;
  width: 100%;
}

.Congratulation_resultPageItem__dgOXC h1 {
  margin-bottom: 32px;
}

.Congratulation_resultPageItem__dgOXC p {
  margin-top: 24px;
}

@media (max-width: 1199px) {
  .Congratulation_resultPageItem__dgOXC {
    width: 100%;
    max-width: none;
  }
}

.BtnList_btnList__1k6fn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.BtnList_btnList__1k6fn > .btn:first-child {
  margin-right: 20px;
}

@media (max-width: 1199px) {
  .promoItem .btnList {
    justify-content: center;
  }

  .BtnList_btnList__1k6fn {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .BtnList_btnList__1k6fn {
    flex-wrap: wrap;
  }

  .BtnList_btnList__1k6fn > button,
  .BtnList_btnList__1k6fn > .btn {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .BtnList_btnList__1k6fn > .btn:first-child {
    margin-right: 0;
  }
}

.ExamResultContainer_resultPage__2ebDJ {
  padding: 88px 0 140px;
}

.ExamResultContainer_resultPageContent__3J8ym {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-right: 64px;
}

@media (max-width: 1199px) {
  .ExamResultContainer_resultPage__2ebDJ {
    padding: 24px 0 0;
  }

  .ExamResultContainer_resultPageContent__3J8ym {
    flex-flow: row wrap;
    padding-right: 0;
  }

  .ExamResultContainer_resultPageContentItem__Ql_SI {
    width: 100%;
  }
}

.Avatar_avatar__2zivk {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 112px;
  width: 112px;
  margin-right: 20px;
  background-color: var(--theme-primary-80);
  border-radius: 4px;
}

.Avatar_avatarImg__9ZerX {
  max-width: 100%;
  height: auto;
}

.Avatar_avatarText__2yH-x {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 60px;
  color: var(--additional-light-blue);
}

@media (max-width: 991px) {
  .Avatar_avatar__2zivk {
    width: 80px;
    height: 80px;
  }

  .Avatar_avatarText__2yH-x {
    font-size: 40px;
  }
}

.InstructorCard_instructorInfo__3QdN1 {
  position: relative;
  background-color: var(--lavender-grey);
  border-radius: 10px;
  margin: 10% 0 10% auto;
  width: 100%;
  max-width: 500px;
  z-index: 5;
}

.InstructorCard_instructorContent__33M2v {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 40px 40px 20px;
}

.InstructorCard_instructorSchoolInfo__5n97- {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.InstructorCard_instructorContact__x1R5j {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 40px;
  border-top: 1px solid var(--additional-gray-blue);
}

.InstructorCard_instructorContact__x1R5j:last-child,
.InstructorCard_instructorContact__x1R5j:nth-child(3) {
  display: none;
}

.InstructorCard_contactItem__5sZWB {
  flex-grow: 1;
  width: 50%;
}

.InstructorCard_userName__TV-Gh {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: var(--neutral-dark-blue);
}

.InstructorCard_userInfo__gAtkJ {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.InstructorCard_instructorLabel__229fP {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 42px;
  color: var(--neutral-gray-blue-50);
}

.InstructorCard_instructorValue__7ZbEJ {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.InstructorCard_instructorText__30BVt {
  margin-top: 65px;
}

@media (max-width: 991px) {
  .InstructorCard_instructorInfo__3QdN1 {
    margin-bottom: 0;
  }

  .InstructorCard_instructorContent__33M2v,
  .InstructorCard_instructorContact__x1R5j {
    padding: 20px;
  }

  .InstructorCard_userName__TV-Gh {
    font-size: 18px;
    line-height: 24px;
  }

  .InstructorCard_userInfo__gAtkJ {
    font-size: 14px;
    line-height: 22px;
  }

  .InstructorCard_instructorValue__7ZbEJ {
    font-size: 18px;
    line-height: 22px;
  }

  .InstructorCard_instructorContact__x1R5j:last-child,
  .InstructorCard_instructorContact__x1R5j:nth-child(3) {
    display: flex;
  }

  .InstructorCard_instructorContact__x1R5j:nth-child(2) {
    display: none;
  }

  .InstructorCard_instructorInfo__3QdN1::after {
    display: none;
  }
}

.InstructorContainer_instructorInfo__214xP {
  background-color: var(--lavender-grey);
  border-radius: 10px;
  margin: 10% 0;
  width: 100%;
}

.InstructorContainer_instructorInfo__214xP:after {
  position: absolute;
  content: '';
  top: 0;
  left: 25%;
  width: 100%;
  height: 100%;
  max-width: 650px;
  background: url(/static/media/il-2.7e8393f5.svg) no-repeat 50% 50%;
  background-size: contain;
}

.InstructorContainer_instructorContent__3iWI5 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 40px 40px 20px;
}

.InstructorContainer_instructorSchoolInfo__2_r01 {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.InstructorContainer_instructorContact__1yHhy {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 40px;
  border-top: 1px solid var(--additional-gray-blue);
}

.InstructorContainer_instructorContact__1yHhy:last-child,
.InstructorContainer_instructorContact__1yHhy:nth-child(3) {
  display: none;
}

.InstructorContainer_contactItem__hoorR {
  max-width: calc(50% - 10px);
  width: 100%;
}

.InstructorContainer_userName__1EQZh {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
}

.InstructorContainer_userInfo__23P13 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.InstructorContainer_instructorLabel__RgQ3I {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.InstructorContainer_instructorValue__ZRBJD {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.InstructorContainer_instructorText__2-T-Y {
  margin-top: 65px;
}

.InstructorContainer_imgContainer__1UKRC {
  width: 100%;
  max-width: calc(50% - 10px);
}

.InstructorContainer_imgContainer__1UKRC img {
  height: 100%;
  width: auto;
}

@media (max-width: 1450px) {
  .InstructorContainer_imgContainer__1UKRC {
    width: 100%;
    max-width: unset;
  }

  .InstructorContainer_imgContainer__1UKRC img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .InstructorContainer_imgContainer__1UKRC {
    display: none;
  }

  .InstructorContainer_instructorInfo__214xP {
    margin-bottom: 0;
  }

  .InstructorContainer_instructorInfo__214xP:after {
    content: none;
  }

  .InstructorContainer_instructorContent__3iWI5,
  .InstructorContainer_instructorContact__1yHhy {
    padding: 20px;
  }

  .InstructorContainer_userName__1EQZh {
    font-size: 18px;
    line-height: 24px;
  }

  .InstructorContainer_userInfo__23P13 {
    font-size: 14px;
    line-height: 22px;
  }

  .InstructorContainer_instructorValue__ZRBJD {
    font-size: 18px;
    line-height: 22px;
  }

  .InstructorContainer_instructorContact__1yHhy:last-child,
  .InstructorContainer_instructorContact__1yHhy:nth-child(3) {
    display: flex;
  }

  .InstructorContainer_instructorContact__1yHhy:nth-child(2) {
    display: none;
  }
}

.BottomDetails_bottomDescription__3q3vR {
  column-count: 3;
  column-width: 390px;
  grid-column-gap: 20px;
  column-gap: 20px;
}

@media (max-width: 991px) {
  .BottomDetails_bottomDescription__3q3vR {
    column-width: 320px;
  }
}

.BottomDetailsColumn_bottomDetailsColumn__dVDA5 {
  color: var(--neutral-gray-blue);
}

.ExamPageContainer_examPage__oKgmU {
  /*background: url('../../img/il-2.svg') no-repeat center center,*/
  /*  linear-gradient(359.1deg, var(--whiteColor) -14.55%, var(--whiteColor) 18.5%, rgba(255, 255, 255, 0) 56.75%);*/
  /*background-size: 40% auto;*/
}

.ExamPageContainer_pcInstructor__2k-GB,
.ExamPageContainer_mobileInstructor__2Q4on {
  max-width: calc(50% - 10px);
  width: 100%;
  margin: 0;
}

.ExamPageContainer_examPage__oKgmU .pageTitle {
  margin-bottom: 12px;
}

.ExamPageContainer_examDetails__36W3Y {
  background-color: var(--additional-gray-blue-20);
  padding: 54px 0 80px;
}

.ExamPageContainer_mobileInstructor__2Q4on {
  display: none;
}

.ExamPageContainer_pcInstructor__2k-GB {
  display: block;
}

@media (max-width: 991px) {
  .ExamPageContainer_examPage__oKgmU {
    padding-top: 24px;
    background: none;
  }

  .ExamPageContainer_pcInstructor__2k-GB {
    display: none;
  }

  .ExamPageContainer_mobileInstructor__2Q4on {
    display: block;
    margin-bottom: 40px;
    max-width: none;
  }

  .ExamPageContainer_examDetails__36W3Y {
    background-color: var(--whiteColor);
    padding: 40px 0 0;
  }
}

.ExamBgContainer_examBgContainer__3jEjJ {
  background: url(/static/media/examBg.6dee1690.svg) no-repeat 50% 50%;
  background-size: cover;
}

.ExamBgContainer_pcInstructor__1dLnK {
  max-width: calc(50% - 10px);
  width: 100%;
}

.ExamBgContainer_pcInstructor__1dLnK .ExamBgContainer_instructorInfo__zwiuV {
  margin-right: 0;
}

@media (max-width: 991px) {
  .ExamBgContainer_pcInstructor__1dLnK {
    display: none;
  }

  .ExamBgContainer_examBgContainer__3jEjJ {
    background: none;
    margin-top: 0;
  }
}

.RulesGroupContent_rulesGroupContent__Ic4X6 {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .RulesGroupContent_rulesGroupContent__Ic4X6 {
    flex-wrap: wrap;
  }
}

.RulesGroupItem_rulesGroupItem__34fE_ {
  max-width: calc(50% - 10px);
  width: 100%;
  z-index: 5;
  /*margin-bottom: 100px;*/
}

.rulesGroupContent h1 {
  margin-top: 92px;
}

@media (max-width: 1199px) {
  .RulesGroupItem_rulesGroupItem__34fE_ {
    max-width: 720px;
    width: auto;
    margin: 0 auto;
    text-align: center;
  }

  .RulesGroupItem_rulesGroupItem__34fE_ > .RulesGroupItem_imgContainer__2AyY4,
  .RulesGroupItem_rulesGroupItem__34fE_:first-child {
    display: none;
  }
}

@media (max-width: 991px) {
  .RulesGroupItem_rulesGroupItem__34fE_ {
    max-width: none;
    width: auto;
    margin: 0 auto;
  }

  .RulesGroupItem_rulesGroupItem__34fE_ h1 {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .RulesGroupItem_rulesGroupItem__34fE_ h1 {
    text-align: left;
  }
}

.ExamInfo_examInfoCard__XjfTt {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background-color: var(--lavender-grey);
  border-radius: 6px;
  padding: 24px 24px 8px;
  max-width: 346px;
  width: 100%;
  margin-bottom: 15px;
}

.ExamInfo_examCardItem__3C2Oe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(50% - 10px);
  width: 100%;
  margin-bottom: 12px;
}

.ExamInfo_cardLabel__unsUk {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.ExamInfo_cardValue__35pRX {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.ExamInfo_examPage__15BN3 .ExamInfo_rulesList__oy9Pn {
  margin: 20px 0 44px;
}

.PageText_pageText__1yEAv {
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .PageText_pageText__1yEAv {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .PageText_pageText__1yEAv {
    text-align: left;
  }
}

.PageText_p3__36Hl- {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.PageDescription_pageDescription__3aTVm {
  max-width: 310px;
  width: 100%;
}

.jumbotron {
  padding: 24px 20px 18px;
  background-color: var(--lavender-grey);
  margin-right: 0;
}

.TheoryCard_theoryCard__3KzCG {
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.topicListLogged .jumbotron {
  max-width: 350px;
  background-color: var(--whiteColor);
}

.topicListNotLogged .jumbotron {
  max-width: 350px;
  background-color: var(--lavender-grey);
}

.TheoryCard_theoryCardTitle__24Vu7 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.TheoryCard_theoryCardText__3T8bs {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  margin-bottom: 28px;
}

.TheoryCard_linkBtn__1AVOY {
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  padding: 0;
  border: none;
  transition: all ease 0.3s;
}

.TheoryCard_linkBtn__1AVOY i {
  font-size: 14px;
  color: currentColor;
}

.TheoryCard_linkBtn__1AVOY:hover,
.TheoryCard_linkBtn__1AVOY:focus {
  text-decoration: none;
}

@media (max-width: 991px) {
  .topicListLogged .jumbotron {
    background-color: var(--neutral-light-gray);
    margin-bottom: 20px;
  }
}

.TheoryTopic_topicListItem__2GDVD {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.TheoryTopic_topicListItem__2GDVD i {
  display: none;
}

.TheoryTopic_topicListItem__2GDVD .TheoryTopic_topicsListItemBtn__2cuUV {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
  padding: 16px 30px 16px 16px;
  border: none;
  text-align: left;
  white-space: nowrap;
}

.TheoryTopic_topicListItem__2GDVD .TheoryTopic_topicsListItemBtn__2cuUV:hover,
.TheoryTopic_topicListItem__2GDVD .TheoryTopic_topicsListItemBtn__2cuUV:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

.TheoryModalNotLogged_modalLg__1xoYh {
  position: relative;
  max-width: 720px;
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-content {
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-body,
.TheoryModalNotLogged_modalLg__1xoYh .modal-header {
  padding: 0;
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-header button.close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 200;
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-header button.close:before {
  color: var(--whiteColor);
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-header button.close > span {
  display: none;
}

.TheoryModalNotLogged_modalContent__NhSMr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.TheoryModalNotLogged_modalImg__2L9AY {
  max-width: 100%;
  height: auto;
  border-radius: 0 6px 6px 0;
}

.TheoryModalNotLogged_modalContentText__3uplY {
  padding: 0 22px 0 36px;
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-title {
  margin-bottom: 10px;
}

.TheoryModalNotLogged_modalText__3_HYP {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  margin-bottom: 26px;
}

@media (max-width: 991px) {
  .TheoryModalNotLogged_modalLg__1xoYh {
    max-width: 350px;
  }

  .TheoryModalNotLogged_modalContent__NhSMr {
    flex-flow: row wrap-reverse;
  }

  .TheoryModalNotLogged_modalContentText__3uplY {
    padding: 26px 0 0;
  }

  .TheoryModalNotLogged_modalLg__1xoYh .modal-title {
    margin: 0 20px 16px;
  }

  .TheoryModalNotLogged_modalText__3_HYP {
    margin: 0 20px 26px;
  }

  .TheoryModalNotLogged_modalImg__2L9AY {
    border-radius: 6px 6px 0 0;
  }

  .TheoryModalNotLogged_modalBtn__1Vvab {
    max-width: none;
    width: 100%;
  }
}

.topicListLogged .TheoryByTopicContainer_topicList__1XMC2 {
  background-color: var(--neutral-light-gray);
}

.topicListNotLogged .TheoryByTopicContainer_topicList__1XMC2 {
  background-color: var(--whiteColor);
}

.TheoryByTopicContainer_topicList__1XMC2 {
  padding: 80px 0 20px;
}

.jumbotron {
  max-width: 350px;
  width: 100%;
  margin-right: 177px;
  border-radius: 10px;
}

.TheoryByTopicContainer_topicListContent__2JUn9 {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.TheoryByTopicContainer_theoryTopicList__1OeVO {
  column-count: 2;
  column-width: 250px;
  grid-column-gap: 10px;
  column-gap: 10px;
}

@media (max-width: 991px) {
  .TheoryByTopicContainer_topicListContent__2JUn9 {
    flex-wrap: wrap;
  }

  .topicListLogged .TheoryByTopicContainer_topicList__1XMC2 {
    background-color: var(--whiteColor);
  }

  .TheoryByTopicContainer_theoryTopicList__1OeVO {
    column-count: 1;
    column-width: auto;
    grid-column-gap: inherit;
    column-gap: inherit;
    order: 1;
    padding-bottom: 20px;
  }

  .jumbotron {
    order: 2;
    margin-bottom: 0;
  }

  .TheoryByTopicContainer_topicList__1XMC2 {
    padding: 40px 0 20px;
  }
}

@media (max-width: 767px) {
  .jumbotron {
    margin-right: 0;
  }
}

.notLoggedPageText .TheoryContainer_theoryPageText__2GbY_,
.loggedPageText .TheoryContainer_theoryPageText__2GbY_ {
  margin-bottom: 112px;
  max-width: 310px;
  width: 100%;
}

.TheoryContainer_theoryPageText__2GbY_ h1 {
  margin-bottom: 17px;
}

.TheoryContainer_rulesList__31L6W {
  display: flex;
  margin: 20px 0 40px;
}

.TheoryContainer_rulesListItem__NX40f {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.TheoryContainer_rulesListItem__NX40f:not(:last-child) {
  margin-right: 26px;
}

.rulesListItemBg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: var(--theme-primary-80);
  transition: all ease-in 0.3s;
}

.TheoryContainer_rulesListItemBg__3ptmX > i {
  font-size: 48px;
}

.TheoryContainer_rulesListItemText__1Oqjj {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.TheoryContainer_rulesListItemBadge__3SgLN {
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  text-transform: uppercase;
  color: var(--whiteColor);
  background-color: var(--badge-color);
  text-align: center;
  padding: 8px;
  border-radius: 21px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.04px;
  width: 55px;
}

.TheoryContainer_linkBtn__17cKp {
  display: flex;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: var(--theme-primary);
}

.TheoryContainer_linkBtn__17cKp > .TheoryContainer_icon-arrow__eztGK {
  color: currentColor;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 2px;
}

@media (max-width: 1199px) {
  .TheoryContainer_rulesList__31L6W {
    justify-content: center;
  }

  .TheoryContainer_rulesListItemBg__3ptmX {
    background-color: var(--additional-light-blue);
  }

  .TheoryContainer_rulesListItemBadge__3SgLN {
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .TheoryContainer_rulesListItemBg__3ptmX {
    width: 60px;
    height: 60px;
  }

  .TheoryContainer_rulesListItemBg__3ptmX > i {
    font-size: 36px;
  }

  .theoryBtn {
    max-width: 350px;
    width: 100%;
  }

  .notLoggedPageText .TheoryContainer_theoryPageText__2GbY_ {
    margin-bottom: 302px;
  }

  .loggedPageText.TheoryContainer_theoryPageText__2GbY_ {
    margin-bottom: 52px;
  }
}

.TheoryPage_mobileView__3rhbC {
  display: none;
}

.TheoryPage_imageBox__37u_T {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 991px) {
  .TheoryPage_theoryPageTopContent__3muLj {
    padding: 24px 0 40px;
  }

  .TheoryPage_mobileView__3rhbC {
    display: block;
  }

  .TheoryPage_imageBox__37u_T {
    max-width: unset;
  }
}

.ContentTopic_contentListItem__2qnA- {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.ContentTopic_contentListItem__2qnA- i {
  display: none;
}

.ContentTopic_contentListItem__2qnA- .ContentTopic_topicsListItemBtn__24dnn {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
  padding: 16px 30px 16px 16px;
  border: none;
  text-align: left;
}

.ContentTopic_contentListItem__2qnA-.ContentTopic_highlighted__kcENU {
  border: 2px solid var(--theme-primary);
}

.ContentTopic_contentListItem__2qnA-.ContentTopic_active__1Htu0 .ContentTopic_topicsListItemBtn__24dnn,
.ContentTopic_contentListItem__2qnA- .ContentTopic_topicsListItemBtn__24dnn:hover,
.ContentTopic_contentListItem__2qnA- .ContentTopic_topicsListItemBtn__24dnn:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

.ContentTopic_contentListItem__2qnA- .ContentTopic_topicsListSubItemBtn__1smpq {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-dark-blue);
  margin-left: 15px;
  border: none;
  text-align: left;
}

.ContentTopic_contentListItem__2qnA-.ContentTopic_active__1Htu0 .ContentTopic_topicsListSubItemBtn__1smpq,
.ContentTopic_contentListItem__2qnA- .ContentTopic_topicsListSubItemBtn__1smpq:hover,
.ContentTopic_contentListItem__2qnA- .ContentTopic_topicsListSubItemBtn__1smpq:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

.topicListLogged .ContentByTopicContainer_topicList__3uWiL {
  background-color: var(--neutral-light-gray);
}

.topicListNotLogged .ContentByTopicContainer_topicList__3uWiL {
  background-color: var(--whiteColor);
}

.ContentByTopicContainer_topicList__3uWiL {
  padding: 20px 0 20px;
}

.jumbotron {
  max-width: 350px;
  width: 100%;
  margin-right: 177px;
  border-radius: 10px;
}

.ContentByTopicContainer_topicListContent__1GdU9 {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.ContentByTopicContainer_theoryTopicList__2lb8P {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 991px) {
  .ContentByTopicContainer_topicListContent__1GdU9 {
    flex-wrap: wrap;
  }

  .topicListLogged .ContentByTopicContainer_topicList__3uWiL {
    background-color: var(--whiteColor);
  }

  .ContentByTopicContainer_theoryTopicList__2lb8P {
    column-count: 1;
    column-width: auto;
    grid-column-gap: inherit;
    column-gap: inherit;
    order: 1;
    padding-bottom: 20px;
    width: 100%;
  }

  .jumbotron {
    order: 2;
    margin-bottom: 0;
  }

  .ContentByTopicContainer_topicList__3uWiL {
    padding: 40px 0 20px;
  }
}

@media (max-width: 767px) {
  .jumbotron {
    margin-right: 0;
  }
}

.DrivingskillsPage_mobileView__19FI6 {
  display: none;
}

.DrivingskillsPage_imageBox__2UThK {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 991px) {
  .DrivingskillsPage_theoryPageTopContent__2tYPc {
    padding: 24px 0 40px;
  }

  .DrivingskillsPage_mobileView__19FI6 {
    display: block;
  }
}

.EcodrivePage_mobileView__2YLtD {
  display: none;
}

.EcodrivePage_imageBox__3RS0q {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 991px) {
  .EcodrivePage_theoryPageTopContent__2Fqyj {
    padding: 24px 0 40px;
  }

  .EcodrivePage_mobileView__2YLtD {
    display: block;
  }

  /*.imageBox {*/
  /*  max-width: unset;*/
  /*}*/
}

.ContentPage_mobileView__GJNj6 {
  display: none;
}

.ContentPage_imageBox__aBRMg {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 991px) {
  .ContentPage_theoryPageTopContent__RU6GL {
    padding: 24px 0 40px;
  }

  .ContentPage_mobileView__GJNj6 {
    display: block;
  }
}

.CategoryIcon_rulesListItem__3Dk0e {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.CategoryIcon_rulesListItem__3Dk0e:not(:last-child) {
  margin-right: 26px;
}

.CategoryIcon_rulesListItemBg__1wVri {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--additional-light-blue);
  color: var(--theme-primary-80);
  transition: all ease-in 0.3s;
}

.categoryIconBarHome .CategoryIcon_rulesListItemBg__1wVri {
  background-color: var(--whiteColor);
}

.CategoryIcon_rulesListItemBg__1wVri > i {
  font-size: 48px;
}

.CategoryIcon_rulesListItemText__3b1Lj {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.categoryIconBarHome .CategoryIcon_rulesListItemText__3b1Lj {
  color: var(--whiteColor);
}

.CategoryIcon_rulesListItemBadge__1f_UN {
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  text-transform: uppercase;
  color: var(--whiteColor);
  background-color: var(--badge-color);
  text-align: center;
  padding: 8px;
  border-radius: 21px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.04px;
  width: 55px;
}

@media (max-width: 1199px) {
  .CategoryIcon_rulesListItemBg__1wVri,
  .categoryIconBarHome .CategoryIcon_rulesListItemBg__1wVri {
    background-color: var(--additional-light-blue);
  }

  .categoryIconBarHome .CategoryIcon_rulesListItemText__3b1Lj {
    color: var(--text-color);
  }

  .CategoryIcon_rulesListItemBadge__1f_UN {
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .CategoryIcon_rulesListItemBg__1wVri {
    width: 60px;
    height: 60px;
  }

  .CategoryIcon_rulesListItemBg__1wVri > i {
    font-size: 36px;
  }
}

.ImprintPage_imprintPage__3Nzke {
  padding: 60px 0 120px;
}

.ImprintPage_imprintList__3ydvh {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 28px;
}

.ImprintPage_imprintPage__3Nzke p {
  margin-bottom: 12px;
  max-width: 400px;
}

@media (max-width: 991px) {
  .ImprintPage_imprintPage__3Nzke {
    padding: 24px 0 0;
  }

  .ImprintPage_imprintPage__3Nzke h1 {
    text-align: left;
  }

  .ImprintPage_imprintList__3ydvh {
    margin-bottom: 28px;
  }
}

.CollapseItem_root__3jezy {
  position: relative;
  margin-right: 17px;
  color: var(--theme-primary);
  padding-left: 30px;
  margin-bottom: 16px;
  border: none;
  background: var(--whiteColor);
  text-align: inherit;
}

.CollapseItem_root__3jezy:before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
  width: 14px;
  height: 14px;
  font-family: 'icomoon', 'serif';
  font-size: 12px;
  transition: all ease 0.3s;
  line-height: 1;
}

.CollapseItem_open__3pC_8.CollapseItem_root__3jezy:before {
  transform: rotate(90deg);
}

.CollapseItem_root__3jezy:hover {
  cursor: pointer;
}

.CollapseItem_faqItem__2jTsj {
  margin-bottom: 21px;
  width: 100%;
  overflow: hidden;
}

.CollapseItem_faqHeader__2ZkwP {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-bottom: 7px;
}

.CollapseItem_faqText__2mxLe {
  display: block;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  padding-left: 30px;
  text-transform: none;
}

.CollapseItem_faqItem__2jTsj .collapse:not(.show) {
  display: inherit;
  height: 0;
  transition: all ease 0.4s;
}

.CollapseItem_faqItem__2jTsj .collapse.show {
  height: auto;
}

@media (max-width: 991px) {
  .CollapseItem_questionsByTopicList__1cCug {
    column-width: 320px;
  }
}

.TermPage_termPage__39Gc4 {
  padding: 60px 0;
}

.TermPage_termPageContent__3msWJ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.TermPage_termPageContentMobile__1ddGh {
  display: none;
}

.TermPage_termPageContentMobile__1ddGh .faqHeader {
  text-transform: lowercase;
}

.TermPage_termPageContentMobile__1ddGh .collapsibleItem:first-letter {
  text-transform: uppercase;
}

.TermPage_termPage__39Gc4 .list-group {
  min-width: 280px;
  margin-right: 33px;
  flex-grow: 1;
}

.TermPage_termPage__39Gc4 .list-group-item {
  border: none;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  padding: 5px 0;
  margin-bottom: 18px;
}

.TermPage_termPage__39Gc4 .list-group-item:hover {
  color: var(--theme-primary);
  cursor: pointer;
}

.TermPage_termPageText__1JBgt {
  column-count: 2;
  column-width: 350px;
  grid-column-gap: 20px;
  column-gap: 20px;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.TermPage_mobileText__2VYPH {
  display: none;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
}

.TermPage_glossaryScrollBox__1TUAP {
  max-height: 700px;
  height: 100%;
  overflow-y: auto;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

.TermPage_glossaryScrollBox__1TUAP::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TermPage_glossaryScrollBox__1TUAP::-webkit-scrollbar {
  width: 12px;
}

.TermPage_glossaryScrollBox__1TUAP::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

@media (max-width: 991px) {
  .TermPage_mobileText__2VYPH {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .TermPage_termPage__39Gc4 {
    padding: 0;
  }

  .TermPage_termPageContent__3msWJ {
    display: none;
  }

  .TermPage_termPageContentMobile__1ddGh {
    display: block;
  }
}

.AsaPage_asaPage__ArP-s {
  padding: 40px 0 150px;
}

.AsaPage_asaPageTitle__UhNk- {
  margin-bottom: 32px;
}

.AsaPage_asaPageContent__DOqLc {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.AsaPage_pageText__A7wVd {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.AsaPage_asaPageItem__3tk3w {
  max-width: 33%;
  width: 100%;
}

.AsaPage_asaPageItem__3tk3w:not(last-child) {
  margin-right: 18px;
}

@media (max-width: 991px) {
  .AsaPage_asaPage__ArP-s {
    padding: 0;
  }

  .AsaPage_asaPageContent__DOqLc {
    flex-flow: row wrap;
  }

  .AsaPage_asaPageItem__3tk3w {
    max-width: none;
    width: 100%;
  }
}

.ModalByTopic_modalTopic__1HbfW .modal-body {
  padding: 0 44px 28px;
}

.ModalByTopic_modalTopic__1HbfW .modal-header {
  padding: 40px 44px 70px;
}

.ModalByTopic_modalTopic__1HbfW .modal-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.ModalByTopic_topicListLink__3TzcG {
  display: block;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 32px;
}

.ModalByTopic_topicList__kVEPB {
  display: grid;
  grid-template-columns: repeat(2, 200px);
  grid-auto-flow: row;
}

@media (max-width: 991px) {
  .ModalByTopic_modalTopic__1HbfW {
    max-width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    border-radius: 6px;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-body {
    padding: 0 20px;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-dialog {
    height: 100%;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-header {
    padding: 20px;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-body {
    padding: 0 20px;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-title {
    font-family: 'Ubuntu', sans-serif;
    font-size: 26px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: var(--neutral-dark-blue);
    margin-top: 0;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .ModalByTopic_topicList__kVEPB {
    grid-template-columns: repeat(1, 200px);
  }
}

.QuestionCardContainer_questionPage__1sn0Z {
  background-color: var(--lavender-grey);
}

.QuestionCardContainer_questionLinkBottom__2KJR9 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 88px;
  padding-bottom: 30px;
}

.QuestionCardContainer_loaderContainer__2Zvg- {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

.QuestionCardContainer_questionBar__3Cbly {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.QuestionCardContainer_doubleCounter__1ZQbe {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 6px;
  margin: 10px 32px 10px 0;
  border: 1px solid var(--additional-gray-blue);
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--neutral-gray-blue);
}

.QuestionCardContainer_headerQuestionBox__1S5uo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.QuestionCardContainer_questionCustomBtn__1Hu1p {
  max-width: 350px;
  width: 100%;
  border: 1px solid var(--additional-gray-blue);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: var(--whiteColor);
}

.QuestionCardContainer_questionCustomBtn__1Hu1p button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  padding: 8px 16px;
  border: none;
}

.QuestionCardContainer_questionCustomBtn__1Hu1p button:hover,
.QuestionCardContainer_questionCustomBtn__1Hu1p button:focus {
  text-decoration: none;
}

.QuestionCardContainer_questionCustomBtn__1Hu1p i {
  font-size: 16px;
  color: var(--theme-primary);
}

.QuestionCardContainer_mobileTopic__3Cqum {
  display: none;
}

.QuestionCardContainer_theoryMobile__2CCV1 {
  display: none;
  margin-bottom: 40px;
  margin-top: -48px;
}

.QuestionCardContainer_theoryMobileFooter__32HsD {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .QuestionCardContainer_questionPage__1sn0Z {
    background-color: var(--whiteColor);
  }

  .QuestionCardContainer_questionLinkBottom__2KJR9 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .QuestionCardContainer_headerQuestionBox__1S5uo .QuestionCardContainer_questionCustomBtn__1Hu1p {
    display: none;
  }

  .QuestionCardContainer_mobileTopic__3Cqum {
    display: block;
  }

  .QuestionCardContainer_theoryMobile__2CCV1 {
    display: block;
    max-width: none;
    margin-top: 30px;
  }
}

@media (max-width: 430px) {
  .QuestionCardContainer_questionLinkBottom__2KJR9 button,
  .QuestionCardContainer_questionLinkBottom__2KJR9 a {
    padding: 5px 10px;
    font-size: 15px;
  }
}

.TopicProgress_progressContent__1XTaE {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.TopicProgress_progressContent__1XTaE:hover {
  cursor: pointer;
}

.TopicProgress_status__3u3jN {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
}

.TopicProgress_bad__8prnQ {
  background-color: var(--functional-warning);
}

.TopicProgress_good__3-04Y {
  background-color: var(--functional-success);
}

.TopicProgress_middle__2jP1C {
  background-color: var(--button-primary-pressed);
}

.TopicProgress_empty__1xHth {
  background-color: var(--additional-gray-blue);
}

.progress {
  max-width: 80px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--additional-gray-blue);
}

.QuestionsByTopicContainer_rulesGroupItem__30das {
  max-width: 50%;
  width: 100%;
}

.QuestionsByTopicContainer_questionsByTopic__2hQWx {
  padding-top: 80px;
  padding-bottom: 80px;
}

.QuestionsByTopicContainer_questionsByTopic__2hQWx .QuestionsByTopicContainer_pageTitle__Xfrz1 {
  padding-bottom: 70px;
}

.QuestionsByTopicContainer_questionsByTopicTitle__2dPqm {
  color: var(--neutral-dark-blue);
}

.QuestionsByTopicContainer_questionsByTopicList__2gnge {
  position: relative;
  columns: 3 auto;
  padding: 0 0 80px;
  transition: all ease 0.7s;
}

.QuestionsByTopicContainer_questionsTopicText__33O10 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.QuestionsByTopicContainer_questionsTopicText__33O10 p {
  max-width: calc(100% / 3);
  width: 100%;
}

.QuestionsByTopicContainer_questionsTopicText__33O10 p:not(:last-child) {
  margin-right: 20px;
}

.QuestionsByTopicContainer_questionsByTopicList__2gnge.QuestionsByTopicContainer_mobileMode__2pLak::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 180px;
  background: linear-gradient(180deg, var(--whiteColor) 21.87%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(-180deg);
  transition: all ease 1s;
}

.QuestionsByTopicContainer_questionsByTopicList__2gnge.QuestionsByTopicContainer_mobileMode__2pLak.QuestionsByTopicContainer_expanded__ucwGG::after {
  content: none;
  height: 0;
}

.QuestionsByTopicContainer_extendControlContainer__IyGga {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.QuestionsByTopicContainer_expandBtn__22a4p {
  display: inline-flex;
  align-items: center;
  color: var(--neutral-dark-blue);
  text-decoration: none !important;
}

.QuestionsByTopicContainer_expandStatusIcon__3mRH2::after {
  display: block;
  margin-left: 13px;
  content: '\e918';
  font-family: 'icomoon';
  font-size: 18px;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
  transition: all ease 0.3s;
  color: var(--button-default-enabled);
}

.QuestionsByTopicContainer_expandStatusIcon__3mRH2.QuestionsByTopicContainer_expanded__ucwGG::after {
  transform: rotate(-90deg);
}

.QuestionsByTopicContainer_questionsByTopicListItem__Vwk5d > div {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  max-width: 315px;
  line-height: 56px;
  width: 100%;
}

.QuestionsByTopicContainer_questionsLink__223Ux {
  display: inline-block;
  color: var(--neutral-dark-blue);
}

.QuestionsByTopicContainer_questionsLink__223Ux:first-letter {
  text-transform: uppercase;
}

.QuestionsByTopicContainer_questionsByTopicList__2gnge > p {
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .QuestionsByTopicContainer_questionsByTopicList__2gnge.QuestionsByTopicContainer_mobileMode__2pLak {
    max-height: 330px;
    height: 100%;
    overflow: hidden;
  }

  .QuestionsByTopicContainer_questionsByTopicList__2gnge.QuestionsByTopicContainer_mobileMode__2pLak.QuestionsByTopicContainer_expanded__ucwGG {
    max-height: none;
    overflow: visible;
    padding-bottom: 0;
    height: 100%;
    min-height: 100%;
  }

  .QuestionsByTopicContainer_questionsByTopicList__2gnge {
    column-width: 320px;
    margin: 32px 0 0;
  }

  .QuestionsByTopicContainer_questionsByTopic__2hQWx {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .QuestionsByTopicContainer_questionsByTopic__2hQWx .QuestionsByTopicContainer_pageTitle__Xfrz1 {
    padding-bottom: 0;
  }

  .QuestionsByTopicContainer_questionsByTopicList__2gnge {
    padding: 0;
  }

  .QuestionsByTopicContainer_questionSection__yb51z {
    background-color: var(--whiteColor);
  }

  .QuestionsByTopicContainer_extendControlContainer__IyGga {
    margin-bottom: 40px;
  }

  .QuestionsByTopicContainer_questionsTopicText__33O10 {
    flex-flow: row wrap;
  }

  .QuestionsByTopicContainer_questionsTopicText__33O10 p {
    max-width: none;
    margin-bottom: 20px;
  }

  .QuestionsByTopicContainer_questionsTopicText__33O10 p:not(:last-child) {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .QuestionsByTopicContainer_questionsByTopicList__2gnge {
    columns: auto;
    column-width: auto;
  }
}

.QuestionPage_questionPage__1blr7 .rulesGroupItem h1 {
  margin-bottom: 20px;
}

.QuestionPage_questionTopicBg__2vSE9 {
  background-color: var(--additional-gray-blue-20);
}

.QuestionPage_mobileView__2H-8y {
  display: none;
}

.QuestionPage_imageBox__4l35e {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 991px) {
  .QuestionPage_mobileView__2H-8y {
    display: block;
  }
}

.QuestionsBgContainer_questionsBgContainer__Hd0ux {
  background: url(/static/media/questionBg.e9f209ff.svg) no-repeat 50% 50%;
  background-size: cover;
}

.QuestionsBgContainer_imageBox__1cB7f {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 991px) {
  .QuestionsBgContainer_imageBox__1cB7f {
    max-width: unset;
  }
}

.ContactCard_contactItem__3XvFl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--neutral-light-gray);
  border-radius: 6px;
  padding: 24px 26px 20px 24px;
  max-width: calc((100% / 3) - 10px);
  width: 100%;
  margin-bottom: 20px;
  margin-right: 0;
}

.ContactCard_contactItem__3XvFl:not(:last-child) {
  margin-right: 20px;
}

.ContactCard_contactTitle__2jnc1 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.ContactCard_contactText__3BjJk {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}

.ContactCard_contactFooter__1vaGk {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .ContactCard_contactItem__3XvFl {
    max-width: none;
    width: 100%;
  }
}

.Map_loadingElement__2j8q7 {
    height: 100%;
}

.Map_container__3xh_q {
    height: 400px;
}

.Map_map__2bGnQ {
    height: 100%;
}

.ContactPage_jumbotron__356z4 {
  margin-bottom: 20px;
}

.ContactPage_contactPage__3X5KQ {
  padding-top: 60px;
}

.ContactPage_contactPageTitle__V9YKu {
  margin-bottom: 32px;
}

.ContactPage_contactList__3Z-Oi {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ContactPage_contactItemFly__1L8GB {
  position: absolute;
  left: 100px;
  top: 50px;
  padding: 20px 24px;
  background-color: var(--neutral-light-gray);
  border-radius: 6px;
  max-width: 350px;
  width: 100%;
  z-index: 999;
  margin-bottom: 20px;
}

.ContactPage_contactTitle__2kS6i {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.ContactPage_contactSubtitle__27XJr {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.ContactPage_contactText__2A7Hu {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}
.ContactPage_contactItemContent__2Wixu:not(:last-child) {
  margin-bottom: 10px;
}

.ContactPage_mapContainer__U62YL {
  position: relative;
  margin-bottom: 60px;
}

.ContactPage_mapElementContainer__2tL6N {
  height: 520px;
}

@media (max-width: 991px) {
  .ContactPage_contactPage__3X5KQ {
    padding-top: 24px;
  }

  .ContactPage_contactList__3Z-Oi {
    flex-flow: row wrap;
  }

  .ContactPage_contactItemFly__1L8GB {
    position: static;
    max-width: none;
  }

  .ContactPage_mapContainer__U62YL {
    margin-bottom: 24px;
  }

  .ContactPage_mapElementContainer__2tL6N {
    height: 350px;
  }
}

.Pagination_prevBtn__vAIru,
.Pagination_nextBtn__1wpro {
  position: relative;
}

.Pagination_prevBtn__vAIru {
  margin-right: 30px;
}

.Pagination_nextBtn__1wpro {
  margin-left: 30px;
}

.Pagination_prevBtn__vAIru:before,
.Pagination_nextBtn__1wpro:before {
  position: absolute;
  content: '\e917';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-family: 'icomoon', sans-serif;
  color: var(--neutral-dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: var(--button-secondary-enable);
}

.Pagination_prevBtn__vAIru:before {
  transform: rotate(180deg);
}

.Pagination_prevBtn__vAIru a,
.Pagination_nextBtn__1wpro a {
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.Pagination_prevBtn__vAIru span,
.Pagination_nextBtn__1wpro span {
  display: none;
}

.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item:first-child, .page-item:last-child {
  display: none;
}

.Pagination_paginationItem__PU1Qg a,
.Pagination_paginationItem__PU1Qg span {
  border: none;
  background-color: transparent;
  padding: 0 5px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 48px;
  color: var(--neutral-gray-blue);
  transition: all 0.3s ease;
}

.page-link:hover {
  color: var(--button-default-focused);
  background-color: transparent;
}

.page-item.active .page-link {
  color: var(--button-default-focused);
  border: none;
  background-color: transparent;
}

.page-link:focus {
  box-shadow: none;
}

@media (max-width: 991px) {
  .Pagination_prevBtn__vAIru {
    margin-right: 15px;
  }

  .Pagination_nextBtn__1wpro {
    margin-left: 15px;
  }

  .Pagination_prevBtn__vAIru:before,
  .Pagination_nextBtn__1wpro:before {
    font-size: 16px;
  }

  .Pagination_prevBtn__vAIru a,
  .Pagination_nextBtn__1wpro a {
    width: 32px;
    height: 32px;
  }

  .Pagination_paginationItem__PU1Qg a,
  .Pagination_paginationItem__PU1Qg span {
    font-size: 16px;
    line-height: 32px;
  }
}

.SchoolTableContainer_schoolTable__2oL2J {
  margin-top: 36px;
}

.SchoolTableContainer_tableHeader__PzGVW,
.SchoolTableContainer_tableBodyRow__13Kqe {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
}

.SchoolTableContainer_tableHeaderItem__1RuG1 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-gray-blue);
  width: 100%;
}

.SchoolTableContainer_tableBodyRow__13Kqe {
  background-color: var(--neutral-light-gray);
  border-radius: 6px;
  margin-bottom: 16px;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  text-align: left;
  width: 100%;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:not(:last-child) {
  margin-right: 29px;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:first-child,
.SchoolTableContainer_tableBodyRowItem__1zlF6:last-child,
.SchoolTableContainer_tableHeaderItem__1RuG1:first-child,
.SchoolTableContainer_tableHeaderItem__1RuG1:last-child {
  width: 32px;
  height: 32px;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:first-child,
.SchoolTableContainer_tableHeaderItem__1RuG1:first-child {
  display: none;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(2),
.SchoolTableContainer_tableHeaderItem__1RuG1:nth-child(2) {
  max-width: 25%;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(3),
.SchoolTableContainer_tableHeaderItem__1RuG1:nth-child(3) {
  max-width: 30%;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(4),
.SchoolTableContainer_tableHeaderItem__1RuG1:nth-child(4) {
  max-width: 15%;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(5),
.SchoolTableContainer_tableHeaderItem__1RuG1:nth-child(5) {
  max-width: 10%;
}

.SchoolTableContainer_tableFooter__3M4OB {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 36px;
}

.SchoolTableContainer_linkBtn__3mFJW {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.SchoolTableContainer_linkBtn__3mFJW i {
  font-size: 14px;
}

@media (max-width: 991px) {
  .SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(4),
  .SchoolTableContainer_tableHeader__PzGVW,
  .SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(3) {
    display: none;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(2) {
    order: 1;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:first-child {
    order: 2;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(5) {
    order: 3;
    max-width: 20%;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:last-child {
    order: 4;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6 {
    font-size: 14px;
    line-height: 21px;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:not(:last-child) {
    margin-right: 0;
  }

  .SchoolTableContainer_tableBodyRow__13Kqe {
    padding: 16px;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:first-child,
  .SchoolTableContainer_tableBodyRowItem__1zlF6:last-child {
    width: 24px;
    height: 24px;
  }

  .SchoolTableContainer_tableFooter__3M4OB {
    justify-content: center;
  }
}

.SchoolSearchContainer_searchContainer__1b9O3 {
  width: 100%;
}

.SchoolSearchContainer_searchContainer__1b9O3 button {
  margin-left: 20px;
}

.SchoolSearchContainer_searchContainer__1b9O3 form {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 767px) {
  .SchoolSearchContainer_searchContainer__1b9O3 form {
    flex-flow: row wrap;
  }

  .SchoolSearchContainer_searchContainer__1b9O3 button {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }
}

.SchoolInfoModalContainer_root__PGBJU.modal-sm {
  max-width: 355px;
  background-color: var(--lavender-grey);
  border-radius: 5px;
}

.SchoolInfoModalContainer_modalBody___w2Vs {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
}

.SchoolInfoModalContainer_modalBody___w2Vs p {
  margin: 0;
}

.SchoolInfoModalContainer_modalHeader__Fm96I {
  padding: 0;
}

.SchoolInfoModalContainer_modalHeader__Fm96I.modal-header button.close:before {
  color: var(--theme-primary);
}

.SchoolInfoModalContainer_modalHeader__Fm96I.modal-header button.close {
  z-index: 20;
}

.SchoolInfoModalContainer_infoSection__bzhHi {
  padding: 20px;
  border-bottom: 1px solid var(--additional-gray-blue);
}

.SchoolInfoModalContainer_nestedSection__1b1yI {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.SchoolInfoModalContainer_nestedSection__1b1yI > div {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
}

.SchoolInfoModalContainer_infoSection__bzhHi:last-child {
  border-bottom: 1px solid transparent;
}

.SchoolInfoModalContainer_generalInfoSection__3djtc {
  display: flex;
}

.SchoolInfoModalContainer_generalInfoBox__EA24U {
  width: 10%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.SchoolInfoModalContainer_titleText__sQmqc {
  font-family: Ubuntu;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.SchoolInfoModalContainer_subTitleText__3WVYa {
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue);
}

.SchoolInfoModalContainer_labelText__3TA8r {
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.SchoolInfoModalContainer_infoText__3rzuV {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SchoolInfoModalContainer_controlsContainer__3tBeg {
  display: flex;
  overflow: hidden;
}

.SchoolInfoModalContainer_controlsContainer__3tBeg .btn {
  width: 100%;
  border-radius: 0;
}

.SchoolMarkersContainer_container__TPPzG {
  position: relative;
}

.SchoolMarkersContainer_mapContainer__2kWxX {
  height: 640px;
}

@media (max-width: 991px) {
  .SchoolMarkersContainer_mapContainer__2kWxX {
    height: 350px;
  }
}

.DrivingSchoolPage_schoolPage__2CxGZ {
  padding: 60px 0 43px;
}

.DrivingSchoolPage_schoolTopContainer__2Y_en {
  max-width: 520px;
  margin-bottom: 130px;
}

@media (max-width: 991px) {
  .DrivingSchoolPage_schoolPage__2CxGZ {
    padding: 24px 0 0;
  }

  .DrivingSchoolPage_schoolTopContainer__2Y_en {
    margin-bottom: 40px;
  }
}

.VipCard_vipCard__1gLVH {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  width: 473px;
  height: 300px;
  border-radius: 10px;
  background: url('/static/images/access_card.png') no-repeat left center, var(--whiteColor);
  background-size: 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  padding: 26px;
}

.VipCard_cardTitle__JAEDR {
  font-family: 'Hind', sans-serif;
  font-size: 32px;
  line-height: 60px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--theme-primary);
  margin-bottom: 42px;
}

.VipCard_ruleList__3yNAj {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.VipCard_ruleListItem__2Q8_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: var(--theme-primary-80);
}

.VipCard_ruleListItem__2Q8_1:not(:first-child) {
  margin-left: 12px;
}

.VipCard_ruleListItem__2Q8_1 > i {
  color: currentColor;
}

@media (max-width: 1199px) {
  .VipCard_vipCard__1gLVH {
    padding: 17px;
  }

  .VipCard_cardTitle__JAEDR {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 25px;
  }

  .VipCard_cardLogo__3RRHF {
    width: 75px;
    margin-bottom: 42px;
  }

  .VipCard_ruleListItem__2Q8_1 {
    width: 36px;
    height: 36px;
  }

  .VipCard_ruleListItem__2Q8_1 > i {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .VipCard_vipCard__1gLVH {
    padding: 14px;
    margin: 0 auto;
  }

  .VipCard_cardLogo__3RRHF {
    width: 68px;
    margin-bottom: 25px;
  }

  .VipCard_ruleListItem__2Q8_1:not(:first-child) {
    margin-left: 10px;
  }

  .VipCard_ruleListItem__2Q8_1 {
    width: 32px;
    height: 32px;
  }

  .VipCard_ruleListItem__2Q8_1 > i {
    font-size: 24px;
  }
}

@media (max-width: 500px) {
  .VipCard_vipCard__1gLVH {
    width: 315px;
    height: 200px;
  }
}

.PageContentTitle_pageContentTitle__cP3fs {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
}

.VipCodeContainer_vipPage__dNOyH {
  padding-top: 54px;
  margin-bottom: -57px;
}

.VipCodeContainer_vipPage__dNOyH .linkBtn {
  display: none;
}

.VipCodeContainer_vipPageText__200Bj {
  text-align: left;
}

.VipCodeContainer_vipPageContent__838Mb {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
}

.VipCodeContainer_vipPageSubtitle__G9ggs {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  margin-bottom: 24px;
}

.VipCodeContainer_vipCodeField__3EqYc {
  width: 100%;
}

.VipCodeContainer_vipCodeField__3EqYc .form-group {
  margin-bottom: 0;
  margin-right: 20px;
  max-width: 300px;
  width: 100%;
  flex-grow: 1;
}

.VipCodeContainer_formContent__3iF3w button {
  min-width: 0;
  flex-shrink: 0;
}

.VipCodeContainer_vipCodeInput__1epYK {
  height: 44px;
  border-radius: 6px;
}

.VipCodeContainer_vipItem__2-8bf {
  max-width: calc(50% - 20px);
  width: 100%;
  margin-right: 20px;
}

.VipCodeContainer_vipCodePageText__1QFor {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  max-width: 300px;
  text-align: left;
}

.VipCodeContainer_formContent__3iF3w {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 26px;
}

.VipCodeContainer_vipCodeBottomText__1KQqO {
  background-color: var(--lavender-grey);
  padding: 74px 0 102px;
}

@media (max-width: 991px) {
  .VipCodeContainer_vipPage__dNOyH {
    padding-top: 28px;
    margin-bottom: 0;
  }

  .VipCodeContainer_vipPageContent__838Mb {
    flex-flow: row wrap-reverse;
  }

  .VipCodeContainer_vipItem__2-8bf {
    max-width: 420px;
    margin: 24px auto 0 0;
  }

  .VipCodeContainer_vipPageContent__838Mb {
    padding-bottom: 0;
  }

  .VipCodeContainer_vipCodeBottomText__1KQqO {
    background-color: var(--whiteColor);
    padding: 0;
  }
}

@media (max-width: 767px) {
  .VipCodeContainer_vipItem__2-8bf {
    max-width: none;
  }

  .VipCodeContainer_formContent__3iF3w {
    flex-flow: row wrap;
  }

  .VipCodeContainer_formContent__3iF3w button {
    width: 100%;
    margin-top: 26px;
  }

  .VipCodeContainer_vipCodeField__3EqYc .form-group {
    margin-right: 0;
    max-width: none;
  }
}

.CockpitTopPanel_cockpitTopPanel__1ppww {
  padding: 80px 0 26px;
}

.CockpitTopPanel_rulesList__3mP-G {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.CockpitTopPanel_cardFooter__1sHAE {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.CockpitTopPanel_cardFooter__1sHAE .CockpitTopPanel_rulesList__3mP-G {
  margin-right: 35px;
}

.CockpitTopPanel_jumbotron__17Ou2 {
  width: 100%;
  max-width: 100%;
}

.CockpitTopPanel_jList__2F58i {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px;
  background-color: var(--lavender-grey);
  margin-right: 0;
  border-radius: 10px;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2 img {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:first-child {
  max-width: 55%;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:not(:last-child) {
  margin-right: 20px;
}

.CockpitTopPanel_panelCount__2WHD5 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 52px;
  line-height: 100px;
  color: var(--neutral-gray-blue);
}

.CockpitTopPanel_panelTitle__3DyRr {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: var(--neutral-dark-blue);
}

.CockpitTopPanel_jumbotronHeader__2Zveq {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.CockpitTopPanel_jumbotronHeaderItem__3Q2Da {
  max-width: 60%;
}

.CockpitTopPanel_panelDate__tBYaB,
.CockpitTopPanel_panelTime__fiMM7 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
}

.CockpitTopPanel_panelDate__tBYaB {
  color: var(--neutral-dark-blue);
}

.CockpitTopPanel_panelTime__fiMM7 {
  color: var(--neutral-dark-blue-50);
}

.CockpitTopPanel_daysContent__3hw4n {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin-bottom: 14px;
}

.CockpitTopPanel_daysValue__DBrBs {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 92px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.CockpitTopPanel_daysLabel__1tmot {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-left: 10px;
}

.CockpitTopPanel_mobileJumbotron__3vzI5 {
  display: none;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_jumbotron__17Ou2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  margin-bottom: 0;
  background-color: var(--neutral-light-gray);
}

.CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_panelTitle__3DyRr {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue);
}

.CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_daysValue__DBrBs,
.CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_daysLabel__1tmot {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_daysContent__3hw4n {
  margin-bottom: 0;
}

@media (max-width: 1300px) {
  .CockpitTopPanel_rulesListItem__30ABh:not(:last-child) {
    margin-right: 15px;
  }

  .CockpitTopPanel_cardFooter__1sHAE .CockpitTopPanel_rulesList__3mP-G {
    margin-right: 15px;
  }
}

@media (max-width: 1199px) {
  .CockpitTopPanel_cardFooter__1sHAE {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .CockpitTopPanel_jList__2F58i {
    flex-flow: row wrap;
    max-width: 500px;
    margin: 0 auto;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2,
  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:first-child {
    max-width: 400px;
    width: 100%;
    margin: 0 auto 20px;
    flex-grow: 1;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:not(:last-child) {
    margin-right: auto;
    margin-left: auto;
  }

  .CockpitTopPanel_cardFooter__1sHAE .CockpitTopPanel_rulesList__3mP-G {
    width: 100%;
    justify-content: center;
    margin-bottom: 28px;
    margin-right: 0;
  }

  .CockpitTopPanel_cardFooter__1sHAE > .btn {
    width: 100%;
  }

  .CockpitTopPanel_daysContent__3hw4n {
    justify-content: center;
  }

  .CockpitTopPanel_panelCount__2WHD5 {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .CockpitTopPanel_cockpitTopPanel__1ppww {
    padding: 0;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2 {
    background-color: var(--whiteColor);
    padding: 0;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:nth-child(2),
  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:nth-child(3) {
    display: none;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:not(:last-child) {
    margin-right: auto;
    margin-left: auto;
  }

  .CockpitTopPanel_mobileJumbotron__3vzI5 {
    display: block;
    margin: 40px 0 20px;
  }
}

.CockpitCategories_cardCategoriesContainer__3xHLi {
  padding: 72px 0 88px;
  background-color: var(--additional-gray-blue);
}

.CockpitCategories_cardCategoriesItem__2Ek6F {
  position: relative;
  background-color: var(--neutral-light-gray);
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 220px;
  overflow: hidden;
  border: none;
}

.CockpitCategories_cardCategoriesItem__2Ek6F:first-child {
  background: var(--neutral-light-gray) url(/static/media/il-1.9b8bc3f5.svg) no-repeat 100% 60%;
}

.CockpitCategories_cardCategoriesItem__2Ek6F:nth-child(2) {
  background: var(--neutral-light-gray) url(/static/media/il-2.7e8393f5.svg) no-repeat 90%;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .card-body {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 32px 36px 0;
  z-index: 2;
  width: 100%;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .card-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 16px;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .card-text {
  margin-bottom: 32px;
  color: var(--neutral-gray-blue);
  max-width: 300px;
}

.CockpitCategories_cardCategoriesProgress__3zF1E .card-text {
  max-width: 100%;
}

.CockpitCategories_cardCategoriesProgress__3zF1E .card-text div {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .card-footer {
  position: relative;
  padding: 0 36px 28px;
  background-color: transparent;
  border-top: none;
  z-index: 2;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .linkBtn {
  display: inline-flex;
}

.CockpitCategories_cardCategoriesItem__2Ek6F img {
  position: absolute;
  right: 5px;
  top: 15px;
  height: 240px;
  width: auto;
  z-index: 1;
}

.CockpitCategories_cardCategoriesProgress__3zF1E,
.CockpitCategories_cardCategoriesList__1mZry {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.CockpitCategories_cardCategoriesItem__2Ek6F {
  max-width: calc(33.3% - 12px);
  width: 100%;
  margin-bottom: 20px;
}

.CockpitCategories_cardCategoriesProgress__3zF1E .CockpitCategories_cardCategoriesItem__2Ek6F {
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 1199px) {
  .CockpitCategories_cardCategoriesItem__2Ek6F .card-title {
    font-size: 30px;
    line-height: 39px;
  }
}

@media (max-width: 991px) {
  .CockpitCategories_cardCategoriesItem__2Ek6F .card-title {
    font-size: 24px;
    line-height: 39px;
  }

  .CockpitCategories_cardCategoriesContainer__3xHLi {
    display: none;
  }

  .CockpitCategories_cardCategoriesItem__2Ek6F,
  .CockpitCategories_cardCategoriesItem__2Ek6F:nth-child(1),
  .CockpitCategories_cardCategoriesItem__2Ek6F:nth-child(2) {
    max-width: none;
    margin-bottom: 12px;
  }

  .CockpitCategories_cardCategoriesContainer__3xHLi {
    padding: 30px 0 28px;
  }
}

.CockpitProgress_cockpitBottomPanel__3wgQw {
    padding: 80px 0 100px;
  }

  .CockpitProgress_jumbotron__2uKj9 {
    padding: 0;
    background-color: var(--lavender-grey);
    flex-grow: 1;
  }

  .CockpitProgress_jumbotron__2uKj9:not(:last-child) {
    margin-right: 20px;
  }

.CockpitProgress_jumbotronBox__1qSKd {
  padding: 20px 28px;
}

.CockpitProgress_jumbotronBox__1qSKd:not(:last-child),
.CockpitProgress_jumbotronHeader__3PNnn {
  border-bottom: 1px solid var(--additional-gray-blue);
}

.CockpitProgress_jumbotronBox__1qSKd .h3 {
  color: var(--neutral-dark-blue);
  text-align: center;
}

.CockpitProgress_jumbotronBoxItem__GzA6Y {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.CockpitProgress_jumbotronBoxItem__GzA6Y:not(:last-child) {
  margin-bottom: 16px;
}

  .CockpitProgress_jumbotronTime__F7L8g,
  .CockpitProgress_jumbotronDate__3nhyt,
  .CockpitProgress_jumbotronCount__2YLle {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .CockpitProgress_jumbotronDate__3nhyt {
    color: var(--neutral-dark-blue);
  }

  .CockpitProgress_jumbotronTime__F7L8g {
    color: var(--neutral-dark-blue-50);
  }

  .CockpitProgress_jumbotronCount__2YLle {
    color: var(--functional-success);
  }

  .CockpitProgress_jumbotronText__1MoVo {
    font-family: 'Hind', sans-serif;
    font-size: 15px;
    line-height: 21px;
  }

  .CockpitProgress_jumbotronIcon__irzUu .icon-arrow {
    font-size: 14px;
    color: var(--theme-primary);
  }

  .CockpitProgress_progressBarContent__1goNg {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .CockpitProgress_progressBarContent__1goNg .progress {
    max-width: none;
    height: 16px;
  }

  .progress {
    flex-grow: 1;
    border-radius: 8px;
  }

  .CockpitProgress_progressBar__32WEy .progress-bar {
    background-color: var(--theme-primary);
    border-radius: 8px;
    max-width: none;
  }

  .CockpitProgress_status__3uxkp {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-left: 10px;
  }

  .CockpitProgress_bad__2JfrR {
    background-color: var(--functional-warning);
  }

  .CockpitProgress_good__hEoaB {
    background-color: var(--functional-success);
  }

  .CockpitProgress_middle__rouIu {
    background-color: var(--button-primary-pressed);
  }

  .CockpitProgress_empty__ScKlH {
    background-color: var(--additional-gray-blue);
  }

  .CockpitProgress_jumbotronBoxDate__Ma3Cn {
    width: 100%;
  }

  .CockpitProgress_instructorContent__HEYQE {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .CockpitProgress_daysValue__YxDe9 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 96px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: var(--functional-success);
    margin-bottom: 14px;
  }

  .CockpitProgress_daysText___5IfW {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: var(--functional-success);
  }

  .CockpitProgress_dateItemText__2rrdg {
    font-family: 'Hind', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--neutral-gray-blue);
  }

  .CockpitProgress_dateItemLabel__Gq5D0 {
    font-family: 'Hind', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--neutral-dark-blue-50);
  }

  .CockpitProgress_jumbotronBoxBtn__3eBaY a,
  .CockpitProgress_jumbotronBoxBtn__3eBaY .CockpitProgress_btn__2xXeq {
    width: 100%;
    border-radius: 0 0 6px 6px;
  }

  .CockpitProgress_jumbotronLabel__1aE9N {
    font-family: 'Hind', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--neutral-dark-blue-50);
    margin-bottom: 0;
  }

  .CockpitProgress_jumbotronValue__4uXBP {
    font-family: 'Hind', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--neutral-gray-blue);
  margin-bottom: 0;
}

.CockpitProgress_jumbotronBoxItem__GzA6Y {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.CockpitProgress_jumbotron__2uKj9 {
  width: 100%;
  max-width: 100%;
  margin-right: 177px;
  border-radius: 10px;
}

.CockpitProgress_dateItem__SKb0L {
    max-width: calc(50% - 10px);
    width: 100%;
  }

  .CockpitProgress_jumbotronPlaceholder__1Tmxz {
    text-align: center;
  }

  .CockpitProgress_jumbotronImg__2bpVn {
    display: block;
    max-width: 100%;
    margin: 35px auto 44px;
  }

  .CockpitProgress_jumbotronHeader__3PNnn {
    min-height: 0;
    flex-shrink: 0;
  }

  .CockpitProgress_jumbotronFooter__366iD {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .CockpitProgress_jumbotronFooter__366iD .CockpitProgress_jumbotronBox__1qSKd {
    border-bottom: none;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .CockpitProgress_jumbotronFooter__366iD .CockpitProgress_jumbotronBtn__pGZkG {
    min-height: 0;
    flex-shrink: 0;
  }

  .CockpitProgress_jumbotronIconBox__2Nsgj {
    width: 100%;
    text-align: center;
  }

  .CockpitProgress_jumbotronIconBox__2Nsgj i {
    font-size: 78px;
    color: var(--neutral-gray);
  }

  @media (max-width: 991px) {
    .CockpitProgress_cockpitBottomPanel__3wgQw {
      padding: 0;
    }


  .CockpitProgress_jumbotronHeader__3PNnn .CockpitProgress_jumbotronBox__1qSKd {
    padding: 20px 28px 0;
    border-bottom: none;
  }

    .CockpitProgress_jumbotronHeader__3PNnn {
      border-bottom: none;
    }

    .CockpitProgress_dateItemText__2rrdg {
      font-size: 17px;
    }


    .CockpitProgress_jumbotronBox__1qSKd {
      padding: 20px;
    }
  }

.CockpitBottomPanel_cockpitBottomPanel__bDMKx {
  padding: 80px 0 100px;
}

.CockpitBottomPanel_bottomPanelList__339DN {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 33%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
}

.CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:first-child .CockpitBottomPanel_jumbotronBox__2-7oS .CockpitBottomPanel_jumbotronBoxItem__1nKTL {
  padding: 16px;
}

.CockpitBottomPanel_jumbotron__2RMIt {
  padding: 0;
  background-color: var(--lavender-grey);
  flex-grow: 1;
}

.CockpitBottomPanel_jumbotron__2RMIt:not(:last-child) {
  margin-right: 20px;
}

.CockpitBottomPanel_jumbotronBox__2-7oS {
  padding: 20px 28px;
}

.CockpitBottomPanel_jumbotronBox__2-7oS:not(:last-child),
.CockpitBottomPanel_jumbotronHeader__3g5Nv {
  border-bottom: 1px solid var(--additional-gray-blue);
}

.CockpitBottomPanel_jumbotronBox__2-7oS .h3 {
  color: var(--neutral-dark-blue);
  text-align: center;
}

.CockpitBottomPanel_jumbotronBoxItem__1nKTL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.CockpitBottomPanel_jumbotronBoxItem__1nKTL:not(:last-child) {
  margin-bottom: 16px;
}

.CockpitBottomPanel_instructorName__2ZCGU {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  color: var(--neutral-dark-blue);
}

.CockpitBottomPanel_instructorSc__3OCu6 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue);
}

.CockpitBottomPanel_jumbotronTime__2aGXn,
.CockpitBottomPanel_jumbotronDate__3cPRQ,
.CockpitBottomPanel_jumbotronCount__2hhDv {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.CockpitBottomPanel_jumbotronDate__3cPRQ {
  color: var(--neutral-dark-blue);
}

.CockpitBottomPanel_jumbotronTime__2aGXn {
  color: var(--neutral-dark-blue-50);
}

.CockpitBottomPanel_jumbotronCount__2hhDv {
  color: var(--functional-success);
}

.CockpitBottomPanel_jumbotronText__2KPqR {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
}

.CockpitBottomPanel_jumbotronIcon__32MPO .icon-arrow {
  font-size: 14px;
  color: var(--theme-primary);
}

.CockpitBottomPanel_progressBarContent__27ypp {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.CockpitBottomPanel_progressBarContent__27ypp .progress {
  max-width: none;
  height: 16px;
}

.progress {
  flex-grow: 1;
  border-radius: 8px;
}

.CockpitBottomPanel_progressBar___IehR .progress-bar {
  background-color: var(--theme-primary);
  border-radius: 8px;
  max-width: none;
}

.CockpitBottomPanel_jumbotronBoxDate__2A3eD {
  width: 100%;
}

.CockpitBottomPanel_instructorContent__ItRoD {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.CockpitBottomPanel_daysValue__3ob0v {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 96px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-bottom: 14px;
}

.CockpitBottomPanel_daysText__3RvaI {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.CockpitBottomPanel_dateItemText__3URVL {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.CockpitBottomPanel_dateItemLabel__1M05t {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-50);
}

.CockpitBottomPanel_jumbotronBoxBtn__4dEW7 a,
.CockpitBottomPanel_jumbotronBoxBtn__4dEW7 .CockpitBottomPanel_btn__1TDAa {
  width: 100%;
  border-radius: 0 0 6px 6px;
}

.CockpitBottomPanel_jumbotronLabel__36SQ5 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-50);
  margin-bottom: 0;
}

.CockpitBottomPanel_jumbotronValue__1QsoP {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--neutral-gray-blue);
  margin-bottom: 0;
}

.CockpitBottomPanel_jumbotronBoxItem__1nKTL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.CockpitBottomPanel_dateItem__2g-4Z {
  max-width: calc(50% - 10px);
  width: 100%;
}

.CockpitBottomPanel_jumbotronPlaceholder__vHEAK {
  text-align: center;
}

.CockpitBottomPanel_jumbotronImg__E3ZNw {
  display: block;
  max-width: 100%;
  margin: 35px auto 44px;
}

.CockpitBottomPanel_jumbotronHeader__3g5Nv {
  min-height: 0;
  flex-shrink: 0;
}

.CockpitBottomPanel_jumbotronFooter__3G_5W {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.CockpitBottomPanel_jumbotronFooter__3G_5W .CockpitBottomPanel_jumbotronBox__2-7oS {
  border-bottom: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.CockpitBottomPanel_jumbotronFooter__3G_5W .CockpitBottomPanel_jumbotronBtn__qLPxk {
  min-height: 0;
  flex-shrink: 0;
}

.CockpitBottomPanel_jumbotronIconBox__3RdEq {
  width: 100%;
  text-align: center;
}

.CockpitBottomPanel_jumbotronIconBox__3RdEq i {
  font-size: 78px;
  color: var(--neutral-gray);
}

@media (max-width: 991px) {
  .CockpitBottomPanel_cockpitBottomPanel__bDMKx {
    padding: 0;
  }

  .CockpitBottomPanel_bottomPanelList__339DN {
    flex-flow: row wrap;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt {
    max-width: 400px;
    width: 100%;
    margin: 0 auto 20px;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:first-child {
    margin-bottom: 0;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt {
    background-color: var(--whiteColor);
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:first-child .CockpitBottomPanel_jumbotronBoxItem__1nKTL {
    background-color: var(--lavender-grey);
    border-radius: 6px;
  }

  .CockpitBottomPanel_jumbotronHeader__3g5Nv .CockpitBottomPanel_jumbotronBox__2-7oS {
    padding: 20px 28px 0;
    border-bottom: none;
  }

  .CockpitBottomPanel_jumbotronHeader__3g5Nv {
    border-bottom: none;
  }

  .CockpitBottomPanel_dateItemText__3URVL {
    font-size: 17px;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:nth-child(2) {
    background-color: var(--neutral-light-gray);
    order: 3;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:nth-child(2) > .CockpitBottomPanel_jumbotronBox__2-7oS:first-child {
    display: none;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child {
    order: 2;
    flex-flow: row wrap;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child > .CockpitBottomPanel_jumbotronBox__2-7oS:first-child,
  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child > .CockpitBottomPanel_jumbotronBox__2-7oS:nth-child(2) {
    max-width: 50%;
    width: 100%;
    border-bottom: none;
    background-color: var(--neutral-light-gray);
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child > .CockpitBottomPanel_jumbotronBox__2-7oS:first-child {
    padding-right: 10px;
  }

  .CockpitBottomPanel_jumbotronBox__2-7oS {
    padding: 20px;
  }

  .CockpitBottomPanel_daysValue__3ob0v {
    display: inline-block;
    font-size: 48px;
    line-height: 31px;
    text-align: left;
    margin-bottom: 0;
  }

  .CockpitBottomPanel_daysText__3RvaI {
    display: inline-block;
    font-size: 18px;
    line-height: 31px;
    text-align: left;
    margin-left: 5px;
  }

  .CockpitBottomPanel_jumbotronBox__2-7oS .h3 {
    font-size: 18px;
    line-height: 23px;
    text-align: left;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child .CockpitBottomPanel_jumbotronBox__2-7oS {
    padding: 20px;
  }
}

.CockpitLoggedOffline_placeholderContainer__2Chxu {
  padding-top: 75px;
  padding-bottom: 75px;
}

.CockpitLoggedOffline_invert__Zl9BY.CockpitLoggedOffline_placeholderContainer__2Chxu {
  background-color: var(--additional-gray-blue);
}

.CockpitLoggedOffline_box__2KKu4 {
  width: 100%;
  height: 220px;
  margin-bottom: 15px;
}

.CockpitLoggedOffline_box__2KKu4.CockpitLoggedOffline_vertical__3tf64 {
  height: 460px;
}

.CockpitLoggedOffline_invert__Zl9BY.CockpitLoggedOffline_box__2KKu4 {
  background-color: var(--whiteColor);
}

@media (max-width: 767px) {
  .CockpitLoggedOffline_box__2KKu4.CockpitLoggedOffline_mbRaw__1GEuL {
    height: 60px;
  }

  .CockpitLoggedOffline_box__2KKu4.CockpitLoggedOffline_vertical__3tf64 {
    height: 220px;
  }
}

.CockpitCategoriesAccordion_cardCategoriesMobile__JGk_P {
  display: none;
}

.CockpitCategoriesAccordion_cardCategoriesList__1wZ1u .CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card {
  border-radius: 6px;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2:not(:last-child) {
  margin-bottom: 12px;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  border-bottom: none;
  background-color: var(--lavender-grey);
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card {
  width: 100%;
  border-color: transparent;
  transition: all ease 0.3s;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card-body {
  padding: 18px 24px 21px;
  border: 1px solid transparent;
  width: 100%;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .collapse.show .card-body {
  width: 100%;
  border-color: var(--additional-blue);
  border-top: 0;
  border-radius: 0 0 10px 10px;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card-header .icon-arrow {
  font-size: 24px;
  color: var(--theme-primary);
  transform: rotate(90deg);
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card-text {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .CockpitCategoriesAccordion_cardCategoriesMobile__JGk_P {
    display: block;
    padding: 30px 0 40px;
  }
}

.CockpitLoggedContainer_mobileCategories__2mq8K {
  display: none;
}

@media (max-width: 991px) {
  .CockpitLoggedContainer_mobileCategories__2mq8K {
    display: block;
  }
}

.CockpitNotLoggedPage_rulesGroupContent__2UwLO {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.CockpitNotLoggedPage_imgContainer__3XC4L {
  max-width: calc(50% - 10px);
}

.CockpitNotLoggedPage_imgContainer__3XC4L img {
  height: 100%;
  width: auto;
}

.CockpitNotLoggedPage_questionsByTopic__3I0nc {
  padding-top: 80px;
}

.CockpitNotLoggedPage_questionsByTopicTitle__dbtf_ {
  color: var(--neutral-dark-blue);
}

.CockpitNotLoggedPage_questionsByTopicList__1DQnU {
  column-count: 3;
  column-width: 390px;
  grid-column-gap: 20px;
  column-gap: 20px;
  margin: 54px 0 80px;
}

.CockpitNotLoggedPage_questionsByTopicListItem__2ZRHJ {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.CockpitNotLoggedPage_questionsLink__K2J7- {
  display: inline-block;
  margin: 16px 0;
  color: var(--neutral-dark-blue);
}

.CockpitNotLoggedPage_questionsByTopicList__1DQnU > p {
  color: var(--neutral-gray-blue);
}

@media (max-width: 1199px) {
  .CockpitNotLoggedPage_CockpitNotLoggedPage__26nHo .CockpitNotLoggedPage_imgContainer__3XC4L {
    display: none;
  }

  .CockpitNotLoggedPage_btnList__UcQGx {
    justify-content: center;
    margin-bottom: 20px;
  }

  .CockpitNotLoggedPage_btnList__UcQGx .CockpitNotLoggedPage_btn__2qGMS {
    margin: 10px auto 0;
  }
}

@media (max-width: 991px) {
  .CockpitNotLoggedPage_questionsByTopicList__1DQnU {
    column-width: 320px;
  }
}

.DrivingInstructor_instructorPage__1fMbV {
  padding: 60px 0 120px;
}

.DrivingInstructor_instructorPage__1fMbV h1 {
  margin-bottom: 32px;
}

.DrivingInstructor_instructorSubtitle__1LhOq {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

p.DrivingInstructor_instructorSubtitle__1LhOq {
  color: var(--neutral-dark-blue);
}

.DrivingInstructor_instructorList__2m36Q p {
  position: relative;
  padding-left: 22px;
}

.DrivingInstructor_instructorList__2m36Q p:before {
  position: absolute;
  content: '';
  left: 0;
  top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--neutral-gray);
}

.DrivingInstructor_instructorPage__1fMbV .jumbotron {
  margin-right: 0;
  padding: 24px 13px 30px 25px;
  border-radius: 10px;
  max-width: 440px;
}

.DrivingInstructor_jumbotronTitle__22SrI {
  padding-left: 20px;
  max-width: 420px;
}

@media (max-width: 991px) {
  .DrivingInstructor_instructorPage__1fMbV {
    padding: 24px 0 0;
  }

  .DrivingInstructor_jumbotronTitle__22SrI {
    padding-left: 0;
  }
}

.RegisterForm_checkList__ELXGt {
  border-radius: 6px;
  background-color: var(--neutral-light-gray);
  padding: 16px 18px;
}

.RegisterForm_checkList__ELXGt .custom-checkbox:not(:last-child) {
  margin-bottom: 12px;
}

.PaymentMethod_paymentImgBox__1GHP8 {
  background-color: var(--whiteColor);
  border-radius: 5px;
  width: 53px;
  height: 34px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 23px;
}

.PaymentMethod_paymentImgBox__1GHP8 img {
  max-height: 34px;
  max-width: 53px;
  width: auto;
}

.PaymentConfirmation_paymentModal__XgKBE .modal-dialog {
  max-width: 350px;
}

.PaymentConfirmation_paymentModal__XgKBE .modal-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-bottom: 12px;
  margin-top: 0;
}

.PaymentConfirmation_modalText__1Xh2u {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  margin-bottom: 12px;
}

.PaymentConfirmation_paymentModal__XgKBE .modal-header,
.PaymentConfirmation_paymentModal__XgKBE .modal-body {
  padding: 0;
}

.PaymentConfirmation_paymentModal__XgKBE .modal-content {
  padding: 20px 20px 10px;
}

.PaymentConfirmation_paymentModal__XgKBE .modal-footer > button {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--modal-footer-btn-color);
  margin-top: 22px;
}

.PaymentConfirmation_paymentModal__XgKBE .modal-footer > button:hover {
  text-decoration: none;
}

.RegisterContainer_registration__3TzbL {
  padding: 40px 0;
}

.RegisterContainer_registration__3TzbL .linkBtn {
  margin-bottom: 20px;
  margin-left: 20px;
}

.RegisterContainer_registration__3TzbL .linkBtn .icon-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}

.RegisterContainer_registration__3TzbL .btn-secondary {
  display: block;
  margin-left: auto;
  margin-top: 20px;
}

.RegisterContainer_formRegistration__3qC1j {
  margin-top: 24px;
}

.RegisterContainer_formTitle__1DXK5 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
  color: var(--neutral-dark-blue);
  margin-left: 20px;
}

.RegisterContainer_planInfo__3giEr {
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  background-color: var(--neutral-light-gray);
  padding: 24px 32px;
}

.RegisterContainer_planInfoBox__2xd7d {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.RegisterContainer_planInfoBox__2xd7d > .RegisterContainer_planInfoItem__1i_N2 {
  max-width: calc(50% - 5px);
  width: 100%;
  margin-bottom: 8px;
}

.RegisterContainer_itemLabel__2Fvet {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.RegisterContainer_itemValue__1ehY5 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.RegisterContainer_registration__3TzbL .form-group {
  margin-bottom: 22px;
}

.RegisterContainer_affiliatePrice__24SeM {
  position: relative;
  display: inline;
}

.RegisterContainer_affiliatePrice__24SeM::after {
  border-bottom: 0.13em solid red;
  content: '';
  margin-top: calc(0.2em / 2 * -1);
  position: absolute;
  top: 50%;
  width: 130%;
  left: -9px;
}

.RegisterContainer_registration__3TzbL .form-control {
  height: 44px;
  border: 1px solid var(--neutral-gray);
}

.RegisterContainer_paymentList__MF3CZ .list-group-item:not(:last-child) {
  margin-bottom: 8px;
}

.RegisterContainer_paymentList__MF3CZ .list-group-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--neutral-light-gray);
  border: 1px solid var(--additional-gray-blue);
  border-radius: 10px;
  font-family: 'Hind', sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
  cursor: pointer;
  transition: all ease 0.3s;
  padding: 12px 13px;
}

.RegisterContainer_paymentList__MF3CZ .list-group-item:hover,
.RegisterContainer_paymentList__MF3CZ .list-group-item.active {
  background-color: var(--whiteColor);
}

.RegisterContainer_paymentList__MF3CZ .p3 {
  color: var(--neutral-gray-blue);
}

.custom-control-label::before {
  position: absolute;
  top: 0;
  left: -24px;
  display: block;
  width: 24px;
  height: 24px;
  content: '';
  background-color: var(--whiteColor);
  border: 1px solid var(--neutral-gray);
  transition: all ease 0.3s;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input {
  position: absolute;
  opacity: 0;
  z-index: 2;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--neutral-dark-blue-70);
  background-color: var(--whiteColor);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  content: '\e902';
  color: var(--theme-primary);
  font-family: 'icomoon';
  width: 24px;
  height: 24px;
  top: 0;
  left: -24px;
  font-size: 24px;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: var(--whiteColor);
}

.custom-control-input.is-valid:checked ~ .custom-control-label::after, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::after {
  color: var(--functional-success);
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: var(--neutral-gray-blue);
}

.custom-checkbox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.custom-control-label {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  padding-left: 20px;
}

.RegisterContainer_planInfoTopOffset__39JO0 {
  margin-top: 20px;
}

.RegisterContainer_planInfoTopOffsetMobile__1Vkgx {
  display: none;
}

@media (max-width: 991px) {
  .RegisterContainer_affiliateInfo__3Ly5H {
    max-width: unset;
  }

  .RegisterContainer_planInfo__3giEr {
    margin-bottom: 20px;
  }

  .RegisterContainer_registration__3TzbL .linkBtn,
  .RegisterContainer_formTitle__1DXK5 {
    margin-left: 0;
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfo__3giEr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfo__3giEr .RegisterContainer_itemLabel__2Fvet {
    display: none;
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfo__3giEr.RegisterContainer_planInfoTopOffsetMobile__1Vkgx .RegisterContainer_itemLabel__2Fvet {
    display: block;
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfo__3giEr .RegisterContainer_itemValue__1ehY5,
  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfoBox__2xd7d .RegisterContainer_planInfoItem__1i_N2 {
    margin-bottom: 0;
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfoBox__2xd7d {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 50%;
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfo__3giEr > .RegisterContainer_planInfoItem__1i_N2 > .RegisterContainer_itemValue__1ehY5,
  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfoBox__2xd7d .RegisterContainer_planInfoItem__1i_N2 .RegisterContainer_itemValue__1ehY5 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfo__3giEr > .RegisterContainer_planInfoItem__1i_N2 > .RegisterContainer_itemValue__1ehY5,
  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfoBox__2xd7d .RegisterContainer_planInfoItem__1i_N2:last-child .RegisterContainer_itemValue__1ehY5 {
    text-transform: uppercase;
    color: var(--neutral-dark-blue);
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfo__3giEr > .RegisterContainer_planInfoItem__1i_N2 > .RegisterContainer_itemValue__1ehY5 {
    margin-right: 70px;
  }

  .RegisterContainer_formRegistration__3qC1j .RegisterContainer_planInfoBox__2xd7d .RegisterContainer_planInfoItem__1i_N2:first-child .RegisterContainer_itemValue__1ehY5 {
    color: var(--neutral-dark-blue-50);
  }

  .RegisterContainer_planInfoTopOffsetMobile__1Vkgx {
    display: block;
  }

  .RegisterContainer_planInfo__3giEr.RegisterContainer_planInfoTopOffset__39JO0 {
    display: none;
  }
}

@media (max-width: 767px) {
  .RegisterContainer_planInfo__3giEr {
    max-width: none;
  }

  .RegisterContainer_registration__3TzbL .btn-secondary {
    margin-left: 0;
    width: 100%;
  }

  .RegisterContainer_affiliateInfo__3Ly5H.RegisterContainer_planInfo__3giEr > .RegisterContainer_planInfoItem__1i_N2 > .RegisterContainer_itemValue__1ehY5 {
    margin: 0 10px;
  }
}

@media (max-width: 450px) {
  .RegisterContainer_affiliateInfo__3Ly5H.RegisterContainer_planInfo__3giEr > .RegisterContainer_planInfoItem__1i_N2 > .RegisterContainer_itemValue__1ehY5 {
    font-size: 13px;
  }

  .RegisterContainer_affiliateInfo__3Ly5H.RegisterContainer_planInfo__3giEr {
    padding: 5px;
  }
}

.FaqPage_faqPage__3bBGl {
  padding: 80px 0 60px;
}

.FaqPage_faqList__Qu_Ms {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
}

.FaqPage_faqListContainer__3NTnC {
  max-width: 30%;
  width: 100%;
}

.FaqPage_faqPage__3bBGl .pageTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.FaqPage_faqLink__2nBBu {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  text-transform: none;
}
.FaqPage_faqLink__2nBBu:hover {
  text-decoration: none;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .FaqPage_faqPage__3bBGl {
    padding: 0;
  }

  .FaqPage_faqPage__3bBGl .pageTitle {
    font-size: 28px;
    line-height: 42px;
  }

  .FaqPage_faqList__Qu_Ms {
    margin-top: 12px;
  }

  .FaqPage_faqListContainer__3NTnC {
    max-width: none;
  }
}

.AnleitungPage_manualPage__11RQl {
  padding-bottom: 50px;
}

.AnleitungPage_manualPageTitle__2JrvD {
  margin: 50px 0 26px;
}

.AnleitungPage_pageText__3JDtG,
.AnleitungPage_pageTextBold__3B17y {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.AnleitungPage_pageSubtitle__2DqX5 {
  display: block;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.AnleitungPage_pageTextBold__3B17y {
  font-weight: bold;
}

.AnleitungPage_manualPageContent__2FMVf {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.AnleitungPage_manualPageContentIcon__SdBP- {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 44px 0 28px;
}

.AnleitungPage_manualPageContentIcon__SdBP- div {
  margin-right: 20px;
}

.AnleitungPage_rulesList__JulAW {
  display: flex;
  margin-right: 20px;
  width: 100%;
}

.NotFoundContainer_notFoundPage__N7cSY {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-top: 60px;
}

.NotFoundContainer_pageContainer__1sVTq {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.NotFoundContainer_imgContainer__3lyo2 {
  margin-bottom: 38px;
}

.NotFoundContainer_pageTextError__nAVCB {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 120px;
  line-height: 200px;
  text-align: center;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue-70);
}

.NotFoundContainer_pageText__2MVmU {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: var(--neutral-dark-blue-70);
  margin-top: 14px;
}

.NotFoundContainer_linkBtn__1su3T {
  display: flex;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--button-default-focused);
  margin-top: 80px;
}

.NotFoundContainer_linkBtn__1su3T i {
  display: block;
  transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

@media (max-width: 991px) {
  .NotFoundContainer_pageTextError__nAVCB {
    font-size: 96px;
  }
}

.ZoomImage_root__2yCuj {
  position: relative;
}

.ZoomImage_zoomBtn__3wgQJ {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.ZoomImage_modal__1CeE- .modal-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1060;
}

.ZoomImage_modal__1CeE- .modal-body {
  max-height: 90vh;
}

.AboutPage_aboutPage__1MWWQ {
  padding: 60px 0 30px;
}

.AboutPage_aboutBtn__14Ee1 {
  margin: 12px 0 28px;
}

.AboutPage_aboutPageSubtitle__-54D8 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
  margin: 20px 0 28px;
}

.AboutPage_aboutPageTextList__t99uL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.AboutPage_aboutPageItem__Z48Ug {
  max-width: 33.3%;
  width: 100%;
}

.AboutPage_aboutPageItem__Z48Ug img {
  max-width: 100%;
}

.AboutPage_aboutPageItem__Z48Ug:not(:last-child) {
  margin-right: 26px;
}

.AboutPage_aboutPageText__oXG8a {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.AboutPage_videoTitle__1lE2J {
  text-align: center;
  text-transform: none;
  text-transform: initial;
}

.AboutPage_videoContainer__DuU_x {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.AboutPage_videoContainer__DuU_x iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .AboutPage_aboutPage__1MWWQ {
    padding: 0;
  }

  .AboutPage_aboutPageTextList__t99uL {
    flex-flow: row wrap;
  }

  .AboutPage_aboutPageItem__Z48Ug {
    max-width: none;
  }

  .AboutPage_aboutPageItem__Z48Ug:not(:last-child) {
    margin-right: 0;
  }

  .AboutPage_aboutPageItem__Z48Ug:not(:first-child) {
    margin-top: 20px;
  }

  .AboutPage_aboutBtn__14Ee1 {
    max-width: 320px;
    width: 100%;
    margin: 12px auto 28px;
    display: block;
  }
}

.TheoryTopicsListContainer_topicList__1VkRq {
  max-width: 400px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
  margin-right: 20px;
}

.TheoryTopicsListContainer_topicList__1VkRq div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-right: 20px;
}

.TheoryTopicsListContainer_topicList__1VkRq div:not(:last-child) {
  margin-bottom: 12px;
}

.TheoryTopicsListContainer_topicList__1VkRq i {
  display: block;
  font-size: 14px;
  color: var(--theme-primary);
}

.TheoryTopicsListContainer_topicList__1VkRq button:hover,
.TheoryTopicsListContainer_topicList__1VkRq button:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

@media (max-width: 991px) {
  .TheoryTopicsListContainer_topicList__1VkRq {
    display: none;
  }
}

.TheoryBookByTopicsPage_theoryTopicPage__3_tcO {
  background-color: var(--lavender-grey);
  padding-top: 32px;
}

.theoryTopicPage .topicsListItem {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.TheoryBookByTopicsPage_theoryTopicPage__3_tcO .TheoryBookByTopicsPage_linkBtn__1FFum {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.TheoryBookByTopicsPage_linkBtn__1FFum i {
  display: block;
  transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

.TheoryBookByTopicsPage_theoryTopicContent__2QXmA {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 100px;
  max-height: 930px;
  height: 100%;
  overflow: hidden;
}

.TheoryBookByTopicsPage_mobileView__fNXhO {
  display: none;
}

@media (max-width: 991px) {
  .TheoryBookByTopicsPage_theoryTopicPage__3_tcO {
    background-color: var(--whiteColor);
  }

  .TheoryBookByTopicsPage_theoryTopicContent__2QXmA {
    padding-bottom: 20px;
    max-height: none;
    overflow: inherit;
  }

  .TheoryBookByTopicsPage_mobileView__fNXhO {
    display: block;
    margin-bottom: 40px;
  }
}

.ContentBook_questionBtnListItem__3pNQe {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  cursor: pointer;
  color: var(--theme-primary);
  border-radius: 2px;
  margin-bottom: 12px;
}

.ContentBook_movieSpacer__2hzDF {
  margin-bottom: 34px;
}

.ContentBook_glossaryHeader__2xHLu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.ContentBook_glossaryHeader__2xHLu .glossary-title {
  margin-right: 10px;
}

.ContentBook_questionBtnListItem__3pNQe > i {
  font-size: 48px;
}

.ContentBook_glossaryFooter__155ET {
  position: absolute;
  bottom: 0;
  left: 44px;
  right: 44px;
  padding: 38px 44px 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
}

.ContentBook_footerLink__2DA8P {
  display: none;
}

.ContentBook_btnPrev__2UPCX,
.ContentBook_btnNext__3VOFl {
  position: absolute;
  top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: var(--additional-gray-blue);
  border-radius: 6px;
  transition: all ease 0.3s;
  min-width: auto;
}

.ContentBook_btnPrev__2UPCX:disabled,
.ContentBook_btnNext__3VOFl:disabled {
  opacity: 0;
  pointer-events: none;
}

.ContentBook_btnPrev__2UPCX > i,
.ContentBook_btnNext__3VOFl > i {
  font-size: 20px;
  color: var(--neutral-gray-blue);
}

.ContentBook_btnPrev__2UPCX {
  transform: rotate(180deg);
  left: 0;
}

.ContentBook_btnNext__3VOFl {
  right: 0;
}

.ContentBook_btnPrev__2UPCX:hover,
.ContentBook_btnNext__3VOFl:hover,
.ContentBook_btnPrev__2UPCX:focus,
.ContentBook_btnNext__3VOFl:focus,
.ContentBook_btnPrev__2UPCX:not(:disabled):not(.ContentBook_disabled__1JJeb):active,
.ContentBook_btnNext__3VOFl:not(:disabled):not(.ContentBook_disabled__1JJeb):active,
.ContentBook_btnPrev__2UPCX:not(:disabled):not(.ContentBook_disabled__1JJeb).ContentBook_active__sLNC2,
.ContentBook_btnNext__3VOFl:not(:disabled):not(.ContentBook_disabled__1JJeb).ContentBook_active__sLNC2,
.ContentBook_btnPrev__2UPCX:not(:disabled):not(.ContentBook_disabled__1JJeb):active:focus,
.ContentBook_btnNext__3VOFl:not(:disabled):not(.ContentBook_disabled__1JJeb):active:focus {
  background-color: var(--button-secondary-enable);
  box-shadow: none;
}

.glossary-subtitle {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
}

.glossary-subtitle, .glossary-text {
  margin-bottom: 12px;
  display: block;
  width: 100%;
}

.glossary-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 20px;
}

.ContentBook_glossaryImgList__2csk- {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.ContentBook_imgContent__2RBYL,
.ContentBook_imgPlaceholderContent__NmhH3 {
  display: inline-flex;
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-right: 24px;
}

.ContentBook_imgPlaceholderContent__NmhH3 {
  width: 160px;
  height: 120px;
}

.ContentBook_glossaryScrollBox__KiUKs {
  max-height: calc(100% - 100px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.ContentBook_glossaryScrollBox__KiUKs::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.ContentBook_glossaryScrollBox__KiUKs::-webkit-scrollbar {
  width: 12px;
}

.ContentBook_glossaryScrollBox__KiUKs::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.ContentBook_modal__34oJW,
.ContentBook_backdrop__H0fv8,
.ContentBook_topicLink__2nZYb {
  display: none !important;
}

@media (max-width: 991px) {
  .ContentBook_questionBtnListItem__3pNQe {
    width: 32px;
    height: 32px;
  }

  .ContentBook_questionBtnListItem__3pNQe > i {
    font-size: 32px;
  }

  .ContentBook_glossaryScrollBox__KiUKs {
    max-height: none;
    overflow-y: inherit;
    overflow-x: hidden;
  }

  .ContentBook_imgPlaceholderContent__NmhH3 {
    width: 120px;
    height: 80px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .ContentBook_imgContent__2RBYL,
  .ContentBook_imgPlaceholderContent__NmhH3 {
    max-width: 100%;
  }

  .ContentBook_glossaryScrollBox__KiUKs.ContentBook_withModal__2PrGL {
    display: none;
  }

  .ContentBook_modal__34oJW,
  .ContentBook_backdrop__H0fv8,
  .ContentBook_topicLink__2nZYb {
    display: block !important;
  }

  .ContentBook_glossaryFooter__155ET {
    position: relative;
    left: 0;
    right: 0;
    padding: 0;
    height: 48px;
  }

  .ContentBook_btnPrev__2UPCX,
  .ContentBook_btnNext__3VOFl {
    top: 0;
  }
}

.ContentBookContainer_glossaryContent__1Z2cV {
  position: relative;
  padding: 40px 44px 44px;
  background-color: var(--whiteColor);
  border-radius: 6px;
  flex-grow: 1;
  width: 100%;
}

.ContentBookContainer_glossaryScrollBox__Lrx_T::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.ContentBookContainer_glossaryScrollBox__Lrx_T::-webkit-scrollbar {
  width: 12px;
}

.ContentBookContainer_glossaryScrollBox__Lrx_T::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

@media (max-width: 991px) {
  .ContentBookContainer_glossaryContent__1Z2cV {
    padding: 0;
  }
}

.ContentTopicsListContainer_topicList__3vi_m {
  max-width: 400px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
  margin-right: 20px;
  overflow-y: scroll;
}

.ContentTopicsListContainer_topicList__3vi_m div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-right: 20px;
}

.ContentTopicsListContainer_topicList__3vi_m div:not(:last-child) {
  margin-bottom: 12px;
}

.ContentTopicsListContainer_topicList__3vi_m i {
  display: block;
  font-size: 14px;
  color: var(--theme-primary);
}

.ContentTopicsListContainer_topicList__3vi_m button:hover,
.ContentTopicsListContainer_topicList__3vi_m button:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

@media (max-width: 991px) {
  .ContentTopicsListContainer_topicList__3vi_m {
    display: none;
  }
}

.ContentByTopicPage_theoryTopicPage__3BVte {
  background-color: var(--lavender-grey);
  padding-top: 32px;
}

.theoryTopicPage .topicsListItem {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.ContentByTopicPage_theoryTopicPage__3BVte .ContentByTopicPage_linkBtn__vox55 {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.ContentByTopicPage_linkBtn__vox55 i {
  display: block;
  transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

.ContentByTopicPage_theoryTopicContent__2HHaQ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 100px;
  max-height: 930px;
  height: 100%;
  overflow: hidden;
}

.ContentByTopicPage_mobileView__3Ks_U {
  display: none;
}

@media (max-width: 991px) {
  .ContentByTopicPage_theoryTopicPage__3BVte {
    background-color: var(--whiteColor);
  }

  .ContentByTopicPage_theoryTopicContent__2HHaQ {
    padding-bottom: 20px;
    max-height: none;
    overflow: inherit;
  }

  .ContentByTopicPage_mobileView__3Ks_U {
    display: block;
    margin-bottom: 40px;
  }
}

.TheoryGlossary_glossaryListItem__3rfbP {
  margin-bottom: 16px;
}

.TheoryGlossary_glossaryLink__ICziV {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  padding: 0;
  text-align: left;
  border: none;
}

.TheoryGlossaryContainer_glossaryList__2WD_y {
  background-color: var(--whiteColor);
  padding: 32px;
  border-radius: 6px;
  overflow-y: auto;
  max-width: 400px;
  width: 100%;
  margin-right: 32px;
  min-width: 0;
  flex-shrink: 0;
}

.TheoryGlossaryContainer_glossaryList__2WD_y::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TheoryGlossaryContainer_glossaryList__2WD_y::-webkit-scrollbar {
  width: 12px;
}

.TheoryGlossaryContainer_glossaryList__2WD_y::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.TheoryGlossaryContainer_letterItem__2Y9FI {
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 16px;
}

@media (max-width: 991px) {
  .TheoryGlossaryContainer_glossaryList__2WD_y {
    width: 100%;
    max-width: none;
    max-height: 500px;
    overflow-y: auto;
    margin-bottom: 40px;
    background-color: var(--lavender-grey);
  }
}

.TheoryGlossaryPage_glossaryPage__1UHrJ {
  padding: 32px 0 104px;
  background-color: var(--lavender-grey);
}

.TheoryGlossaryPage_glossaryPage__1UHrJ .h2 {
  color: var(--neutral-dark-blue);
  margin: 20px 0;
}

.TheoryGlossaryPage_glossaryPageContent__347Qu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  max-height: 750px;
  height: 100%;
  overflow: hidden;
}

.TheoryGlossaryPage_glossaryPage__1UHrJ .TheoryGlossaryPage_linkBtn__2RyWr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--button-default-focused);
  transition: all ease 0.3s;
  text-transform: uppercase;
}

.TheoryGlossaryPage_glossaryPage__1UHrJ .TheoryGlossaryPage_linkBtn__2RyWr i {
  display: block;
  font-size: 14px;
  color: currentColor;
  transform: rotate(180deg);
  margin-left: 5px;
}

.TheoryGlossaryPage_glossaryPage__1UHrJ .TheoryGlossaryPage_linkBtn__2RyWr:hover,
.TheoryGlossaryPage_glossaryPage__1UHrJ .TheoryGlossaryPage_linkBtn__2RyWr:focus {
  text-decoration: none;
}

.TheoryGlossaryPage_glossaryPageContent__347Qu::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TheoryGlossaryPage_glossaryPageContent__347Qu::-webkit-scrollbar {
  width: 12px;
}

.TheoryGlossaryPage_glossaryPageContent__347Qu::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.TheoryGlossaryPage_mobileView__38HSi {
  display: none;
}

@media (max-width: 991px) {
  .TheoryGlossaryPage_glossaryPage__1UHrJ {
    padding: 24px 0 20px;
    background-color: var(--whiteColor);
  }

  .TheoryGlossaryPage_glossaryPageContent__347Qu {
    flex-flow: row wrap;
    max-height: none;
  }

  .TheoryGlossaryPage_glossaryPageContent__347Qu > div:last-child {
    display: none;
  }

  .TheoryGlossaryPage_mobileView__38HSi {
    display: block;
  }
}

.OfferCard_headerText__3cgSR {
  font-family: 'Ubuntu', sans-serif;
  line-height: 21px;
  font-size: 21px;
  font-weight: bold;
  color: var(--modal-footer-btn-color);
}

.OfferCard_continueText__28B-d {
  font-size: 24px;
}

.OfferCard_footerDays__2vbiz {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  line-height: 21px;
  font-size: 14px;
  color: var(--functional-success);
}

@media (max-width: 767px) {
  h3,
  .OfferCard_h3__uJmPg {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  h3,
  .OfferCard_h3__uJmPg {
    font-size: 14px;
    line-height: 22px;
  }
}

.OfferAccordion_offerAccordionList__1xG_E {
  display: block;
}

.OfferAccordion_accordionCardItem__1Ho0Z {
  max-width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
}

.OfferAccordion_accordionBtn__3Bf9s {
  transform: rotate(90deg);
  color: var(--theme-primary);
  width: 24px;
  height: 24px;
}

.OfferAccordion_accordionBtn__3Bf9s > i {
  font-size: 24px;
}

.OfferAccordion_cardHeaderText__3GEDl,
.OfferAccordion_cardHeaderDays__1GC8M {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.OfferAccordion_cardHeaderDays__1GC8M {
  line-height: 18px;
  color: var(--neutral-dark-blue-50);
}

.OfferAccordion_cardHeaderText__3GEDl {
  line-height: 22px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 18px 20px 18px 16px;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card-body {
  padding: 25px 0 0;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card-text {
  font-family: 'Hind', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-70);
  padding: 0 20px;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card {
  border: none;
  background-color: var(--neutral-light-gray);
  border-radius: 6px !important;
}

.OfferAccordion_cardHeaderInfo__1hVFK {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 50%;
  width: 100%;
}

.OfferAccordion_accordionFooterBtn__3ulmM {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02px;
  width: 100%;
}

@media (max-width: 991px) {
  .OfferAccordion_offerAccordionList__1xG_E {
    display: flex;
  }
}

@media (max-width: 600px) {
  .OfferAccordion_cardHeaderInfo__1hVFK {
    max-width: 60%;
  }
}

.OfferCardContainer_offerCardList__mjenO .card {
  margin-bottom: 32px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: none;
  width: 100%;
  overflow: hidden;
}

.OfferCardContainer_offerAccordionList__1MaTj .card {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
}
.OfferCardContainer_placeholderBox__2LHLx {
  width: calc(33.3% - 15px);
  background-color: var(--whiteColor);
  margin-bottom: 30px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
}

.OfferCardContainer_offerCardList__mjenO {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.OfferCardContainer_offerCardList__mjenO .card {
  margin-bottom: 32px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: none;
  width: 100%;
  overflow: hidden;
}

.OfferCardContainer_offerCardList__mjenO .card-body {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 20px 0;
  width: 100%;
}

.OfferCardContainer_offerCardList__mjenO .card-title,
.OfferCardContainer_offerCardList__mjenO .card-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.OfferCardContainer_offerCardList__mjenO .card-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 28px;
  color: var(--neutral-dark-blue);
}

.OfferCardContainer_offerCardList__mjenO .card-footer {
  background-color: transparent;
  border-top: none;
  padding: 0 20px 25px;
  margin-top: 44px;
}

.OfferCardContainer_offerCardList__mjenO .offerCard {
  background-color: var(--neutral-light-gray);
}

@media (max-width: 991px) {
  .OfferCardContainer_card___AZwS,
  .OfferCardContainer_placeholderBox__2LHLx {
    max-width: calc(50% - 15px);
    margin-bottom: 20px;
    background-color: var(--lavender-grey);
  }
}

@media (max-width: 767px) {
  .OfferCardContainer_card___AZwS {
    max-width: none;
  }

  h3,
  .OfferCardContainer_h3__115Eg {
    font-size: 14px;
    line-height: 22px;
  }

  .OfferCardContainer_placeholderBox__2LHLx {
    width: 100%;
    max-width: 100%;
    height: 60px;
    background-color: var(--lavender-grey);
  }

  .OfferCardContainer_offerAccordionList__1MaTj {
    display: flex;
    flex-direction: column;
  }
}

.OffersPage_offersPageText__1Oeui {
  padding-top: 60px;
  padding-bottom: 100px;
}

.OffersPage_pageTextList__m2vWc,
.OffersPage_pageTextListMobile__t1ve- {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.OffersPage_pageTextList__m2vWc > p,
.OffersPage_pageTextListMobile__t1ve- > p {
  max-width: calc(50% - 9px);
  width: 100%;
}

.OffersPage_pageTextListMobile__t1ve- {
  display: none;
}

.OffersPage_iconsList__3LA5P {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 62px;
  margin-top: 30px;
}

.OffersPage_iconsListItem__1gAUC {
  width: 100px;
  height: 100px;
}

.OffersPage_iconsListItem__1gAUC:not(:last-child) {
  margin-right: 20px;
}

.OffersPage_offerPageCard__361cE {
  background-color: var(--neutral-light-gray);
  padding-top: 80px;
  padding-bottom: 80px;
}

.OffersPage_offerPageContent__1SqiX {
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.OffersPage_offerCard__22yTG {
  background-color: var(--neutral-light-gray);
  max-width: 408px;
  width: 100%;
  margin-top: 74px;
}

.OffersPage_offerPageItem__3xXi0 {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  max-width: 50%;
  width: 100%;
}

.OffersPage_offerPageVip__2QnVW {
  padding: 58px 0 37px;
}

.OffersPage_offerPageVip__2QnVW .OffersPage_offerCard__22yTG {
  margin-bottom: 0;
  border: none;
}

.OffersPage_offerPageVip__2QnVW .OffersPage_offerCard__22yTG .card-body {
  width: 100%;
  padding: 40px 32px;
}

.OffersPage_offerPageVip__2QnVW .OffersPage_offerCard__22yTG .card-text {
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.OffersPage_cardFooterSingle__1bWdM {
  background-color: var(--theme-primary);
  padding: 0;
  border-radius: 0 0 10px 10px;
}

.OffersPage_offerFooterLink__3ity_ {
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--whiteColor);
  padding: 15px 20px;
  transition: all ease-in 0.3s;
}

.OffersPage_offerFooterLink__3ity_:hover,
.OffersPage_offerFooterLink__3ity_:focus {
  background-color: var(--button-primary-focused);
  color: var(--whiteColor);
}

@media (max-width: 1199px) {
  .OffersPage_pageTextList__m2vWc {
    flex-flow: row wrap;
  }

  .OffersPage_pageTextList__m2vWc > p {
    max-width: none;
  }
}

@media (max-width: 991px) {
  .OffersPage_offerPageItem__3xXi0 {
    max-width: none;
    margin-bottom: 24px;
  }

  .OffersPage_offerCard__22yTG {
    margin: 0 auto;
  }

  .OffersPage_pageTextListMobile__t1ve- {
    display: block;
    text-align: center;
  }

  .OffersPage_pageTextListMobile__t1ve- > p {
    max-width: 345px;
    margin: 0 auto 15px;
  }

  .OffersPage_pageTextList__m2vWc {
    display: none;
  }

  .OffersPage_iconsList__3LA5P {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .OffersPage_offersPageText__1Oeui {
    padding: 20px 0 24px;
  }

  .OffersPage_offerPageVip__2QnVW {
    padding: 0 0 40px;
  }

  .OffersPage_offerPageCard__361cE {
    padding: 0;
    background-color: var(--whiteColor);
    margin-bottom: 24px;
  }

  .OffersPage_offerPageCard__361cE .offerCardList .card {
    max-width: calc(50% - 15px);
    margin-bottom: 20px;
    background-color: var(--lavender-grey);
  }
}

@media (max-width: 767px) {
  .OffersPage_offerPageCard__361cE .offerCardList .card {
    max-width: none;
  }

  .OffersPage_offerPageCard__361cE .offerCardList {
    display: none;
  }

  h3,
  .OffersPage_h3__1SeMF {
    font-size: 18px;
    line-height: 22px;
  }
}

.PromoIcon_iconsListItem__f3Xfl {
  width: 100px;
  height: 100px;
}

.PromoIcon_iconsListItem__f3Xfl:not(:last-child) {
  margin-right: 20px;
}

.SchoolInfoContainer_container__dMZtR {
  background: var(--additional-gray-blue-20);
  border-radius: 10px;
  padding: 30px 40px;
  font-family: Hind;
  font-weight: 500;
}

.SchoolInfoContainer_container__dMZtR p {
  margin: 0;
}

.SchoolInfoContainer_generalInfoBox__3H1fy {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.SchoolInfoContainer_titleText__wbua_ {
  font-family: Ubuntu;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
}

.SchoolInfoContainer_subTitleText__3taDb {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.SchoolInfoContainer_contactInfoRow__3zSP2 {
  padding-top: 50px;
}

.SchoolInfoContainer_contactInfoBoxItem__2kKWN {
  margin-bottom: 20px;
}

.SchoolInfoContainer_infoLabel__1KCfK {
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.SchoolInfoContainer_infoText__2dDX3 {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SchoolInfoContainer_controlsContainer__dDfFz {
  margin-top: 20px;
  padding: 0;
}

.SchoolInfoContainer_controlsBox__2SQ1j {
  display: flex;
  justify-content: flex-end;
}

.DrivingSchoolInfoPage_schoolInfoPage__1e_r4 {
  padding-top: 24px;
}

.DrivingSchoolInfoPage_linkBtn__2SBkH {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  text-transform: uppercase;
  font-weight: 500;
}

.DrivingSchoolInfoPage_linkBtn__2SBkH i {
  display: block;
  transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

.HomeCards_promoCardList__1dvML {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.HomeCards_promoCardItem__1wHgM {
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  max-width: calc(25% - 10px);
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.HomeCards_cardContent__2L5cW {
  display: block;
  width: 100%;
}

.HomeCards_cardContentTitle__6NSYm,
.HomeCards_cardContentText__2UC0Z {
  margin-bottom: 10px;
}

.HomeCards_cardContentTitle__6NSYm {
  color: var(--neutral-dark-blue);
}

.HomeCards_cardContentText__2UC0Z {
  color: var(--neutral-gray-blue);
}

.HomeCards_cardFooterLink__v79Gt {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 18px;
}

@media (max-width: 1200px) {
  .HomeCards_promoCardList__1dvML {
    flex-flow: row wrap;
  }

  .HomeCards_promoCardItem__1wHgM {
    max-width: calc(50% - 10px);
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .HomeCards_promoCardList__1dvML {
    margin: 0;
  }

  .HomeCards_promoCardItem__1wHgM {
    max-width: none;
  }
}

.HomeCarousel_homeSlider__23Fyt {
  margin-bottom: 36px;
  min-height: 200px;
  max-width: 300px;
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators {
  right: auto;
  margin-left: 0;
  margin-right: 0;
}

.HomeCarousel_homeSlider__23Fyt .carousel-control-prev,
.HomeCarousel_homeSlider__23Fyt .carousel-control-next {
  display: none;
}

.HomeCarousel_sliderTitle__1useJ {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
  margin-bottom: 4px;
}

.HomeCarousel_homeSlider__23Fyt .HomeCarousel_sliderText__2UtE5 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
  margin: 12px 0 0;
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--neutral-gray);
  opacity: 1;
  transition: ease-in-out 0.4s;
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li:not(:first-child) {
  margin-left: 8px;
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li.active,
.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li:hover {
  background-color: var(--theme-primary);
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li:hover {
  cursor: pointer;
}

.HomeCarousel_langList__TCRf6 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 1199px) {
  .HomeCarousel_homeSlider__23Fyt {
    margin: 0 auto 36px;
  }
}

@media (max-width: 991px) {
  .HomeCarousel_homeSlider__23Fyt {
    display: none;
  }
}

@media (max-width: 767px) {
  .HomeCarousel_mobileSlider__3zt5n {
    text-align: left;
  }
}

.PromoContent_promoContent__3knAD {
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.PromoContent_promoItem__1fWBE {
  max-width: calc(50% - 10px);
  width: 100%;
}

.PromoContent_promoItem__1fWBE:first-child {
  position: relative;
}

.PromoContent_btnList__3KgFv {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.PromoContent_btnList__3KgFv > a:last-child {
  margin-left: 20px;
}

.PromoContent_rulesImg__2hPE7 {
  height: 100%;
  width: auto;
}

.categoryIconBarHome {
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: center;
  padding-left: 50px;
  width: 100%;
}

.PromoContent_rulesList__1-TMz {
  position: absolute;
  right: 0;
  bottom: 48px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.PromoContent_rulesListItem__1yoeh {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.PromoContent_rulesListItem__1yoeh:not(:last-child) {
  margin-right: 26px;
}

.PromoContent_rulesListItemText__3QfYq {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.PromoContent_rulesListMobile__33Fw1 {
  display: none;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 50%;
}

.PromoContent_iconsList__3vkhM {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 62px;
}

.PromoContent_iconsListItem__2pZqb {
  width: 100px;
  height: 100px;
}

.PromoContent_loginBtnMobile__3DWNl {
  display: none;
}

.PromoContent_ruleListItem__2Kq5q:not(:first-child) {
  margin-left: 12px;
}

.PromoContent_ruleListItem__2Kq5q > i {
  color: currentColor;
}

.PromoContent_socialLinkListMobile__1TE-D {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 36px;
}

.PromoContent_socialLinkList__3ThCs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

.PromoContent_socialLinkList__3ThCs > li:not(:last-child) {
  margin-right: 40px;
}

.PromoContent_socialLinkList__3ThCs > li {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PromoContent_socialLinkItem__fZaFF {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease-in-out 0.4s;
}

.PromoContent_socialLinkItem__fZaFF:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.PromoContent_socialLinkItemText__3Va5H {
  text-transform: uppercase;
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: var(--text-color);
  margin-top: 14px;
}

.PromoContent_navbar-expand-lg__2DyTX {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: var(--whiteColor);
}

@media (min-width: 1200px) {
  .PromoContent_promoItem__1fWBE:last-child {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .PromoContent_promoItem__1fWBE:first-child {
    display: none;
  }

  .PromoContent_promoItem__1fWBE {
    text-align: center;
  }

  .PromoContent_rulesListMobile__33Fw1 {
    display: flex;
    margin-bottom: 28px;
    justify-content: center;
    max-width: none;
  }

  .PromoContent_rulesListMobile__33Fw1 > i {
    font-size: 36px;
  }

  .PromoContent_btnList__3KgFv {
    justify-content: center;
  }

  .PromoContent_promoContent__3knAD {
    flex-wrap: wrap;
  }

  .PromoContent_promoItem__1fWBE {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
  }

  .PromoContent_iconsList__3vkhM {
    margin-bottom: 44px;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .PromoContent_sectionVipContent__34bHF {
    flex-wrap: wrap;
  }

  .PromoContent_sectionVipItem__2pXhQ {
    max-width: none;
    width: 100%;
    margin-bottom: 25px;
  }

  .PromoContent_sectionCard__3CQkK {
    background-color: var(--neutral-light-gray);
  }

  .PromoContent_storeBtnList__2oH8v {
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
  }

  .PromoContent_loginBtnMobile__3DWNl {
    display: block;
  }

  .PromoContent_btnList__3KgFv {
    flex-wrap: wrap;
  }

  .PromoContent_btnList__3KgFv > a,
  .PromoContent_btnList__3KgFv > button {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .PromoContent_btnList__3KgFv > button {
    order: 1;
  }

  .PromoContent_btnList__3KgFv > a:nth-child(3) {
    margin-left: 0;
    order: 2;
  }

  .PromoContent_btnList__3KgFv > a:nth-child(2) {
    order: 3;
  }
}

.Market_storeBtnItem__b-P_d {
  max-width: 100%;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  margin-top: 105px;
  text-align: right;
}

.Market_storeBtnItem__b-P_d img {
  height: 30px;
}

.Market_storeBtnItem__b-P_d:first-child {
  margin-top: 0;
  text-align: left;
}

.Market_storeBtnItem__b-P_d:first-child img {
  height: 260px;
  width: 100%;
}

@media (max-width: 1199px) {
  .Market_storeBtnItem__b-P_d img {
    height: 20px;
  }

  .Market_storeBtnItem__b-P_d:first-child img {
    height: 120px;
  }
}

.VipContent_sectionVipContent__GfCk8 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
}

.VipContent_sectionVipItem__20bvX {
  max-width: 50%;
  width: 100%;
}

.VipContent_sectionVipItem__20bvX .h3 {
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
}

.VipContent_sectionVipItem__20bvX:last-child {
  max-width: 45%;
}

.VipContent_storeBtnList__aFc2C {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.VipContent_storeBtnItem__1aojy {
  max-width: 100%;
  width: 100%;
  height: 48px;
  background-color: var(--blackColor);
  border-radius: 4px;
}

@media (max-width: 1199px) {
  .VipContent_storeBtnList__aFc2C {
    justify-content: center;
  }
  .VipContent_sectionVipItem__20bvX .VipContent_pageText__2SQQY {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .VipContent_sectionVipContent__GfCk8 {
    flex-wrap: wrap;
  }
  .VipContent_sectionVipItem__20bvX,
  .VipContent_sectionVipItem__20bvX:last-child {
    max-width: none;
    width: 100%;
    margin-bottom: 25px;
  }
  .VipContent_storeBtnList__aFc2C {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.SocialLinkList_socialLinkItem__BzCSb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease 0.4s;
}

.SocialLinkList_socialLinkItem__BzCSb:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.SocialLinkList_socialLinkItemText__1NY2l {
  text-transform: uppercase;
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: var(--text-color);
  margin-top: 14px;
}

.SocialLinkList_socialLinkListMobile__3vqb4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 36px;
}

.SocialLinkList_socialLinkList__3Bqtk {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

.SocialLinkList_socialLinkList__3Bqtk > li:not(:last-child) {
  margin-right: 40px;
}

.SocialLinkList_socialLinkList__3Bqtk > li {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  .SocialLinkList_socialLinkListMobile__3vqb4 {
    display: none;
  }
}

.HomePage_sectionPromo__xoNfr {
  margin-bottom: 10px;
}

.HomePage_sectionPromo__xoNfr .pageTitle {
  margin-bottom: 32px;
}

.HomePage_sectionCard__zvzZ0 {
  padding: 70px 0 100px;
}

.HomePage_rulesListMobile__XzTuv {
  display: none;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 50%;
}

.HomePage_sectionAdsMobile__DnmNG {
  display: none;
}

@media (max-width: 991px) {
  .HomePage_sectionCard__zvzZ0 {
    background-color: var(--neutral-light-gray);
    padding: 24px 0 36px;
  }

  .HomePage_sectionPromo__xoNfr {
    margin-bottom: 20px;
  }

  .HomePage_sectionAdsMobile__DnmNG {
    display: block;
    padding: 0 0 30px;
  }

  .HomePage_mobileCarousel__2XTbB {
    display: block;
    background-color: var(--neutral-light-gray);
    padding: 40px 0;
  }
}

.HomeCarouselMobile_mobileSlider__3DGxG {
  display: none;
}

.HomeCarouselMobile_mobileSliderItem__3kwfT:not(:last-child) {
  margin-bottom: 20px;
}

.HomeCarouselMobile_sliderTitle__1vX6A {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
  margin-bottom: 4px;
}

.HomeCarouselMobile_mobileSlider__3DGxG .HomeCarouselMobile_sliderText__3Trws {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
  margin: 8px 0 0;
}

.HomeCarouselMobile_langList__16duO {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.HomeCarouselMobile_langList__16duO > li:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 991px) {
  .HomeCarouselMobile_mobileSlider__3DGxG {
    display: block;
  }
}

.Teacher3dNavMenu_navList__8pD2r {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Teacher3dNavMenu_navList__8pD2r .nav-item {
  width: 100%;
  background-color: var(--whiteColor);
  margin-bottom: 12px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.Teacher3dNavMenu_navList__8pD2r .nav-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
  padding: 16px 30px 16px 16px;
  transition: all ease 0.3s;
}

.Teacher3dNavMenu_navList__8pD2r .nav-link i {
  font-size: 14px;
  color: var(--theme-primary);
}

.Teacher3dNavMenu_navList__8pD2r .nav-link.active,
.Teacher3dNavMenu_navList__8pD2r .nav-link:hover {
  color: var(--button-default-focused);
}

.Teacher3dDisplay_pageTopContent__1owFB {
  padding-bottom: 80px;
}

.Teacher3dDisplay_pageTopContent__1owFB h1 {
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .Teacher3dDisplay_pageTopContent__1owFB {
    padding-bottom: 0;
  }

  .Teacher3dDisplay_pageTopContent__1owFB h1 {
    margin-bottom: 16px;
  }
}

.Teacher3dCarousel_responsiveVideo__3mkfz {
  max-width: 610px;
  width: 100%;
  height: 100%;
  min-height: 442px;
  border-radius: 6px;
}

.carousel-item p {
  margin: 12px 0 24px;
  max-width: 400px;
}

.Teacher3dCarousel_responsiveVideo__3mkfz::-webkit-media-controls {
  opacity: 0;
}

.Teacher3dCarousel_videoContainer__28W3-:hover .Teacher3dCarousel_responsiveVideo__3mkfz::-webkit-media-controls {
  cursor: pointer;
}

@media (max-width: 1199px) {
  .Teacher3dCarousel_responsiveVideo__3mkfz {
    min-height: 338px;
  }
}

@media (max-width: 991px) {
  .Teacher3dCarousel_responsiveVideo__3mkfz {
    min-height: auto;
    max-width: none;
  }
}

.Teacher3dPage_teacherPage__2pCD4 {
  padding-top: 40px;
  margin-bottom: -57px;
}

.Teacher3dPage_pageBottomContent__20vuz {
  padding: 60px 0 100px;
  background-color: var(--lavender-grey);
  will-change: max-height;
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-indicators {
  display: none;
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-control-next-icon,
.Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev-icon {
  display: none;
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
.Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
  transition: all ease 0.3s;
  opacity: 1;
  width: 40px;
  height: 40px;
  top: auto;
  bottom: 220px;
  margin-bottom: -20px;
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
  transform: rotate(180deg);
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-control-next:before,
.Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev:before {
  position: absolute;
  content: '\e918';
  font-family: 'icomoon';
  font-size: 14px;
  color: var(--whiteColor);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.Teacher3dPage_teacherContent__13xjP {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.Teacher3dPage_teacherNavList__1tQk4 {
  max-width: 400px;
  width: 100%;
  margin-right: 10%;
}

@media (max-width: 991px) {
  .Teacher3dPage_teacherPage__2pCD4 {
    padding-top: 24px;
    margin-bottom: 0;
  }

  .Teacher3dPage_pageBottomContent__20vuz {
    padding: 24px 0 16px;
    background-color: var(--whiteColor);
  }

  .Teacher3dPage_navListContent__3tbQV {
    padding-top: 24px;
  }

  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
    bottom: 260px;
  }
}

@media (max-width: 767px) {
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
    bottom: 190px;
  }
}

@media (max-width: 480px) {
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
    bottom: 130px;
  }
}

.VipCardAccordion_vipCardMobile__3U3DA {
  display: none;
}

.VipCardAccordion_vipCardMobile__3U3DA .accordion {
  position: relative;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  overflow: hidden;
  letter-spacing: 1px;
  background: url(/static/media/vip-mobile.a7eaaf2d.svg) no-repeat -63px -58px, var(--whiteColor);
  background-size: 146px 146px;
}

.VipCardAccordion_vipCardMobile__3U3DA .card-header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-bottom: none;
  border-radius: 0;
  padding: 0;
}

.VipCardAccordion_vipCardMobile__3U3DA .card {
  background-color: transparent;
  margin-bottom: 0;
  border: none;
}

.VipCardAccordion_vipCardMobile__3U3DA .card-body {
  display: flex;
  justify-content: center;
  padding: 3px 20px 8px;
  align-items: center;
  width: 100%;
}

.VipCardAccordion_vipCardMobile__3U3DA .linkBtn {
  letter-spacing: 0.1px;
}

.VipCardAccordion_cardTitle__S9ZQn {
  font-family: 'Hind', sans-serif;
  font-size: 24px;
  line-height: 60px;
  color: var(--theme-primary);
  font-weight: bold;
  text-transform: uppercase;
}

.VipCardAccordion_accordionBtn__2ImvN {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -12px;
  transform: rotate(90deg);
  color: var(--theme-primary);
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.VipCardAccordion_accordionBtn__2ImvN > i {
  font-size: 24px;
}

@media (max-width: 767px) {
  .VipCardAccordion_vipCardMobile__3U3DA {
    display: block;
  }
}

.ExtendOffersPage_root__9TXXv {
  padding-top: 40px;
  margin-bottom: -57px;
}

.ExtendOffersPage_infoBlock__24idh {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
}

.ExtendOffersPage_infoBlockItem__1tnAp {
  max-width: calc(50% - 10px);
  width: 100%;
}

.ExtendOffersPage_infoBlockItem__1tnAp:last-child .ExtendOffersPage_infoBlockContent__3Mbf7 {
  max-width: 500px;
  margin-left: auto;
}

.ExtendOffersPage_infoBlockContent__3Mbf7 h1 {
  max-width: 500px;
}

.ExtendOffersPage_extendPageText__2XtGO {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.ExtendOffersPage_extendPageText__2XtGO p {
  max-width: calc(50% - 10px);
  width: 100%;
}

.ExtendOffersPage_vipCardMobile__3mtTk {
  display: none;
}

.ExtendOffersPage_mbShow__3oQwh {
  display: none;
}

@media (max-width: 1199px) {
  .ExtendOffersPage_extendPageText__2XtGO {
    flex-flow: row wrap;
  }

  .ExtendOffersPage_extendPageText__2XtGO p {
    max-width: none;
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .ExtendOffersPage_root__9TXXv {
    padding-top: 24px;
    margin-bottom: 0;
  }

  .ExtendOffersPage_infoBlock__24idh {
    flex-flow: row wrap;
  }

  .ExtendOffersPage_infoBlockItem__1tnAp {
    max-width: 80%;
    margin: 0 auto 20px;
  }

  .ExtendOffersPage_infoBlockItem__1tnAp:last-child .ExtendOffersPage_infoBlockContent__3Mbf7 {
    margin-left: 0;
  }

  .ExtendOffersPage_mbShow__3oQwh {
    display: block;
  }
}

.ConfirmedPlanExtensionPage_confirmedPage__3LJH1 {
  margin-bottom: -57px;
}

.ConfirmedPlanExtensionPage_confirmedPageContent__1uZxc {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
}

.ConfirmedPlanExtensionPage_confirmedPageText__1bEQh,
.ConfirmedPlanExtensionPage_imgContainer__2JX9m {
  max-width: calc(50% - 10px);
  width: 100%;
}

.ConfirmedPlanExtensionPage_imgContainer__2JX9m img {
  height: 100%;
  width: auto;
}

.ConfirmedPlanExtensionPage_confirmedPageText__1bEQh h1 {
  margin-bottom: 24px;
}

.ConfirmedPlanExtensionPage_pageTextSmall__3dceQ {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
  margin-bottom: 16px;
}

.ConfirmedPlanExtensionPage_pageTextSubtitle__2H74L {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-gray-blue);
  max-width: 340px;
  margin-bottom: 48px;
}

.ConfirmedPlanExtensionPage_linkBtn__C7bsS {
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  padding: 0;
  border: none;
  text-transform: uppercase;
  transition: all ease 0.3s;
}

.ConfirmedPlanExtensionPage_linkBtn__C7bsS i {
  display: inline-block;
  margin-left: 5px;
  transform: rotate(180deg);
  font-size: 14px;
  color: currentColor;
}

.ConfirmedPlanExtensionPage_linkBtn__C7bsS:hover,
.ConfirmedPlanExtensionPage_linkBtn__C7bsS:focus {
  text-decoration: none;
}

@media (max-width: 991px) {
  .ConfirmedPlanExtensionPage_confirmedPage__3LJH1 {
    padding: 24px 0 60px;
    margin-bottom: 0;
  }

  .ConfirmedPlanExtensionPage_confirmedPageContent__1uZxc {
    flex-flow: row-reverse wrap;
  }

  .ConfirmedPlanExtensionPage_confirmedPageText__1bEQh,
  .ConfirmedPlanExtensionPage_imgContainer__2JX9m {
    max-width: none;
  }

  .ConfirmedPlanExtensionPage_imgContainer__2JX9m {
    display: none;
  }

  .ConfirmedPlanExtensionPage_confirmedPageText__1bEQh {
    text-align: center;
  }

  .ConfirmedPlanExtensionPage_pageTextSmall__3dceQ {
    font-family: 'Hind', sans-serif;
    font-size: 15px;
    line-height: 21px;
    color: var(--neutral-gray-blue);
    margin-bottom: 16px;
  }

  .ConfirmedPlanExtensionPage_pageTextSubtitle__2H74L {
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto 28px;
  }
}

@media (max-width: 767px) {
  .ConfirmedPlanExtensionPage_confirmedPageText__1bEQh {
    text-align: left;
  }

  .ConfirmedPlanExtensionPage_pageTextSubtitle__2H74L {
    max-width: none;
  }

  .ConfirmedPlanExtensionPage_pageTextSubtitle__2H74L {
    margin: 0 0 28px;
  }
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-body {
  padding: 0 20px;
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-header {
  padding: 20px;
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-body {
  padding: 0 20px;
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SuccessSignUpPopup_root__NpFg_ {
  font-family: 'Hind', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.SuccessSignUpPopup_header__lAEk4 {
  padding: 20px;
}

.SuccessSignUpPopup_body__2Uc53 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
}

.SuccessSignUpPopup_footer__gqbzH .btn {
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.SuccessSignUpPopup_title__2YhAk {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
  margin: 0;
}

.SuccessSignUpPopup_text__1qNmn {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  margin-bottom: 8px;
  text-align: start;
}

.SuccessSignUpPopup_text__1qNmn .SuccessSignUpPopup_linkText__2txsp {
  color: var(--button-default-focused);
}

.SuccessSignUpPopup_passwordContainer__2-ktf {
  text-align: center;
}

.SuccessSignUpPopup_passwordContainer__2-ktf .SuccessSignUpPopup_passwordInput__1WaOJ {
  display: inline;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: var(--neutral-dark-blue);
  border: 0;
  background-color: transparent;
  max-width: 100%;
}

.SuccessSignUpPopup_passwordContainer__2-ktf .SuccessSignUpPopup_copyHint__mJ67U {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  transition: 0.3s;
  opacity: 0;
}

.SuccessSignUpPopup_passwordContainer__2-ktf .SuccessSignUpPopup_copyHint__mJ67U.SuccessSignUpPopup_copyHintShow__3Lg7R {
  opacity: 1;
}

.SuccessSignUpPopup_body__2Uc53 .SuccessSignUpPopup_copyButton__1pJok {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: var(--modal-footer-btn-color);
}

footer {
  padding: 40px 0 42px;
  border-top: 1px solid var(--neutral-gray);
  margin-top: 57px;
}

.Footer_socialLinkList__q8VD_ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-top: 45px;
}

.Footer_socialLinkList__q8VD_ > li:not(:last-child) {
  margin-right: 12px;
}

.Footer_socialLinkItem__1Jeq4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease-in-out 0.4s;
}

.Footer_socialLinkItem__1Jeq4:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.Footer_brandLinkList__1WDHl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 12px;
}

.Footer_brandLinkList__1WDHl p {
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: var(--text-color);
}

.Footer_brandLinkItem__DRzYs {
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 100%;*/
}

.Footer_footerTextWrapper__1Sacs {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  height: 100%;
}

.Footer_footerText__2uHpc {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.Footer_footerTextItem__hAUsY {
  white-space: nowrap;
}

.Footer_footerTextItem__hAUsY:not(:first-child):before {
  content: '·';
  margin: 0 5px;
}

.Footer_footerTextWrapper__1Sacs {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  height: 100%;
}

.Footer_footerCopyright__3QU6m,
.Footer_footerCopyrightLink__DfmeY,
.Footer_footerCopyrightMobile__1dZYr {
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 18px;
  color: rgba(75, 101, 132, 0.7);
}

.Footer_footerCopyright__3QU6m {
  margin-top: 16px;
}

.Footer_footerCopyrightLink__DfmeY {
  display: inline-block;
  padding: 0 2px;
}

.Footer_footerCopyrightLink__DfmeY:hover {
  text-decoration: none;
  color: rgba(75, 101, 132, 0.7);
}

.Footer_footerPartnerContainer__1gHN9 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_footerPartnerText__XA0qD {
  font-weight: 700;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .Footer_socialLinkItem__1Jeq4 {
    width: 30px;
    height: 30px;
  }

  .Footer_socialLinkItem__1Jeq4 > i {
    font-size: 20px;
  }

  .Footer_footerTextItem__hAUsY:last-child {
    display: block;
  }

  .Footer_footerTextItem__hAUsY:last-child:before {
    content: none;
  }
}

@media (max-width: 991px) {
  footer {
    padding: 24px 0 30px;
    margin-top: 20px;
  }

  footer .Footer_socialLinkList__q8VD_,
  .Footer_footerCopyright__3QU6m {
    display: none;
  }

  .Footer_footerText__2uHpc {
    font-size: 14px;
    line-height: 22px;
  }

  .Footer_footerTextItem__hAUsY:last-child:first-letter {
    display: none;
  }

  .Footer_footerCopyrightMobile__1dZYr {
    margin-top: 36px;
  }

  .Footer_footerLogo__2TNvs {
    display: block;
    max-width: 117px;
    margin-bottom: 26px;
  }
}

@media (min-width: 992px) {
  .Footer_footerCopyrightMobile__1dZYr {
    display: none;
  }

  .Footer_footerCopyright__3QU6m {
    display: block;
  }
}

@media (max-width: 768px) {
  .Footer_footerPartnerContainer__1gHN9 {
    padding-left: 50px;
    align-items: center;
    flex-direction: column;
  }

  .Footer_footerTextWrapper__1Sacs {
    justify-content: center;
  }

  .Footer_footerLogo__2TNvs {
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .Footer_footerLogo__2TNvs {
    text-align: center;
    margin: 0 auto 26px auto;
  }

  .Footer_footerTextWrapper__1Sacs {
    text-align: center;
  }

  .Footer_footerPartnerContainer__1gHN9 {
    align-items: center;
    padding-top: 25px;
    padding-left: 15px;
  }

  .Footer_footerCopyrightMobile__1dZYr {
    text-align: center;
  }
}

.LoginModal_forgotPassLink__13Pq7 {
  text-decoration: underline;
}

.LoginModal_formLinkBox__1PsnK {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.LoginModal_actionBtn__3Fuia {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--whiteColor);
  width: 100%;
  border-radius: 0 0 4px 4px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-body {
  padding: 0 20px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-header {
  padding: 20px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-body {
  padding: 0 20px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .form-group {
  margin-bottom: 35px;
}

.RecoveryPasswordModal_linkBtn__1bLYN {
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  border: none;
  transition: all ease 0.3s;
  padding: 6px 24px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LogoutModal_actionBtn__3TLmL {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--whiteColor);
  width: 100%;
  border-radius: 0 0 4px 4px;
}

.GoodByeModal_modalGoodBye__2j7Vx {
  padding: 0;
}

.GoodByeModal_modalGoodBye__2j7Vx .modal-body {
  padding: 0;
}

.GoodByeModal_modalGoodBye__2j7Vx .modal-footer {
  padding: 25px 20px;
}

.GoodByeModal_modalFooterText__1wrdU {
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
}

.ScrollToTopButton_btnTop__Ss9TZ {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  background-color: var(--whiteColor);
  transition: ease 0.3s;
  transform-origin: center;
  border: 1px solid transparent;
  transform: scale(0);
  z-index: 10;
}

.ScrollToTopButton_btnTop__Ss9TZ i {
  width: 14px;
  height: 14px;
  font-size: 14px;
  transform: rotate(-90deg);
  color: var(--theme-primary);
}

.ScrollToTopButton_btnTop__Ss9TZ.ScrollToTopButton_visible__3ZgUE {
  transform: scale(1);
}

.ScrollToTopButton_btnTop__Ss9TZ:hover {
  cursor: pointer;
  box-shadow: none;
  border: 1px solid var(--theme-primary);
}

.ScrollToTopButton_btnTopText__QEgvX {
  font-family: 'Hind', sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: var(--text-color);
}

@media (max-width: 767px) {
  .ScrollToTopButton_btnTop__Ss9TZ {
    right: 15px;
    bottom: 15px;
  }
}

