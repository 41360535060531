.searchContainer {
  width: 100%;
}

.searchContainer button {
  margin-left: 20px;
}

.searchContainer form {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 767px) {
  .searchContainer form {
    flex-flow: row wrap;
  }

  .searchContainer button {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }
}
