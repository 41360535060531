.questionsBgContainer {
  background: url('../../../src/img/questionBg.svg') no-repeat 50% 50%;
  background-size: cover;
}

.imageBox {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 991px) {
  .imageBox {
    max-width: unset;
  }
}
