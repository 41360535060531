.glossaryList {
  background-color: var(--whiteColor);
  padding: 32px;
  border-radius: 6px;
  overflow-y: auto;
  max-width: 400px;
  width: 100%;
  margin-right: 32px;
  min-width: 0;
  flex-shrink: 0;
}

.glossaryList::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.glossaryList::-webkit-scrollbar {
  width: 12px;
}

.glossaryList::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.letterItem {
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 16px;
}

@media (max-width: 991px) {
  .glossaryList {
    width: 100%;
    max-width: none;
    max-height: 500px;
    overflow-y: auto;
    margin-bottom: 40px;
    background-color: var(--lavender-grey);
  }
}
