.pageTopContent {
  padding-bottom: 80px;
}

.pageTopContent h1 {
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .pageTopContent {
    padding-bottom: 0;
  }

  .pageTopContent h1 {
    margin-bottom: 16px;
  }
}
