.examPage {
  /*background: url('../../img/il-2.svg') no-repeat center center,*/
  /*  linear-gradient(359.1deg, var(--whiteColor) -14.55%, var(--whiteColor) 18.5%, rgba(255, 255, 255, 0) 56.75%);*/
  /*background-size: 40% auto;*/
}

.pcInstructor,
.mobileInstructor {
  max-width: calc(50% - 10px);
  width: 100%;
  margin: 0;
}

.examPage :global(.pageTitle) {
  margin-bottom: 12px;
}

.examDetails {
  background-color: var(--additional-gray-blue-20);
  padding: 54px 0 80px;
}

.mobileInstructor {
  display: none;
}

.pcInstructor {
  display: block;
}

@media (max-width: 991px) {
  .examPage {
    padding-top: 24px;
    background: none;
  }

  .pcInstructor {
    display: none;
  }

  .mobileInstructor {
    display: block;
    margin-bottom: 40px;
    max-width: none;
  }

  .examDetails {
    background-color: var(--whiteColor);
    padding: 40px 0 0;
  }
}
