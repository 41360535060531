.offerAccordionList {
  display: block;
}

.accordionCardItem {
  max-width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
}

.accordionBtn {
  transform: rotate(90deg);
  color: var(--theme-primary);
  width: 24px;
  height: 24px;
}

.accordionBtn > i {
  font-size: 24px;
}

.cardHeaderText,
.cardHeaderDays {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.cardHeaderDays {
  line-height: 18px;
  color: var(--neutral-dark-blue-50);
}

.cardHeaderText {
  line-height: 22px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
}

.accordionCardItem :global(.card-header) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 18px 20px 18px 16px;
}

.accordionCardItem :global(.card-body) {
  padding: 25px 0 0;
}

.accordionCardItem :global(.card-text) {
  font-family: 'Hind', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-70);
  padding: 0 20px;
}

.accordionCardItem :global(.card) {
  border: none;
  background-color: var(--neutral-light-gray);
  border-radius: 6px !important;
}

.cardHeaderInfo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 50%;
  width: 100%;
}

.accordionFooterBtn {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02px;
  width: 100%;
}

@media (max-width: 991px) {
  .offerAccordionList {
    display: flex;
  }
}

@media (max-width: 600px) {
  .cardHeaderInfo {
    max-width: 60%;
  }
}
