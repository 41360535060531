.imgContainer-main {
  position: relative;
  background: #f4efef url('../../../src/img/img-placeholder.svg') no-repeat 50% -44px;
  background-size: contain;
  border-radius: 6px;
  padding-top: 40.25%;
  width: 100%;
}

.imgContainer-sidebar {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.imgContainer-sidebar .questionImgContent {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.imgContainer-main .questionImgContent {
  position: absolute;
  left: 0;
  top: 50%;
  height: 100%;
  border-radius: 6px;
  transform: translateY(-50%);
  object-fit: cover;
  width: 100%;
}

.videoContainer {
  position: relative;
  flex-flow: column;
  justify-content: flex-start;
  padding-top: 47.75%;
  overflow: hidden;
  border-radius: 6px;
  width: 100%;
  height: auto;
}

.videoContainer video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.videoContainer .zoomBtn {
  position: absolute;
  right: 10px;
  top: 6px;
  transition: all ease 0.3s;
}

.sidebarZoomBtn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

@media (max-width: 991px) {
  .videoContainer {
    padding-top: 56.25%;
  }

  .imgContainer-main .questionImgContent {
    width: 100%;
    height: 100%;
  }

  .imgContainer-main {
    max-width: none;
    margin-bottom: 14px;
    padding-top: 56.25%;
  }

  .imgContainer-sidebar img,
  .imgContainer-sidebar .sidebarZoomBtn {
    display: none;
  }

  .videoContainer .zoomBtn {
    display: none;
  }
}
