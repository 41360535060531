.resultPageItem {
  max-width: 400px;
  width: 100%;
}

.resultPageItem h1 {
  margin-bottom: 32px;
}

.resultPageItem p {
  margin-top: 24px;
}

@media (max-width: 1199px) {
  .resultPageItem {
    width: 100%;
    max-width: none;
  }
}
