.modalTopic :global(.modal-body) {
  padding: 0 44px 28px;
}

.modalTopic :global(.modal-header) {
  padding: 40px 44px 70px;
}

.modalTopic :global(.modal-title) {
  font-family: 'Ubuntu', sans-serif;
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.topicListLink {
  display: block;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 32px;
}

.topicList {
  display: grid;
  grid-template-columns: repeat(2, 200px);
  grid-auto-flow: row;
}

@media (max-width: 991px) {
  .modalTopic {
    max-width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    border-radius: 6px;
  }

  .modalTopic :global(.modal-body) {
    padding: 0 20px;
  }

  .modalTopic :global(.modal-dialog) {
    height: 100%;
  }

  .modalTopic :global(.modal-header) {
    padding: 20px;
  }

  .modalTopic :global(.modal-body) {
    padding: 0 20px;
  }

  .modalTopic :global(.modal-title) {
    font-family: 'Ubuntu', sans-serif;
    font-size: 26px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: var(--neutral-dark-blue);
    margin-top: 0;
  }

  .modalTopic :global .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .topicList {
    grid-template-columns: repeat(1, 200px);
  }
}
