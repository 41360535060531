.rulesGroupItem {
  max-width: 50%;
  width: 100%;
}

.questionsByTopic {
  padding-top: 80px;
  padding-bottom: 80px;
}

.questionsByTopic .pageTitle {
  padding-bottom: 70px;
}

.questionsByTopicTitle {
  color: var(--neutral-dark-blue);
}

.questionsByTopicList {
  position: relative;
  columns: 3 auto;
  padding: 0 0 80px;
  transition: all ease 0.7s;
}

.questionsTopicText {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.questionsTopicText p {
  max-width: calc(100% / 3);
  width: 100%;
}

.questionsTopicText p:not(:last-child) {
  margin-right: 20px;
}

.questionsByTopicList.mobileMode::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 180px;
  background: linear-gradient(180deg, var(--whiteColor) 21.87%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(-180deg);
  transition: all ease 1s;
}

.questionsByTopicList.mobileMode.expanded::after {
  content: none;
  height: 0;
}

.extendControlContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.expandBtn {
  display: inline-flex;
  align-items: center;
  color: var(--neutral-dark-blue);
  text-decoration: none !important;
}

.expandStatusIcon::after {
  display: block;
  margin-left: 13px;
  content: '\e918';
  font-family: 'icomoon';
  font-size: 18px;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
  transition: all ease 0.3s;
  color: var(--button-default-enabled);
}

.expandStatusIcon.expanded::after {
  transform: rotate(-90deg);
}

.questionsByTopicListItem > div {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  max-width: 315px;
  line-height: 56px;
  width: 100%;
}

.questionsLink {
  display: inline-block;
  color: var(--neutral-dark-blue);
}

.questionsLink:first-letter {
  text-transform: uppercase;
}

.questionsByTopicList > p {
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .questionsByTopicList.mobileMode {
    max-height: 330px;
    height: 100%;
    overflow: hidden;
  }

  .questionsByTopicList.mobileMode.expanded {
    max-height: none;
    overflow: visible;
    padding-bottom: 0;
    height: 100%;
    min-height: 100%;
  }

  .questionsByTopicList {
    column-width: 320px;
    margin: 32px 0 0;
  }

  .questionsByTopic {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .questionsByTopic .pageTitle {
    padding-bottom: 0;
  }

  .questionsByTopicList {
    padding: 0;
  }

  .questionSection {
    background-color: var(--whiteColor);
  }

  .extendControlContainer {
    margin-bottom: 40px;
  }

  .questionsTopicText {
    flex-flow: row wrap;
  }

  .questionsTopicText p {
    max-width: none;
    margin-bottom: 20px;
  }

  .questionsTopicText p:not(:last-child) {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .questionsByTopicList {
    columns: auto;
    column-width: auto;
  }
}
