.dropDownToggle {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  min-width: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.dropDownToggle:hover,
.dropDownToggle:focus {
  background-color: transparent;
  border-color: transparent;
}

.dropdown-toggle::after {
  border-top: 6px solid;
  border-color: var(--theme-primary);
}

.barBtn .dropMenu {
  max-width: 70px;
  width: 100%;
  min-width: auto;
  transform: none !important;
  top: 32px;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  margin: 0;
}

:global .flag {
  width: 36px;
  height: 36px;
  margin: 6px;
  border-radius: 50%;
}

:global .show > .btn-primary.dropdown-toggle {
  position: relative;
  z-index: 9999;
}

@media (max-width: 991px) {
  :global .flag {
    width: 24px;
    height: 24px;
    margin: 4px;
  }
}
