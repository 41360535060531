.loginBtnMobile,
.logoutBtnMobile {
  display: none;
  color: var(--neutral-dark-blue);
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.loginBtn,
.logoutBtn {
  display: block;
}

.langIconGroup {
  display: none;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 44px;
}

.navbar-nav .nav-link {
  color: var(--neutral-dark-blue);
  transition: all ease 0.3s;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: var(--button-default-pressed);
}

.loginBtnGroup {
  display: flex;
  align-items: center;
}

.loginBtnItem {
  margin-right: 35px;
}

@media (max-width: 1199px) {
  .loginBtnItem {
    margin-right: 10px;
  }
}

@media (max-width: 1150px) {
  :global(.mr-auto .nav-link) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}

@media (max-width: 991px) {
  .loginBtnMobile,
  .logoutBtnMobile {
    display: block;
  }

  :global(.mr-auto .nav-link) {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    max-width: unset;
  }

  :global(.mr-auto .nav-link) {
    word-break: unset;
    text-align: unset;
  }

  .loginBtnItem.desktop {
    display: none;
  }

  .loginBtnGroup {
    align-items: flex-start;
  }

  .loginBtn,
  .logoutBtn {
    display: none;
  }

  .langIconGroup {
    display: flex;
  }
}
