.root {
  padding-top: 40px;
  margin-bottom: -57px;
}

.infoBlock {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
}

.infoBlockItem {
  max-width: calc(50% - 10px);
  width: 100%;
}

.infoBlockItem:last-child .infoBlockContent {
  max-width: 500px;
  margin-left: auto;
}

.infoBlockContent h1 {
  max-width: 500px;
}

.extendPageText {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.extendPageText p {
  max-width: calc(50% - 10px);
  width: 100%;
}

.vipCardMobile {
  display: none;
}

.mbShow {
  display: none;
}

@media (max-width: 1199px) {
  .extendPageText {
    flex-flow: row wrap;
  }

  .extendPageText p {
    max-width: none;
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .root {
    padding-top: 24px;
    margin-bottom: 0;
  }

  .infoBlock {
    flex-flow: row wrap;
  }

  .infoBlockItem {
    max-width: 80%;
    margin: 0 auto 20px;
  }

  .infoBlockItem:last-child .infoBlockContent {
    margin-left: 0;
  }

  .mbShow {
    display: block;
  }
}
