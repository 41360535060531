.questionPage :global(.rulesGroupItem h1) {
  margin-bottom: 20px;
}

.questionTopicBg {
  background-color: var(--additional-gray-blue-20);
}

.mobileView {
  display: none;
}

.imageBox {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 991px) {
  .mobileView {
    display: block;
  }
}
